import { AspectRatio } from '@chakra-ui/react'
export interface VideoProps {
  title: string
  urlEmbed: string
}
export const Video = ({ title, urlEmbed }: VideoProps) => {
  return (
    <AspectRatio maxW="520px" ratio={16 / 9}>
      <iframe
        title={title} //"Analitics Pro|Gestor"
        src={urlEmbed} //"https://www.youtube.com/embed/1htsdhn9aW4"
        allowFullScreen
      />
    </AspectRatio>
  )
}
