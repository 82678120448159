import { Client, ITable, OrderProduct } from '../types'
import { clearCurrency } from './isCurrencyValidator'

const verifyClient = (referenceId: string, client: Client) => {
  const objRequired = { name: client.name, cpfCnpj: client.cpfCnpj }

  if (
    (objRequired.name !== '' && objRequired.cpfCnpj !== '') ||
    (objRequired.name !== undefined &&
      objRequired.cpfCnpj !== undefined &&
      typeof objRequired.cpfCnpj === 'number')
  ) {
    return true
  } else {
    return { referenceId, message: 'Nome do cliente ou CPF/CNPJ' }
  }
}

const verifyProduct = (referenceId: string, products: OrderProduct[]) => {
  const objRequired = products.map((product) => {
    if (
      product.name === '' ||
      product.quantity === 0 ||
      product.unitaryValue === 0 ||
      product.name === undefined ||
      product.quantity === undefined ||
      product.unitaryValue === undefined ||
      typeof clearCurrency(product.quantity.toString()) !== 'number' ||
      typeof clearCurrency(product.unitaryValue.toString()) !== 'number'
    ) {
      return {
        referenceId,
        message: 'Nome do produto, quantidade ou valor unitário',
      }
    }
  })

  return objRequired.filter((item) => item !== undefined).length > 0
    ? objRequired.filter((obj) => obj !== undefined)
    : true
}

const verifyOrder = (referenceId: string) => {
  return referenceId === '' || referenceId === undefined
    ? { referenceId, message: 'Referência' }
    : true
}

const verifySKU = (referenceId: string, products: OrderProduct[]) => {
  const objRequired = products.map((product) => {
    if (product.sku === '' || product.sku === undefined) {
      return { referenceId, message: 'SKU' }
    }
  })

  return objRequired.filter((item) => item !== undefined).length > 0
    ? objRequired.filter((obj) => obj !== undefined)
    : true
}

export const validationLine = (table: ITable[]) => {
  const errors = []
  for (const items of table) {
    const clientErrors = verifyClient(items.referenceId, items.client)
    const ordersErrors = verifyOrder(items.referenceId)
    const productErrors = verifyProduct(items.referenceId, items.orderProduct)
    const skuErrors = verifySKU(items.referenceId, items.orderProduct)

    errors.push(clientErrors, ordersErrors, productErrors, skuErrors)
  }

  return errors.filter((erro) => erro !== true)
}
