import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import {
  HiCheckCircle,
  HiDownload,
  HiEye,
  HiOutlinePrinter,
  HiXCircle,
} from 'react-icons/hi'
import { useSession } from 'src/contexts'
import { apiInstance } from 'src/services/api'
import { NotFoundFiles } from 'src/components/Animations'

type Props = { step: number }

interface Files {
  acao: string
  cnpj: string
  email: string
  datainserido: string
  id: number
  mensagem: string
  status: number
  value: string
  report: string
}

export const Print = ({ step }: Props) => {
  const [files, setFiles] = useState<Files[]>([])
  const { session } = useSession()
  const [pdf, setPdf] = useState({ title: '', file: '' })
  const { isOpen, onClose, onOpen } = useDisclosure()

  const handleFile = (file: Files) => {
    setPdf({ title: file.mensagem.trim(), file: file.value })
    onOpen()
  }

  const getFastPrints = useCallback(async () => {
    try {
      const { data } = await apiInstance(8010).get<any[]>(
        `/ImpressoesRapida/last/${session.company.document}/${session.email}`,
        {
          headers: {
            companyId: session.company.id.toString(),
            authorization: `bearer ${session.token}`,
          },
        }
      )
      setFiles(() => data)
    } catch (error) {
      console.log(error)
    }
  }, [session])

  useEffect(() => {
    if (step === 2) getFastPrints()
  }, [getFastPrints, step])

  return (
    <>
      {/* <Flex
        alignItems="center"
        justifyContent="space-between"
        backgroundColor="rgb(237 230 255)"
        px="10px"
        borderRadius={4}
      >
        <Text fontSize="18px">
          Quer imprimir de forma automática no seu computador? <br />
          Instale nosso <strong>AssistenteLS</strong> de forma gratuita.
        </Text>
        <Button
          as="a"
          href="https://workspace.londrisoft.com.br/assistenteLS.exe"
          leftIcon={<HiDownload size={20} />}
          colorScheme="purple"
          fontSize="14px"
        >
          Download
        </Button>
        <SvgComponent />
      </Flex> */}
      <Box mt="25px">
        {!!files.length ? (
          <Table variant={'striped'} size={'sm'}>
            <Thead>
              <Tr>
                <Th borderBottomWidth="0">Formato</Th>
                <Th borderLeft="1px solid #BABABA" borderBottomWidth="0">
                  Gerado em:
                </Th>

                <Th borderLeft="1px solid #BABABA" borderBottomWidth="0">
                  Nome
                </Th>
                <Th
                  borderLeft="1px solid #BABABA"
                  borderBottomWidth="0"
                  width={'30px'}
                >
                  <HiOutlinePrinter size={22} />
                </Th>
                <Th borderBottomWidth="0" width={'30px'}></Th>
              </Tr>
            </Thead>
            <Tbody>
              <tr></tr>
              {files.map((file) => (
                <Tr key={file.id}>
                  <Td fontWeight="bold" textTransform="uppercase">
                    .PDF
                  </Td>
                  <Td>{file.datainserido}</Td>
                  <Td>{file.mensagem.trim()}</Td>
                  <Td>
                    {file.status === 0 ? (
                      <HiXCircle size={22} color="#E5ACAC" />
                    ) : (
                      <HiCheckCircle size={22} color="#6CD9A0" />
                    )}
                  </Td>
                  <Td textAlign={'center'}>
                    {!file.report.trim().includes('.lbx') && (
                      <Button variant={'link'} onClick={() => handleFile(file)}>
                        <HiEye size={22} color="#1155bb" />
                      </Button>
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        ) : (
          <NotFoundFiles />
        )}
      </Box>

      <Modal onClose={onClose} size="full" isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="16px">{pdf.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box height="calc(100vh - 90px)">
              <embed
                src={`data:application/pdf;base64,${pdf.file}`}
                height="100%"
                width="100%"
              />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

const SvgComponent = () => (
  <svg
    width={300}
    height={90}
    viewBox="0 0 401 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M285.502 60c-28-19.2-8.166-36 3.5-43.5 24-15.428 24.5 12.5 65.5-10.5s43.5 36 46 57-34 6.5-56.5 21-23.5 0-58.5-24Z"
      fill="#6747B3"
      fillOpacity={0.2}
    />
    <path
      d="M384.503 74.988h-103.36V57.456c0-13.346 11.513-21.98 21.98-21.98h81.38v39.512Z"
      fill="#23164C"
    />
    <path
      d="M288.469 55.624c4.972-15.7 14.392-20.149 21.457-20.149h55.998c-8.583 0-21.719 10.729-21.719 20.149h-55.736Z"
      fill="#6747B3"
    />
    <path
      d="M288.469 55.624c-.533 3.624 0 19.102 0 19.102h55.998v-18.84c0-2.513.262-5.234 3.402-9.682-17.183-.087-55.213 0-55.213 0-2.093 3.14-3.653 5.796-4.187 9.42ZM334.262 21.345l-13.869 14.13h44.746l13.868-14.13h-44.745Z"
      fill="#23164C"
    />
    <path
      d="m364.877 35.475 14.13-14.13c2.094 2.094-.785 6.804-1.047 7.065l-7.065 7.065h-6.018ZM384.503 74.726h-32.186s-.129-17.042.685-21.226c.815-4.184 7.165-15.146 18.155-18.025h13.346v39.251Z"
      fill="#6747B3"
    />
    <circle cx={348.13} cy={60.858} r={1.57} fill="#6747B3" />
    <circle cx={348.13} cy={67.138} r={1.57} fill="#6747B3" />
    <path
      d="M316.468 40.97c2.879-3.14 9.159-5.495 9.159-5.495h13.868s-6.018 2.617-8.897 5.496h-14.13ZM285.329 70.801c.209 4.187 3.663 4.187 4.449 4.187h48.147V70.8h-52.596Z"
      fill="#23164C"
    />
    <path
      d="M329.706 70.801h-44.377c0 2.903 2.165 4 3.247 4.187h44.377c-2.165 0-3.067-2.791-3.247-4.187Z"
      fill="#6747B3"
    />
    <path
      d="M338.187 70.24v.561H294.75v-.56c0-6.628 5.372-12 12-12h19.437c6.627 0 12 5.372 12 12Z"
      fill="#010101"
    />
    <path
      d="M372.727 8c2.586 7.096-2.777 19.191-14.653 27.737h-32.971C335.909 27.162 342.416 14.53 339.757 8h32.97ZM278.002 82.838c16.747-4.71.785-8.374 21.719-14.13h32.97c-19.863 5.785-1.57 11.513-22.503 14.13h-32.186Z"
      fill="#FCF9CD"
    />
    <path
      opacity={0.7}
      d="M270.002 81.83c-78.334 3-207.5 4.636-199-50 3.5-22.5 34.666-19.666 34 0C104.335 51.497 99 80.33 40 74.5"
      stroke="#6747B3"
      strokeLinecap="round"
      strokeDasharray="10 10"
    />
    <g opacity={0.7} clipPath="url(#a)">
      <path
        d="m1.99 53.84 31.15 2.826a1.404 1.404 0 0 1 .943 2.347l-4.547 4.972-21.454-6.392c-.412-.125-.642.464-.253.651l19.98 9.669 1.973 4.272c.579 1.252-.706 2.444-1.868 1.88L22.65 71.52l-5.332 5.825a1.407 1.407 0 0 1-2.197-.157L.708 56.028c-.676-.988.118-2.294 1.281-2.188Z"
        fill="#6747B3"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path
          fill="#fff"
          transform="scale(-1 1) rotate(24.797 -109.108 -40.793)"
          d="M0 0h29.929v29.929H0z"
        />
      </clipPath>
    </defs>
  </svg>
)
