import { AspectRatio, Image } from '@chakra-ui/react'
import { useState } from 'react'
import { ModalFullImage } from './ModalFullImage'

export interface ImagemProps {
  title: string
  urlEmbed: string
}

export const Imagem = ({ title, urlEmbed }: ImagemProps) => {
  const [isOpenModalFullImage, setIsOpenModalFullImage] =
    useState<boolean>(false)
  return (
    <>
      <AspectRatio width={'110px'} maxW="110px" ratio={5 / 3}>
        <Image
          src={urlEmbed}
          alt={title}
          objectFit="cover"
          cursor={'pointer'}
          onClick={() => setIsOpenModalFullImage(true)}
          bg="high.pure"
          border="1px solid lightgray"
          _hover={{
            // border: '1px',

            borderColor: 'rgba(17, 85, 187, 1)',
            boxShadow:
              '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)',
          }}
          transition="all 0.1s linear"
        />
      </AspectRatio>
      <ModalFullImage
        isOpen={isOpenModalFullImage}
        onOpen={() => setIsOpenModalFullImage(true)}
        onClose={() => setIsOpenModalFullImage(false)}
        title={title}
        urlImage={urlEmbed}
      />
    </>
  )
}
