import { Icon, Tab, TabProps, Text } from '@chakra-ui/react'

type TabHeaderProps = TabProps & {
  title: string
  icon?: any
  hasSelect?: boolean
}

export const TabHeader = ({
  title,
  icon,
  hasSelect = false,
  ...props
}: TabHeaderProps) => {
  return (
    <Tab
      gap={2}
      py={2}
      minW="150px"
      minH="42px"
      _focus={{ boxShadow: 'none' }}
      {...props}
    >
      {!!icon && (
        <Icon
          as={icon}
          fill={hasSelect ? 'primary.medium' : 'low.light'}
          fontSize="22px"
        />
      )}
      <Text fontSize="sm" color={hasSelect ? 'primary.medium' : 'low.light'}>
        {title}
      </Text>
    </Tab>
  )
}
