import { ChangeEvent } from 'react'
import {
  Flex,
  Icon,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react'

import { ReactComponent as CalendarIcon } from 'src/assets/calendar.svg'

interface AnalyticalFiltersProps {
  changeSeler: (sellerId: number) => void
  changePeriod: (period: string) => void
  sellers: {
    codigo: number
    nome: string
  }[]
}

export const AnalyticalFilters = ({
  sellers,
  changePeriod,
  changeSeler,
}: AnalyticalFiltersProps) => {
  return (
    <Flex alignItems={'center'} gap="20px">
      <Select
        onChange={(e: ChangeEvent<HTMLSelectElement>) =>
          changeSeler(Number(e.target.value))
        }
        w="280px"
        h="36px"
        borderRadius={'4px'}
        fontSize="14px"
        placeholder="Selecione um vendedor"
      >
        {sellers?.map((seller) => (
          <option key={seller.codigo} value={seller.codigo}>
            {seller.nome}
          </option>
        ))}
      </Select>
      <Flex align="center">
        <Icon
          as={CalendarIcon}
          height={'20px'}
          width={'20px'}
          marginRight={'8px'}
        />
        <Text fontSize={'14px'} color={'low.light'} mr="10px">
          Período:
        </Text>

        <RadioGroup
          marginLeft={'12px'}
          onChange={changePeriod}
          defaultValue="mensal"
        >
          <Stack direction="row" spacing={5}>
            <Radio value="mensal" size={'sm'}>
              Mês atual
            </Radio>
            <Radio value="trimestral" size={'sm'}>
              Últimos 3 meses
            </Radio>
            <Radio value="semestral" size={'sm'}>
              Últimos 6 meses
            </Radio>
          </Stack>
        </RadioGroup>
      </Flex>
    </Flex>
  )
}
