import { Box, Flex, Image, Text } from '@chakra-ui/react'
import { ReactComponent as AnalyticsProLogo } from 'src/assets/analitics-pro-logo.svg'
import { ReactComponent as Quod_Store } from 'src/assets/icons/quod-details.svg'
import { ReactComponent as AnalyticsBILogo } from 'src/assets/analytics-bi-logo.svg'
import { ReactComponent as GestorLogo } from 'src/assets/gestor-logo.svg'
import { ReactComponent as UniqueLogo } from 'src/assets/unique-logo.svg'
import { ReactComponent as SimplazLogo } from 'src/assets/simplaz-logo.svg'
import { ReactComponent as MercadoLivreLogo } from 'src/assets/mercado-livre-logo.svg'
import { ReactComponent as ImportadorXlsLogo } from 'src/assets/importador-xls-logo.svg'
import AppLogo from 'src/assets/icons/int-logo.svg'
import SimplazIcon from 'src/assets/icons/simplaz-blue-icon.svg'
import MercadoLivreIcon from 'src/assets/icons/mercado-livre-icon.svg'
import IntelVendasIcon from 'src/assets/icons/inteligencia-vendas-icon.svg'
import AnalyticsBIIcon from 'src/assets/icons/analytics-bi-icon.svg'
import ImportadorXlsIcon from 'src/assets/icons/importador-xls-icon.svg'
import Quod_Stores from 'src/assets/icons/quod-store.svg'

export interface GetTagAppProps {
  appTag: string
}
export const GetAppLogoByTag = ({ appTag }: GetTagAppProps) => {
  return (
    <Box height={'38px'} width={'260px'} marginY={'5px'}>
      {appTag === '' ? null : appTag.toLowerCase() ===
        'ANALYTICSBI'.toLowerCase() ? (
        <Flex
          height={'inherit'}
          alignContent={'center'}
          justifyContent={'center'}
        >
          <AnalyticsBILogo style={{ height: 'inherit', width: '160px' }} />
        </Flex>
      ) : appTag.toLowerCase() === 'SIMPLAZ'.toLowerCase() ? (
        <Flex
          height={'inherit'}
          alignContent={'center'}
          justifyContent={'center'}
        >
          <SimplazLogo style={{ height: 'inherit', width: '160px' }} />
        </Flex>
      ) : appTag.toLowerCase() === 'GESTOR'.toLowerCase() ? (
        <Flex
          height={'inherit'}
          alignContent={'center'}
          justifyContent={'center'}
        >
          <GestorLogo style={{ height: 'inherit', width: '160px' }} />
        </Flex>
      ) : appTag.toLowerCase() === 'UNIQUE'.toLowerCase() ? (
        <Flex
          height={'inherit'}
          alignContent={'center'}
          justifyContent={'center'}
        >
          <UniqueLogo style={{ height: 'inherit', width: '160px' }} />
        </Flex>
      ) : appTag.toLowerCase() === 'MercadoLivre'.toLowerCase() ? (
        <Flex
          height={'inherit'}
          alignContent={'center'}
          justifyContent={'center'}
        >
          <MercadoLivreLogo style={{ height: 'inherit', width: '160px' }} />
        </Flex>
      ) : appTag.toLowerCase() === 'INTELVENDAS'.toLowerCase() ? (
        <Flex
          height={'inherit'}
          alignContent={'center'}
          justifyContent={'center'}
        >
          <Text
            height={'inherit'}
            width={'260px'}
            fontWeight={'700'}
            marginTop={'5px'}
          >
            Inteligência de Vendas
          </Text>
        </Flex>
      ) : appTag.toLowerCase() === 'INTELADM'.toLowerCase() ? (
        <Flex
          height={'inherit'}
          alignContent={'center'}
          justifyContent={'center'}
        >
          <Text
            height={'inherit'}
            width={'260px'}
            fontWeight={'700'}
            marginTop={'5px'}
          >
            Inteligência Administrativa
          </Text>
        </Flex>
      ) : appTag.toLowerCase() === 'EXCEL'.toLowerCase() ? (
        <Flex
          height={'inherit'}
          alignContent={'center'}
          justifyContent={'center'}
        >
          <Text
            height={'inherit'}
            width={'260px'}
            fontWeight={'700'}
            marginTop={'5px'}
          >
            <ImportadorXlsLogo style={{ height: 'inherit', width: '250px' }} />
          </Text>
        </Flex>
      ) : appTag.toLowerCase() === 'QUOD'.toLowerCase() ? (
        <Flex
          height={'inherit'}
          alignContent={'center'}
          justifyContent={'center'}
        >
          <Text
            height={'inherit'}
            width={'260px'}
            fontWeight={'700'}
            marginTop={'5px'}
          >
            <Quod_Store style={{ height: 'inherit', width: '250px' }} />
          </Text>
        </Flex>
      ) : null}
    </Box>
  )
}

export const GetAppIconByTag = ({ appTag }: GetTagAppProps) => {
  return (
    <Box height={'50px'} width={'50px'}>
      {appTag === '' ? null : appTag.toLowerCase() ===
        'ANALYTICSBI'.toLowerCase() ? (
        <Image height="inherit" width="inherit" src={AnalyticsBIIcon} />
      ) : appTag.toLowerCase() === 'SIMPLAZ'.toLowerCase() ? (
        <Image height="inherit" width="inherit" src={SimplazIcon} />
      ) : appTag.toLowerCase() === 'GESTOR'.toLowerCase() ? (
        <Image height="inherit" width="inherit" src={AppLogo} />
      ) : appTag.toLowerCase() === 'MercadoLivre'.toLowerCase() ? (
        <Image height="inherit" width="inherit" src={MercadoLivreIcon} />
      ) : appTag.toLowerCase() === 'INTELVENDAS'.toLowerCase() ? (
        <Image height="inherit" width="inherit" src={IntelVendasIcon} />
      ) : appTag.toLowerCase() === 'INTELADM'.toLowerCase() ? (
        <Image height="inherit" width="inherit" src={IntelVendasIcon} />
      ) : appTag.toLowerCase() === 'EXCEL'.toLowerCase() ? (
        <Image height="inherit" width="inherit" src={ImportadorXlsIcon} />
      ) : appTag.toLowerCase() === 'QUOD'.toLowerCase() ? (
        <Image height="inherit" width="inherit" src={Quod_Stores} />
      ) : null}
    </Box>
  )
}
