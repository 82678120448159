import { Outlet, useLocation } from 'react-router-dom'
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  useMediaQuery,
  useDisclosure,
} from '@chakra-ui/react'

import { Header } from './Header'

import { useEffect } from 'react'
import { SideMenu } from './SideMenu'

export const AppShell = () => {
  const { pathname } = useLocation()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [isLargerThan1300px] = useMediaQuery('(min-width: 1367px)')

  useEffect(() => {
    onClose()
  }, [onClose, pathname])

  return (
    <>
      <Flex h="100vh" w="full" m="0 auto" gap={4} flexDirection="column">
        <Header
          handleToogleMenu={() => onOpen()}
          hasToogleMenu={!isLargerThan1300px}
        />
        <Flex
          maxW="1440px"
          w="full"
          h="calc(100% - 80px)"
          margin="0 auto"
          gap={4}
          px={{ base: '0px', sm: '10px', md: '20px' }}
        >
          {isLargerThan1300px && <SideMenu />}
          <Flex w="full" h="full" maxW="1200px" margin="inherit">
            <Outlet />
          </Flex>
        </Flex>
      </Flex>

      {/* Drawer */}
      <Drawer isOpen={isOpen} onClose={() => onClose()} placement={'left'}>
        <DrawerOverlay />
        <DrawerContent width="230px !important">
          <DrawerBody padding={0} overflow={'hidden'}>
            <SideMenu />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}
