import { Box, Flex, Text } from '@chakra-ui/react'
import { GetAppLogoByTag } from 'src/utilities/GetTagApp'

export interface ListaAppUserProps {
  appAtivos?: string[]
}
export const ListaAppUser = ({ appAtivos }: ListaAppUserProps) => {
  function getImgAppAtivo(appTag: string) {
    return <GetAppLogoByTag appTag={appTag} />
  }
  return (
    <Flex flexDirection={'column'} gap={2}>
      <Text fontSize="sm">
        {appAtivos ? `Aplicativos ativos (${appAtivos?.length})` : ''}
      </Text>
      <Flex gap={5} flexWrap={'wrap'}>
        {appAtivos?.map((app) => {
          return getImgAppAtivo(app)
        })}
      </Flex>
    </Flex>
  )
}
