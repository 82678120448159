export const fildesGestor = [
  // Pedido
  'Código do Pedido',
  'Observação Pedido',
  // Cliente
  'Nome Cliente',
  'CPF/CNPJ',
  'RG/IE Cliente',
  'Celular Cliente',
  'Telefone Cliente',
  'E-mail Cliente',
  'Endereço Cliente',
  'Número',
  'Cidade',
  'Estado',
  'CEP',
  'Complemento',
  'Observação Endereço',
  // Produto
  'Nome do produto',
  'Quantidade',
  'Valor Unitário',
  'Valor Total Produto',
  'SKU (código do produto)',
  // Pagamento
  'Forma de Pagamento',
  'Valor Total',
]
