export const verifyAddress = (Address: any) => {
  if (
    Address.address === '' &&
    Address.city === '' &&
    Address.cityCode === null &&
    Address.complement === '' &&
    Address.number === '' &&
    Address.observation === '' &&
    Address.state === '' &&
    Address.zipCode === ''
  ) {
    return true
  } else {
    return false
  }
}
