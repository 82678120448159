import {
  Button,
  Center,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Spinner,
  Switch,
  Text,
  useToast,
} from '@chakra-ui/react'
import axios from 'axios'

import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Can } from 'src/components/Can'
import { FormInput } from 'src/components/FormInput'
import { FormSelect } from 'src/components/FormSelect'
import { csosnCosumidor, csosnPrincipal } from 'src/constants/csosn'
import { useSession } from 'src/contexts'

import { apiInstance } from 'src/services/api'
import { formatting, unFormatting } from 'src/utilities/formattingToPattern'
import { verifyData } from 'src/utilities/VerifyDataCompanySettings'
import {
  Company,
  Complementos,
  Atividade,
  ComplementosFilled,
  ISegements,
  IFiscal,
} from '../types'

interface Props {
  company: Company
  complemento: Complementos
}

let isFilleds: ComplementosFilled = {
  fantasia: '',
  insEstadual: '',
  insMunicipal: '',
  indAtividade: 0,
  outrasAtividades: '',
  qtColaboradores: 0,
  faturamentoAno: 0,
  csosn: 0,
  csosnNfce: 0,
  indRegime: 0,
  cnae: '0',
  descricaoCnae: '',
  count: 0,
}
let fiscals: IFiscal = {
  indIpi: false,
  indNfe: false,
  indNfce: false,
  indNfse: false,
  count: 0,
}
let segment: ISegements = {
  indSegComercio: 0,
  indSegEcommerce: 0,
  indSegServico: 0,
  indSegIndustria: 0,
  count: 0,
}

export const General = () => {
  const { register, handleSubmit, setValue } = useForm<Props>({
    shouldFocusError: false,
    reValidateMode: 'onChange',
  })

  //toast info users
  const toast = useToast()

  const { session } = useSession()
  const [isFetching, setIsFetching] = useState(true)
  const [atividade, setAtividade] = useState(0)
  const [outrasAtv, setOutrasAtv] = useState('')
  const [regime, setRegime] = useState('')

  const [atividades, setAtividades] = useState<Atividade[]>([])

  const getData = useCallback(async () => {
    try {
      setIsFetching(true)
      const {
        data: { complemento, company },
      } = await apiInstance(9000).get<Props>(
        `/workspace/Empresa/${session.company.document}`,
        {
          headers: {
            GatewayLS: '2e44bb6339e6aacd8faeca8fd4e8694e',
          },
        }
      )

      await new Promise((resolve) => setTimeout(resolve, 1000))

      setValue(
        'company.cnpjCpf',
        formatting({ value: company.cnpjCpf, pattern: 'document' })
      )
      setValue('company.razao', company.razao)

      if (!complemento) {
        return
      }

      const { isFilledCompany, isFiscal, isSegments } = verifyData(complemento)
      isFilleds = {
        fantasia: isFilledCompany.fantasia,
        insEstadual: isFilledCompany.insEstadual,
        insMunicipal: isFilledCompany.insMunicipal,
        indAtividade: isFilledCompany.indAtividade,
        outrasAtividades: isFilledCompany.outrasAtividades,
        qtColaboradores: isFilledCompany.qtColaboradores,
        faturamentoAno: isFilledCompany.faturamentoAno,
        csosn: isFilledCompany.csosn,
        csosnNfce: isFilledCompany.csosnNfce,
        indRegime: isFilledCompany.indRegime,
        cnae: isFilledCompany.cnae,
        descricaoCnae: isFilledCompany.descricaoCnae,
        count: 0,
      } as ComplementosFilled
      fiscals = {
        indIpi: isFiscal.indIpi,
        indNfce: isFiscal.indNfce,
        indNfe: isFiscal.indNfe,
        indNfse: isFiscal.indNfse,
      }
      segment = {
        indSegComercio: isSegments.indSegComercio,
        indSegEcommerce: isSegments.indSegEcommerce,
        indSegIndustria: isSegments.indSegIndustria,
        indSegServico: isSegments.indSegServico,
      }

      setValue('complemento.fantasia', complemento.fantasia || '')
      setValue('complemento.insEstadual', complemento.insEstadual || 'ISENTO')
      setValue('complemento.insMunicipal', complemento.insMunicipal || 'ISENTO')

      setValue('complemento.indAtividade', complemento.indAtividade)
      setAtividade(complemento.indAtividade)
      setValue('complemento.outrasAtividades', complemento.outrasAtividades)

      setValue(
        'complemento.indSegComercio',
        Boolean(complemento.indSegComercio)
      )
      setValue(
        'complemento.indSegEcommerce',
        Boolean(complemento.indSegEcommerce)
      )
      setValue('complemento.indSegServico', Boolean(complemento.indSegServico))
      setValue(
        'complemento.indSegIndustria',
        Boolean(complemento.indSegIndustria)
      )

      setValue('complemento.qtColaboradores', complemento.qtColaboradores)
      setValue('complemento.faturamentoAno', complemento.faturamentoAno)

      setValue('complemento.indIpi', Boolean(complemento.indIpi))
      setValue('complemento.indNfe', Boolean(complemento.indNfe))
      setValue('complemento.indNfce', Boolean(complemento.indNfce))
      setValue('complemento.indNfse', Boolean(complemento.indNfse))

      setValue('complemento.csosn', complemento.csosn)
      setValue('complemento.csosnNfce', complemento.csosnNfce)
      setValue('complemento.indRegime', complemento.indRegime)
      setRegime(complemento.indRegime.toString())

      setValue(
        'company.cep',
        formatting({ value: company.cep, pattern: 'cep' })
      )
      setValue('company.endereco', company.endereco)
      setValue('company.numero', company.numero)
      setValue('company.bairro', company.bairro)
      setValue('company.cidade', company.cidade)
      setValue('company.estado', company.estado)

      setValue('complemento.cnae', complemento.cnae)
      setValue('complemento.descricaoCnae', complemento.descricaoCnae)
      setValue('complemento.cnae2', complemento.cnae2)
      setValue('complemento.descricaoCnae2', complemento.descricaoCnae2)
      setValue('complemento.cnae3', complemento.cnae3)
      setValue('complemento.descricaoCnae3', complemento.descricaoCnae3)
      setValue('complemento.cnae4', complemento.cnae4)
      setValue('complemento.descricaoCnae4', complemento.descricaoCnae4)
      setValue('complemento.cnae5', complemento.cnae5)
      setValue('complemento.descricaoCnae5', complemento.descricaoCnae5)
    } catch (error) {
      console.log(error)
    } finally {
      setIsFetching(false)
    }
  }, [session.company.document, setValue])

  const getAtividades = async () => {
    try {
      const { data } = await apiInstance(9000).get(
        `/workspace/CadastroGenerico/?tabela=ATIVIDADES`,
        {
          headers: {
            GatewayLS: '2e44bb6339e6aacd8faeca8fd4e8694e',
          },
        }
      )
      setAtividades(data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const getCnae = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const { value, name } = e.target
      // 0500301
      const newValue = unFormatting(value)

      if (newValue.length >= 7) {
        const { data } = await axios.get(
          `https://servicodados.ibge.gov.br/api/v2/cnae/subclasses/${newValue}`
        )

        if (data.length !== 0) {
          switch (name) {
            case 'complemento.cnae':
              setValue('complemento.descricaoCnae', data[0].descricao)
              break
            case 'complemento.cnae2':
              setValue('complemento.descricaoCnae2', data[0].descricao)
              break
            case 'complemento.cnae3':
              setValue('complemento.descricaoCnae3', data[0].descricao)
              break
            case 'complemento.cnae4':
              setValue('complemento.descricaoCnae4', data[0].descricao)
              break
            case 'complemento.cnae5':
              setValue('complemento.descricaoCnae5', data[0].descricao)
              break
            default:
              break
          }
        }
      }

      if (newValue.length === 0 || newValue.length === 1) {
        switch (name) {
          case 'complemento.cnae':
            setValue('complemento.descricaoCnae', '')
            break
          case 'complemento.cnae2':
            setValue('complemento.descricaoCnae2', '')
            break
          case 'complemento.cnae3':
            setValue('complemento.descricaoCnae3', '')
            break
          case 'complemento.cnae4':
            setValue('complemento.descricaoCnae4', '')
            break
          case 'complemento.cnae5':
            setValue('complemento.descricaoCnae5', '')
            break
          default:
            break
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const attData: SubmitHandler<Props> = async ({ complemento, company }, e) => {
    try {
      const newData: Complementos = {
        ...complemento,
        cnpjCpfEmpresa: unFormatting(company.cnpjCpf),

        cnae: Number(unFormatting(complemento.cnae.toString())) || 0,
        cnae2: Number(unFormatting(complemento.cnae2.toString())) || 0,
        cnae3: Number(unFormatting(complemento.cnae3.toString())) || 0,
        cnae4: Number(unFormatting(complemento.cnae4.toString())) || 0,
        cnae5: Number(unFormatting(complemento.cnae5.toString())) || 0,

        descricaoCnae: complemento.descricaoCnae || '',
        descricaoCnae2: complemento.descricaoCnae2 || '',
        descricaoCnae3: complemento.descricaoCnae3 || '',
        descricaoCnae4: complemento.descricaoCnae4 || '',
        descricaoCnae5: complemento.descricaoCnae5 || '',

        indSegComercio: complemento.indSegComercio ? 1 : 0,
        indSegEcommerce: complemento.indSegEcommerce ? 1 : 0,
        indSegServico: complemento.indSegServico ? 1 : 0,
        indSegIndustria: complemento.indSegIndustria ? 1 : 0,

        indIpi: complemento.indIpi ? 1 : 0,
        indNfe: complemento.indNfe ? 1 : 0,
        indNfce: complemento.indNfce ? 1 : 0,
        indNfse: complemento.indNfse ? 1 : 0,

        faturamentoAno: Number(complemento.faturamentoAno) || 0,
        qtColaboradores: Number(complemento.qtColaboradores) || 0,

        csosn: Number(complemento.csosn),
        csosnNfce: Number(complemento.csosnNfce),
        indRegime: Number(complemento.indRegime),
        indAtividade: Number(complemento.indAtividade),
        outrasAtividades: outrasAtv,
      }

      isFilleds = {
        fantasia: newData.fantasia,
        insEstadual: newData.insEstadual,
        insMunicipal: newData.insMunicipal,
        indAtividade: newData.indAtividade,
        qtColaboradores: newData.qtColaboradores,
        faturamentoAno: newData.faturamentoAno,
        csosn: newData.csosn,
        csosnNfce: newData.csosnNfce,
        indRegime: newData.indRegime,
        cnae: newData.cnae,
        descricaoCnae: newData.descricaoCnae,
      }

      segment = {
        indSegComercio: newData.indSegComercio,
        indSegEcommerce: newData.indSegEcommerce,
        indSegServico: newData.indSegServico,
        indSegIndustria: newData.indSegIndustria,
      }

      fiscals = {
        indIpi: newData.indIpi,
        indNfe: newData.indNfe,
        indNfce: newData.indNfce,
        indNfse: newData.indNfse,
      }

      const valuesJson = Object.values(isFilleds)
      const valuesJsonSegements = Object.values(segment)
      const valuesJsonFiscal = Object.values(fiscals)

      const truesFilled = valuesJson.filter(
        (item) => item === false || item === '' || item === 0 || item === '0'
      )

      const truesSeg = valuesJsonSegements.filter(
        (item) => item === false || item === '' || item === 0 || item === '0'
      )

      const truesFiscal = valuesJsonFiscal.filter(
        (item) => item === false || item === '' || item === 0 || item === '0'
      )

      // setIsFilled((prevState) => ({ ...prevState, count: truesFilled.length }))
      // setStegments((prevState) => ({ ...prevState, count: truesSeg.length }))
      // setFiscal((prevState) => ({ ...prevState, count: truesFiscal.length }))

      if (
        truesFilled.length > 0 ||
        truesSeg.length === 4 ||
        truesFiscal.length === 4
      ) {
        toast({
          title: 'Preencha todos os dados obrigatórios',
          status: 'error',
          duration: 4500,
          isClosable: true,
        })
        return
      }

      toast({
        title: 'Atualizado com sucesso',
        status: 'success',
        duration: 4500,
        isClosable: true,
      })

      await apiInstance(9000).post(
        `/workspace/Empresa/${session.company.document}`,
        { complemento: newData },
        {
          headers: {
            GatewayLS: '2e44bb6339e6aacd8faeca8fd4e8694e',
          },
        }
      )

      await getData()
    } catch (error) {
      console.log(error)
    }
  }

  const handleChangeAtividade = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.currentTarget
    setAtividade(Number(value))
    if (value !== '199') {
      setOutrasAtv('')
      setValue('complemento.outrasAtividades', '')
    }
  }

  const handlChangeRegime = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.currentTarget
    setRegime(value)

    if (value === '3' || value === '4') {
      setValue('complemento.csosn', '')
      setValue('complemento.csosnNfce', '')
    }
  }

  useEffect(() => {
    getData()
  }, [getData])

  useEffect(() => {
    getAtividades()
  }, [])

  if (isFetching) {
    return (
      <Center h="full">
        <Spinner
          size="xl"
          color="primary.pure"
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
        />
      </Center>
    )
  }

  return (
    <Flex h="full" flexDir="column" as="form" onSubmit={handleSubmit(attData)}>
      <Flex flexDir="column" gap={4}>
        <Text>Dados da Empresa</Text>
        <HStack>
          <FormInput
            {...register('company.cnpjCpf')}
            label="CNPJ ou CPF"
            control={{ w: '220px' }}
            msg={0}
            isDisabled
          />
          <FormInput
            {...register('company.razao')}
            label="Razão social"
            isDisabled
            control={{ w: '320px' }}
            msg={0}
          />
          <FormInput
            {...register('complemento.fantasia')}
            label="Nome fantasia"
            control={{ w: '380px' }}
            isRequired
            msg={isFilleds.fantasia ? 0 : 1}
          />
        </HStack>
        <HStack>
          <FormInput
            {...register('complemento.insEstadual')}
            label="Inscrição Estadual"
            control={{ w: '220px' }}
            isRequired
            msg={isFilleds?.insEstadual ? 0 : 1}
          />
          <FormInput
            {...register('complemento.insMunicipal')}
            label="Inscrição Municipal"
            control={{ w: '220px' }}
            isRequired
            msg={isFilleds.insMunicipal ? 0 : 1}
          />
        </HStack>
      </Flex>

      <Divider my="22px" borderColor="low.light" />

      <Flex flexDir="column" gap={4}>
        <Flex flexDir={'row'}>
          <Text mr="10px">Informações da atividade</Text>
          <Text color="red.500">*</Text>
        </Flex>
        <HStack>
          <FormSelect
            {...register('complemento.indAtividade')}
            onChange={(e: ChangeEvent<HTMLSelectElement>) =>
              handleChangeAtividade(e)
            }
            label="Atividade principal"
            control={{ w: '400px' }}
          >
            {atividades.map((atividade) => (
              <option key={atividade.codigo} value={atividade.codigo}>
                {atividade.descricao}
              </option>
            ))}
          </FormSelect>

          <FormInput
            {...register('complemento.outrasAtividades')}
            isDisabled={atividade !== 199}
            label="Descrição da atividade (outros)"
            placeholder="Descrição da sua atividade principal"
            onChange={(e) => setOutrasAtv(e.target.value)}
            control={{ w: '50%' }}
            msg={0}
          />
        </HStack>
        <Flex flexDir="column" mt="10px">
          <Flex>
            <Text fontSize="xs" mr="10px">
              Seu Segmento
            </Text>
            <Text color="red.500" fontSize={'xs'}>
              *
            </Text>
          </Flex>

          <HStack spacing={4} mt="10px">
            <Checkbox
              colorScheme="blue"
              {...register('complemento.indSegComercio')}
            >
              <Text fontSize="14px">Comércio</Text>
            </Checkbox>
            <Checkbox
              colorScheme="blue"
              {...register('complemento.indSegEcommerce')}
            >
              <Text fontSize="sm">E-commerce</Text>
            </Checkbox>
            <Checkbox
              colorScheme="blue"
              {...register('complemento.indSegServico')}
            >
              <Text fontSize="sm">Serviços</Text>
            </Checkbox>
            <Checkbox
              colorScheme="blue"
              {...register('complemento.indSegIndustria')}
            >
              <Text fontSize="sm">Indústria</Text>
            </Checkbox>
          </HStack>
        </Flex>
        <HStack w="50%">
          <FormSelect
            label="Quantidade de colaboradores"
            {...register('complemento.qtColaboradores')}
            defaultValue=""
            isRequired
          >
            <option value="" disabled>
              Selecione uma opção
            </option>
            <option value="1">Até 5</option>
            <option value="2">De 5 a 10</option>
            <option value="3">De 10 a 15</option>
            <option value="4">15+</option>
          </FormSelect>

          <FormSelect
            label="Faturamento do último ano"
            {...register('complemento.faturamentoAno')}
            isRequired
            defaultValue=""
          >
            <option value="" disabled>
              Selecione uma opção
            </option>
            <option value="1">Até 81 mil</option>
            <option value="2">81 mil a 360 mil</option>
            <option value="3">360mil+ </option>
          </FormSelect>
        </HStack>
      </Flex>

      <Divider my="22px" borderColor="low.light" />

      <Flex flexDir="column" gap={6}>
        <Flex flexDir={'row'}>
          <Text mr="10px">Fiscal</Text>
          <Text color="red.500">*</Text>
        </Flex>

        <HStack>
          <FormSelect
            {...register('complemento.indRegime')}
            label="Regime tributario"
            control={{ w: '200px' }}
            isRequired
            onChange={(e: ChangeEvent<HTMLSelectElement>) =>
              handlChangeRegime(e)
            }
          >
            <option value=""></option>
            <option value="1">Micro Empresa</option>
            <option value="2">Simples Nacional</option>
            <option value="3">Lucro Presumido</option>
            <option value="4">Lucro Real</option>
          </FormSelect>
          <FormSelect
            {...register('complemento.csosn')}
            label="CSOSN principal"
            isDisabled={regime === '3' || regime === '4'}
            isRequired
          >
            <option value=""></option>
            {csosnPrincipal.map((csosn) => (
              <option key={csosn.code} value={csosn.code}>
                {csosn.name}
              </option>
            ))}
          </FormSelect>
          <FormSelect
            {...register('complemento.csosnNfce')}
            label="CSOSN consumidor"
            isDisabled={regime === '3' || regime === '4'}
            isRequired
          >
            <option value=""></option>
            {csosnCosumidor.map((csosn) => (
              <option key={csosn.code} value={csosn.code}>
                {csosn.name}
              </option>
            ))}
          </FormSelect>
        </HStack>
        <HStack mt="10px">
          <FormControl
            display="flex"
            flexDir="column"
            alignItems="center"
            w="150px"
            gap="5px"
          >
            <FormLabel htmlFor="ipi" mb="0" fontSize="xs">
              Contribuinte de I.P.I?
            </FormLabel>
            <Switch id="ipi" {...register('complemento.indIpi')} />
          </FormControl>
          <FormControl
            display="flex"
            flexDir="column"
            alignItems="center"
            w="150px"
            gap="5px"
          >
            <FormLabel htmlFor="nfe" mb="0" fontSize="xs">
              Utiliza NF-e?
            </FormLabel>
            <Switch id="nfe" {...register('complemento.indNfe')} />
          </FormControl>
          <FormControl
            display="flex"
            flexDir="column"
            alignItems="center"
            w="150px"
            gap="5px"
          >
            <FormLabel htmlFor="nfce" mb="0" fontSize="xs">
              Utiliza NFC-e ou SAT?
            </FormLabel>
            <Switch id="nfce" {...register('complemento.indNfce')} />
          </FormControl>
          <FormControl
            display="flex"
            flexDir="column"
            alignItems="center"
            w="150px"
            gap="5px"
          >
            <FormLabel htmlFor="nfse" mb="0" fontSize="xs">
              Utiliza NFS-e?
            </FormLabel>
            <Switch id="nfse" {...register('complemento.indNfse')} />
          </FormControl>
        </HStack>

        <Flex flexDir="column" gap={4}>
          <Flex gap={4} align="end">
            <FormInput
              placeholder="Ex: 47.13-0-02"
              label="CNAE principal"
              control={{ w: '163px' }}
              {...register('complemento.cnae')}
              msg={isFilleds.cnae ? 0 : 1}
              onChange={(e) => getCnae(e)}
              isRequired
            />
            <FormInput
              {...register('complemento.descricaoCnae')}
              label="Descrição"
              control={{ w: '540px' }}
              isDisabled
            />
          </Flex>
          <Flex gap={4} align="end">
            <FormInput
              placeholder="Ex: 47.13-0-02"
              label="CNAE 02"
              control={{ w: '163px' }}
              {...register('complemento.cnae2')}
              onChange={(e) => getCnae(e)}
            />
            <FormInput
              {...register('complemento.descricaoCnae2')}
              label="Descrição"
              control={{ w: '540px' }}
              isDisabled
            />
          </Flex>
          <Flex gap={4} align="end">
            <FormInput
              placeholder="Ex: 47.13-0-02"
              label="CNAE 03"
              control={{ w: '163px' }}
              {...register('complemento.cnae3')}
              onChange={(e) => getCnae(e)}
            />
            <FormInput
              {...register('complemento.descricaoCnae3')}
              label="Descrição"
              control={{ w: '540px' }}
              isDisabled
            />
          </Flex>
          <Flex gap={4} align="end">
            <FormInput
              placeholder="Ex: 47.13-0-02"
              label="CNAE 04"
              control={{ w: '163px' }}
              {...register('complemento.cnae4')}
              onChange={(e) => getCnae(e)}
            />
            <FormInput
              {...register('complemento.descricaoCnae4')}
              label="Descrição"
              control={{ w: '540px' }}
              isDisabled
            />
          </Flex>
          <Flex gap={4} align="end">
            <FormInput
              placeholder="Ex: 47.13-0-02"
              label="CNAE 05"
              control={{ w: '163px' }}
              {...register('complemento.cnae5')}
              onChange={(e) => getCnae(e)}
            />
            <FormInput
              {...register('complemento.descricaoCnae5')}
              label="Descrição"
              control={{ w: '540px' }}
              isDisabled
            />
          </Flex>
        </Flex>
      </Flex>

      <Divider my="22px" borderColor="low.light" />

      <Flex flexDir="column" gap={4} pb="20px">
        <Text>
          Endereço
          <Text as="span" fontSize="xs">
            (Não editável)
          </Text>
        </Text>
        <HStack>
          <FormInput
            {...register('company.cep')}
            isDisabled
            label="Cep"
            control={{ maxW: '160px' }}
          />
          <FormInput
            {...register('company.endereco')}
            isDisabled
            label="Endereço"
          />
          <FormInput
            {...register('company.numero')}
            isDisabled
            label="Número"
            control={{ maxW: '80px' }}
          />
          <FormInput
            {...register('company.bairro')}
            isDisabled
            label="Bairro"
          />
        </HStack>
        <HStack>
          <FormInput
            {...register('company.cidade')}
            isDisabled
            label="Cidade"
            control={{ w: '160px' }}
          />
          <FormInput
            {...register('company.estado')}
            isDisabled
            label="Estado"
            control={{ w: '160px' }}
          />
        </HStack>
      </Flex>

      <Can permissions={['ADMIN']}>
        <Flex
          justifyContent="end"
          position="fixed"
          right={{ sm: '9%', '2xl': '17%' }}
          top="90%"
        >
          <Button
            w="180px"
            colorScheme="green"
            type="submit"
            onClick={handleSubmit((d) => attData(d))}
          >
            Salvar
          </Button>
        </Flex>
      </Can>
    </Flex>
  )
}
