import { Flex, Image, Text } from '@chakra-ui/react'
import Step5SVG from '../../../../../../../assets/icons/step5.svg'
import FileIconSVG from '../../../../../../../assets/icons/fileicon.svg'

export const Header = () => {
  return (
    <Flex justifyContent="space-between">
      <Flex alignItems="center">
        <Image src={FileIconSVG} alt="fileicon" mb="8%" mt={'6%'} />
        <Flex display="grid" ml="19px">
          <Text fontWeight="600" fontSize="22px" color="#333333">
            Finalize sua importação
          </Text>
          <Text fontWeight="400" fontSize="16px" color="#333333">
            Selecione quais pedidos de sua planilha serão importados para o
            sistema Gestor
          </Text>
        </Flex>
      </Flex>

      <Flex alignItems="flex-end" flexDir="column">
        <Image src={Step5SVG} alt="step5" />
      </Flex>
    </Flex>
  )
}
