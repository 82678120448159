import { ReactNode } from 'react'
import { useCan } from 'src/hooks/useCan'

interface CanProps {
  children: ReactNode
  permissions?: string[]
  perfil?: number | null
}

export const Can = ({ children, permissions, perfil }: CanProps) => {
  const userCanSeeComponent = useCan({ permissions, perfil })

  if (!userCanSeeComponent) {
    return null
  }

  return <>{children}</>
}
