import { Flex } from '@chakra-ui/react'
import { GrFormClose } from 'react-icons/gr'
import { BsCheck } from 'react-icons/bs'

interface IBadge {
  dtPagamento: string
}

export const Badge = ({ dtPagamento }: IBadge) => {
  const getDateYearLesserThan1900 = () => {
    const dateYearLesserThan1900 = dtPagamento.split('-')

    return parseInt(dateYearLesserThan1900[0]) <= 1901
      ? ''
      : new Date(dtPagamento).toLocaleDateString()
  }
  const isPaidOut = () => {
    return getDateYearLesserThan1900() === '' ? false : true
  }
  return (
    <Flex
      w="85px"
      h="22px"
      borderRadius="16px"
      bg={isPaidOut() ? '#ECFDF3' : '#FEF3F2'}
      color={isPaidOut() ? '#027A48' : '#B42318'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      {isPaidOut() ? <BsCheck /> : <GrFormClose />}
      {isPaidOut() ? 'Pago' : 'Pendente'}
    </Flex>
  )
}
