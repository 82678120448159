import {
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { encryptedSimple } from 'src/utilities/hashing'
import { TReturnTable } from '../../types/types'

interface SavedModalProps {
  isOpen: boolean
  onClose: () => void
  setInputCorrelation: (value: string) => void
  inputCorrelation: string
  table: string[]
  returnTable: TReturnTable[]
  handleSaveCorrelation: () => void
  setIsModalCorrelation: (value: boolean) => void
}

export const SavedModal = ({
  isOpen,
  onClose,
  setInputCorrelation,
  inputCorrelation,
  table,
  returnTable,
  handleSaveCorrelation,
  setIsModalCorrelation,
}: SavedModalProps) => {
  const navigate = useNavigate()

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={'xl'}>
      <ModalOverlay />
      <ModalContent bg={'#ffffff'} borderRadius="12px" fontFamily="Inter">
        <ModalHeader>
          <Flex>
            <Text fontWeight={'600'} fontSize="32px">
              Deseja salvar a correlação para usar no futuro?
            </Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex>
            <Input
              type={'text'}
              onChange={(e) => setInputCorrelation(e.target.value)}
              placeholder={inputCorrelation}
            ></Input>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button
            w={'113px'}
            h="45px"
            color={'#1155BB'}
            border="none"
            bg={'transparent'}
            _hover={{ bg: 'transparente' }}
            cursor="pointer"
            onClick={() => {
              navigate(
                `/importador-xls/selectpage/${encryptedSimple({
                  table: table,
                  returnTable: returnTable,
                })}`
              )
              onClose()
            }}
          >
            Não salvar
          </Button>

          <Button
            w={'204px'}
            h="45px"
            color={'#ffffff'}
            borderRadius="6px"
            bg={'#1155BB'}
            _hover={{ bg: '#006AFF' }}
            cursor="pointer"
            disabled={inputCorrelation.length < 1}
            onClick={() => {
              setIsModalCorrelation(false)
              setInputCorrelation('')
              handleSaveCorrelation()
              navigate(
                `/importador-xls/selectpage/${encryptedSimple({
                  table: table,
                  returnTable: returnTable,
                })}`
              )
            }}
          >
            Salvar correlação
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
