import {
  Box,
  Button,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { Session } from 'src/contexts/sessions'
import { ReactComponent as GearIcon } from 'src/assets/icons/gear.svg'
import { ReactComponent as UserIcon } from 'src/assets/icons/user-icon.svg'

import { GetAppLogoByTag } from 'src/utilities/GetTagApp'
import { useNavigate } from 'react-router-dom'
import { useUsers } from 'src/screens/Users/hooks'
import { appsCadastrados } from 'src/utilities/storeApps'

export interface TabelaPlansAppProps {
  session: Session
}

export const TabelaPlansApp = ({ session }: TabelaPlansAppProps) => {
  const { users } = useUsers()
  const navigate = useNavigate()

  const appsAtivos = session.plan.aplicativos
    .split('|')
    .filter((x) => x.length > 1)

  function getQtdUserAtivoByApp(app: string) {
    return users.filter((u) => u.aplicativos && u.aplicativos.includes(app))
      .length
  }

  return (
    <Box height="500px">
      <Text
        marginTop={'20px'}
        fontSize={'sm'}
      >{` Aplicativos ativos (${appsAtivos.length})`}</Text>
      <Box overflowY={'auto'} height={'calc(100vh - 512px)'}>
        <TableContainer>
          <Table variant="simple" size={'xl'}>
            <Thead>
              <Tr>
                <Th></Th>
                <Th></Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {appsAtivos.map((app, index) => {
                if (appsCadastrados.some((x) => x === app)) {
                  return (
                    <Tr key={index}>
                      <Td>
                        <GetAppLogoByTag appTag={app} />
                      </Td>
                      <Td>
                        {app === 'INTELVENDAS' || app === 'INTELADM'  && (
                          <Flex alignItems={'center'} gap={3}>
                            <UserIcon style={{ height: '24px' }} />
                            <Text fontSize={'14px'} color={'#606060'}>
                              {` ${getQtdUserAtivoByApp(app)} usuários ativos`}
                            </Text>
                          </Flex>
                        )}
                      </Td>
                      <Td isNumeric>
                        {!['GESTOR', 'UNIQUE'].some((x) => x === app) ? (
                          <Button
                            variant={'outline'}
                            leftIcon={<GearIcon />}
                            size={'sm'}
                            colorScheme={'gray'}
                            onClick={() =>
                              navigate(`/store/integrations/${app}`)
                            }
                          >
                            <Text fontSize={'14px'}>Gerenciar App</Text>
                          </Button>
                        ) : (
                          ''
                        )}
                      </Td>
                    </Tr>
                  )
                }
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}
