import {
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react'
import { CaretDoubleRight } from 'phosphor-react'
import { Can } from 'src/components/Can'

import { formatting } from 'src/utilities/formattingToPattern'

interface Company {
  razao: string
  cnpjCpf: string
}

interface Props {
  companies: Company[]
  changeCompany: (company: Company) => void
  selectedCompany: Company
}

export const CompanyChange = ({
  companies,
  changeCompany,
  selectedCompany,
}: Props) => {
  // const { session } = useSession()

  return (
    <Can permissions={['ADMIN']}>
      <Popover placement="auto-start" trigger="click">
        <PopoverTrigger>
          <Button
            w="270px"
            variant="outline"
            size="sm"
            fontSize="sm"
            color="low.light"
            cursor="inherit"
            rightIcon={<CaretDoubleRight size={18} />}
          >
            Emitente:{' '}
            {formatting({
              value: selectedCompany?.cnpjCpf || '',
              pattern: 'document',
            })}
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />

          <PopoverHeader>Visualizar arquivos:</PopoverHeader>
          <PopoverBody>
            <Flex h="300px" overflowY="auto" gap={2} flexDir="column" p={1}>
              {companies.map((company) => (
                <Button
                  py="10px"
                  h="80px"
                  key={company.cnpjCpf}
                  flexDir="column"
                  gap={2}
                  onClick={() => changeCompany(company)}
                >
                  <Text fontSize="xs" whiteSpace="break-spaces" w="250px">
                    {company.razao.toLowerCase()}
                  </Text>
                  <Text fontSize="xs">
                    {formatting({
                      value: company.cnpjCpf,
                      pattern: 'document',
                    })}
                  </Text>
                </Button>
              ))}
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Can>
  )
}
