import { ModalBody, ModalContent, Spinner } from '@chakra-ui/react'

export const LoadingModal = () => {
  return (
    <ModalContent bg="#F7F8FA" h="487.3px">
      <ModalBody alignSelf="center" mt="34%">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </ModalBody>
    </ModalContent>
  )
}
