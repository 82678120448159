interface sendWhatsMessageProps {
  countryCode: string
  dddCode: string
  phoneNumber: string
  message: string
}
export function sendWhatsMessage({
  countryCode,
  dddCode,
  phoneNumber,
  message,
}: sendWhatsMessageProps) {
  const completePhoneNumber = countryCode.concat(
    dddCode.concat(phoneNumber.replaceAll(' ', ''))
  )

  const a = document.createElement('a')
  a.target = 'blank'
  a.href = `https://api.whatsapp.com/send?phone=${completePhoneNumber}&text=${message}&app_absent=1`
  a.click()
}
