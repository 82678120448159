import { Divider, Flex, Text } from '@chakra-ui/react'
import { ReactNode } from 'react'

interface SideMenuSectionProps {
  children: ReactNode | ReactNode[]
  title: string
}

export const SideMenuSection = ({ children, title }: SideMenuSectionProps) => {
  return (
    <Flex flexDir="column" w="full">
      <Divider my="12px" w="90%" mx="auto" />
      <Text fontSize="12px" color="low.light" px="16px" mb="4px">
        {title}
      </Text>
      {children}
    </Flex>
  )
}
