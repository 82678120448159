import { FormEvent, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import {
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Radio,
  RadioGroup,
  Text,
  Spinner,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Box,
  Tag,
  HStack,
  Icon,
  IconButton,
  Center,
} from '@chakra-ui/react'
import { MagnifyingGlass, UserCircleGear } from 'phosphor-react'
import { ReactComponent as UserAdd } from 'src/assets/icons/user_add.svg'
import { ReactComponent as NotFindImage } from 'src/assets/imgs/not-find.svg'
import { ProfileTag } from 'src/components/ProfileTag'

import { User, useUsers } from './hooks'
import { Can } from 'src/components/Can'

export const Users = () => {
  const navigate = useNavigate()
  const [radFilter, setRadFilter] = useState('0')
  const [textFilter, setTextFilter] = useState('')

  const { users, isFetching } = useUsers()

  const filteredUsers = useCallback(() => {
    let res = users
    if (users) {
      res = users?.filter((user) =>
        user.name.toLocaleLowerCase().includes(textFilter)
      )

      if (radFilter === '0') {
        res = res?.filter((user) => user.status)
      }
      if (radFilter === '1') {
        res = res?.filter((user) => !user.status)
      }

      return res
    }
    return []
  }, [radFilter, textFilter, users])

  const handleSelectUserEditing = (user: User) => {
    localStorage.setItem('ws@user', JSON.stringify(user))
    navigate('editing')
  }

  return (
    <>
      <Helmet>
        <title>Gerência de Usuário | Workspace</title>
      </Helmet>
      <Flex flexDirection="column" position="relative" w="full" gap={4}>
        <Flex
          align="center"
          justify="space-between"
          bg="high.pure"
          boxShadow={
            '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
          }
          borderRadius="4px"
          padding="8px 12px"
        >
          <Can permissions={['ADMIN']}>
            <Button
              variant="outline"
              borderColor="primary.pure"
              color="primary.pure"
              leftIcon={
                <Icon as={UserAdd} fontSize="22px" fill="primary.pure" />
              }
              _hover={{ bg: 'gray.100' }}
              onClick={() => navigate('create')}
            >
              Novo usuário
            </Button>
          </Can>

          <Flex align="center" gap={8} ml="auto">
            <RadioGroup
              name="rad"
              defaultValue="0"
              value={radFilter}
              onChange={setRadFilter}
            >
              <Text color="low.light" fontSize="sm">
                Mostrar:
              </Text>
              <HStack spacing={4}>
                <Radio size="sm" value="0">
                  Ativos
                </Radio>
                <Radio size="sm" value="1">
                  Inativos
                </Radio>
                <Radio size="sm" value="2">
                  Todos
                </Radio>
              </HStack>
            </RadioGroup>
            <InputGroup>
              <Input
                value={textFilter}
                onChange={(e: FormEvent<HTMLInputElement>) =>
                  setTextFilter(e.currentTarget.value)
                }
                borderRadius="4px"
                placeholder="Pesquisar"
              />
              <InputLeftElement children={<MagnifyingGlass />} />
            </InputGroup>
          </Flex>
        </Flex>

        {isFetching ? (
          <Center h="full" w="full">
            <Spinner
              size="xl"
              color="primary.pure"
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
            />
          </Center>
        ) : !isFetching && filteredUsers().length === 0 ? (
          <Center h="full" flexDir="column" gap={4}>
            <NotFindImage />
            <Text fontSize="sm" color="low.light">
              Nada por aqui! Verifique os filtros e tente novamente
            </Text>
          </Center>
        ) : (
          <Box
            bg="high.pure"
            boxShadow={
              '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
            }
            borderRadius="4px"
            overflowY="auto"
          >
            <Table size="sm">
              <Thead position="sticky" top="0" bg="high.pure">
                <Tr h="40px">
                  <Th
                    fontSize="sm"
                    fontWeight="400"
                    color="low.light"
                    borderWidth="0px"
                  >
                    Nome
                  </Th>
                  <Th
                    fontSize="sm"
                    fontWeight="400"
                    color="low.light"
                    borderWidth="0px"
                  >
                    E-mail
                  </Th>
                  <Th
                    fontSize="sm"
                    fontWeight="400"
                    color="low.light"
                    borderWidth="0px"
                  >
                    Status
                  </Th>
                  <Th
                    fontSize="sm"
                    fontWeight="400"
                    color="low.light"
                    borderWidth="0px"
                  >
                    Perfil
                  </Th>
                  <Th
                    fontSize="sm"
                    fontWeight="400"
                    color="low.light"
                    borderWidth="0px"
                  ></Th>
                </Tr>
              </Thead>

              <Tbody>
                {filteredUsers()?.map((user) => (
                  <Tr key={user.userId.toString()}>
                    <Td>{user.name}</Td>
                    <Td>{user.email}</Td>
                    <Td>
                      {user.status ? (
                        <Tag fontSize="12px" borderRadius="4px">
                          Ativo
                        </Tag>
                      ) : (
                        <Tag fontSize="12px" borderRadius="4px">
                          Inativo
                        </Tag>
                      )}
                    </Td>
                    <Td>
                      <ProfileTag profileId={user.perfilId} />
                    </Td>
                    <Td py="0">
                      <IconButton
                        size="sm"
                        position="initial"
                        variant="ghost"
                        aria-label="editar usuário"
                        icon={<UserCircleGear size={22} />}
                        onClick={() => handleSelectUserEditing(user)}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        )}
      </Flex>
    </>
  )
}

// <Flex
// width={'100%'}
// padding={{ base: '5px', md: '24px' }}
// flexDir={'column'}
// position={'relative'}
// >
// <Flex
//   justifyContent={'space-between'}
//   align={'center'}
//   margin={{ base: '0 auto', md: '0 0' }}
// >
//   <Button
//     display={{ base: 'none', md: 'inline-flex' }}
//     fontSize={'14px'}
//     onClick={() => navigate('create')}
//     colorScheme={'blue'}
//     leftIcon={<HiUserAdd size={22} />}
//     isDisabled={session.perfil !== 1 ? true : false}
//   >
//     Novo usuário{' '}
//   </Button>
//   <Button
//     display={{ base: 'inline-flex', md: 'none' }}
//     position={'fixed'}
//     bottom={'15px'}
//     left={'15px'}
//     opacity={0.6}
//     onClick={() => navigate('create')}
//     colorScheme={'blue'}
//     isDisabled={session.perfil !== 1 ? true : false}
//   >
//     <HiUserAdd size={22} />
//   </Button>
//   <Flex
//     align={'center'}
//     flexDirection={{ base: 'column', md: 'row' }}
//     mt={{ base: '10px', md: '0px' }}
//   >
//     <RadioGroup
//       name="rad"
//       marginRight="20px"
//       value={radioFilter}
//       defaultValue={'0'}
//       onChange={setRadioFilter}
//       marginBottom={{ base: '10px', md: '0px' }}
//     >
//       <Text
//         fontSize="14px"
//         color="low.light"
//         fontWeight="400"
//         display={{ base: 'none', md: 'block' }}
//       >
//         Mostrar:
//       </Text>
//       <Stack spacing={2} direction="row">
//         <Radio value="0" size="sm">
//           Ativos
//         </Radio>
//         <Radio value="1" size="sm">
//           Inativos
//         </Radio>
//         <Radio value="2" size="sm">
//           Todos
//         </Radio>
//       </Stack>
//     </RadioGroup>
//     <InputGroup>
//       <Input
//         placeholder="Pesquisar"
//         maxWidth={{ base: '100%', sm: '310px' }}
//         width="100%"
//         borderRadius="4"
//         borderColor="low.dark"
//         onChange={(e) => setTextFilter(e.target.value.toLowerCase())}
//       />
//       <InputLeftElement
//         children={<HiOutlineSearch size={22} color="#bababa" />}
//       />
//     </InputGroup>
//   </Flex>
// </Flex>

// {loadingUsers && (
//   <Flex
//     alignItems="center"
//     justifyContent="center"
//     height="calc(100vh - 210px)"
//   >
//     <Spinner
//       width={'100px'}
//       height={'100px'}
//       borderColor={'low.light'}
//     />
//   </Flex>
// )}

// {!loadingUsers && !!result()?.length && (
//   <Box
//     height={'100%'}
//     paddingRight={{ base: '0px', md: '10px' }}
//     overflowY={'auto'}
//     overflowX="hidden"
//     mt={'20px'}
//   >
//     <Table variant={'striped'} size={'sm'}>
//       <Thead>
//         <Tr>
//           <Th borderBottomWidth="0">Nome</Th>
//           <Th
//             borderBottomWidth="0"
//             borderLeft="1px solid #BABABA"
//             display={{ base: 'none', md: 'table-cell' }}
//           >
//             Email
//           </Th>
//           <Th
//             borderBottomWidth="0"
//             borderLeft="1px solid #BABABA"
//             display={{ base: 'none', lg: 'table-cell' }}
//           >
//             Status
//           </Th>
//           <Th
//             borderBottomWidth="0"
//             borderLeft="1px solid #BABABA"
//             display={{ base: 'none', lg: 'table-cell' }}
//           >
//             Perfil
//           </Th>
//           <Th w={'22px'} borderBottomWidth="0" />
//         </Tr>
//       </Thead>
//       <Tbody>
//         <tr />
//         {result()?.map((user) => (
//           <Tr key={user.userId}>
//             <Td minWidth="200px" wordBreak="break-word">
//               <Text
//                 color={{ base: 'primary.pure', md: 'low.dark' }}
//                 marginBottom="5px"
//               >
//                 {user.name}{' '}
//               </Text>

//               <Text
//                 marginBottom="5px"
//                 display={{ base: 'block', md: 'none' }}
//               >
//                 {user.email}
//               </Text>

//               <Tag
//                 display={{ base: 'inline-flex', lg: 'none' }}
//                 color={user.status ? 'low.dark' : 'high.pure'}
//                 size="sm"
//                 borderRadius="4px"
//                 backgroundColor={
//                   user.status ? 'success.light' : 'low.medium'
//                 }
//               >
//                 {user.status ? 'Ativo' : 'Inativo'}
//               </Tag>

//               <Tag
//                 display={{ base: 'inline-flex', lg: 'none' }}
//                 color="low.dark"
//                 size="sm"
//                 borderRadius="4px"
//                 backgroundColor={user.colorRole}
//                 marginLeft="10px"
//               >
//                 {user.role}
//               </Tag>
//             </Td>
//             <Td display={{ base: 'none', md: 'table-cell' }}>
//               {user.email}
//             </Td>
//             <Td display={{ base: 'none', lg: 'table-cell' }}>
//               <Tag
//                 size={'md'}
//                 borderRadius="4px"
//                 backgroundColor={
//                   user.status ? 'success.light' : 'low.light'
//                 }
//               >
//                 {user.status ? 'Ativo' : 'Inativo'}
//               </Tag>
//             </Td>
//             <Td display={{ base: 'none', lg: 'table-cell' }}>
//               <Tag
//                 size={'md'}
//                 borderRadius="4px"
//                 backgroundColor={user.colorRole}
//               >
//                 {user.role}
//               </Tag>
//             </Td>
//             <Td>
//               <Tooltip
//                 label="Editar"
//                 placement="left"
//                 color="primary.pure"
//                 backgroundColor="high.pure"
//                 fontSize="14px"
//               >
//                 <Button
//                   variant={'link'}
//                   onClick={() => handleSelectUserEditing(user)}
//                 >
//                   <HiPencil size={24} color="#1155BB" />
//                 </Button>
//               </Tooltip>
//             </Td>
//           </Tr>
//         ))}
//       </Tbody>
//     </Table>
//   </Box>
// )}

// {!loadingUsers && !result()?.length && (
//   <Flex alignItems="center" justifyContent="center" flex={1}>
//     <CantFind />
//   </Flex>
// )}
// </Flex>
