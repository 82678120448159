import { useCallback, useEffect, useState } from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'

import Chart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'

import { useSession } from 'src/contexts'
import { apiInstance } from 'src/services/api'
import { currency } from 'src/utilities/currency'

type Props = {
  step: number
  sellerId: number
  period: {
    initial: string
    final: string
  }
  analyticsToken: string | null
}

interface TermOfPaymentProps {
  total: number
  series: { x: string; y: string }[]
}

export const TermOfPayment = ({
  step,
  sellerId,
  period,
  analyticsToken,
}: Props) => {
  const { session } = useSession()

  const [termOfPayment, setTermOfPayment] = useState<TermOfPaymentProps>(
    {} as TermOfPaymentProps
  )

  const getTermOfPayment = useCallback(async () => {
    try {
      const { data } = await apiInstance(9000).get(
        `/relatorios/Prazo/?cnpjcpf=${session.company.document}&indvendedor=${sellerId}&dataini=${period.initial}&datafim=${period.final}`,
        {
          headers: {
            authorization: `bearer ${analyticsToken}`,
            GatewayLS: '2e44bb6339e6aacd8faeca8fd4e8694e',
          },
        }
      )
      setTermOfPayment({
        total: data.registros,
        series: data.prazo.map((p: any) => ({ x: p.nome, y: p.total })),
      })
    } catch (error) {
      console.log(error)
    }
  }, [
    analyticsToken,
    period.final,
    period.initial,
    sellerId,
    session.company.document,
  ])

  useEffect(() => {
    if (step === 1 && analyticsToken) {
      getTermOfPayment()
    }
  }, [analyticsToken, getTermOfPayment, step])

  const options = {
    dataLabels: {
      textAnchor: 'middle',
      offsetX: 20,
      style: {
        colors: ['#303030'],
        fontSize: '12px',
        fontFamily: 'Sora, sans-serif',
        fontWeight: 600,
      },

      formatter: (value: any, opt) => {
        return currency(value)
      },
      dropShadow: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: termOfPayment.series?.length < 2 ? '30%' : '60%',
        borderRadius: 4,
        dataLabels: {
          position: 'bottom',
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          fontSize: '12px',
          fontFamily: 'Sora, sans-serif',
          fontWeight: 400,
        },
      },
    },
    xaxis: {
      type: 'category',
      labels: {
        formatter: (value: any) =>
          new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(value),
      },
    },
    chart: {
      toolbar: {
        show: false,
      },
    },
    states: {
      active: {
        filter: {
          type: 'none',
        },
      },
      hover: {
        filter: {
          type: 'none',
        },
      },
    },
    tooltip: {
      enabled: false,
      y: {
        title: {
          formatter: () => '', //series
        },
      },
    },
  } as ApexOptions

  return (
    <Flex
      flexDir={'column'}
      boxShadow="0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)"
      borderRadius={'4px'}
      // width="calc(100vw - 252px)"
      m={'0 auto'}
      h="calc(100vh - 260px)"
      padding={'12px'}
    >
      <Box
        width={'100%'}
        height={'68px'}
        border={'1px solid #f0f0f0'}
        borderRadius={'4px'}
        padding={'8px 16px'}
        textAlign={'center'}
        marginBottom={'16px'}
      >
        <Text fontSize={'12px'} color={'low.light'}>
          Total
        </Text>
        <Text
          fontSize={'14px'}
          color={'low.medium'}
          mt={'10px'}
          fontWeight={'600'}
        >
          {' '}
          {termOfPayment.total || 0}
        </Text>
      </Box>

      <Box w="full" overflowY="auto" overflowX="hidden">
        {!!termOfPayment?.total ? (
          <Chart
            height={
              termOfPayment.series.length < 8
                ? 400
                : termOfPayment.series.length * 40
            }
            type={'bar'}
            series={[
              {
                data: termOfPayment?.series,
              },
            ]}
            options={options}
          />
        ) : (
          <Flex
            height={'100%'}
            minH={350}
            width={'full'}
            alignItems="center"
            justifyContent={'center'}
          >
            <Text fontSize={'14px'} color={'low.medium'}>
              Sem dados no período
            </Text>
          </Flex>
        )}
      </Box>
    </Flex>
  )
}
