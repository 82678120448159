import { ChakraProvider } from '@chakra-ui/react'
import { HelmetProvider } from 'react-helmet-async'
import { MainRoutes } from 'src/routes'
import { theme } from './styles/theme'
import { ContextsProvider } from './contexts/providers'
import { CookiesProvider } from 'react-cookie'

export const App = () => (
  <ChakraProvider theme={theme}>
    <HelmetProvider>
      <CookiesProvider>
        <ContextsProvider>
          <MainRoutes />
        </ContextsProvider>
      </CookiesProvider>
    </HelmetProvider>
  </ChakraProvider>
)
