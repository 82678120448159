import { ElementType } from 'react'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as GestorIcon } from '../../assets/icons/gestor.svg'
import { ReactComponent as SimplazIcon } from '../../assets/icons/simplaz_icon.svg'
import { ReactComponent as DashboardIcon } from '../../assets/icons/dashboard.svg'
import { ReactComponent as DashboardBIIcon } from '../../assets/icons/dashboard-bi.svg'
import { ReactComponent as FolderIcon } from '../../assets/icons/folder.svg'
import { ReactComponent as StoreIcon } from '../../assets/icons/store.svg'
import { ReactComponent as UsersIcon } from '../../assets/icons/user-search.svg'
import { ReactComponent as ClientIcon } from '../../assets/icons/every-user.svg'
import { ReactComponent as GearIcon } from '../../assets/icons/setting-two.svg'
import { ReactComponent as ImportadorIcon } from '../../assets/icons/importador-icon-sidemenu.svg'
import { ReactComponent as QuodIcon } from '../../assets/icons/quod-icon-sidebar.svg'

import { useSystems } from '../../utilities/useSystems'
import { useSession } from 'src/contexts/sessions'

type Sections = 'Produtos' | 'Gerência' | 'Outros'

interface Item {
  label: string
  icon: ElementType
  section: Sections
  action: () => void
  beta?: boolean
  permissions?: string[]
}

interface SideMenuSectionItems {
  name: Sections
  items: Item[]
}

export const useSideMenuSection = () => {
  const navigate = useNavigate()
  const { openGestor, openSimplaz } = useSystems()
  let { session } = useSession()

  const SideMenuSections: SideMenuSectionItems[] = [
    {
      name: 'Produtos',
      items: [
        {
          label: 'Gestor',
          icon: GestorIcon,
          section: 'Produtos',
          action: () => openGestor(),
          permissions: ['GESTOR', 'CLOUD'],
        },
        {
          label: 'Simplaz',
          icon: SimplazIcon,
          section: 'Produtos',
          action: () => openSimplaz(),
          beta: true,
          permissions: ['SIMPLAZ'],
        },
        {
          label: 'Importador XLS',
          icon: ImportadorIcon,
          section: 'Produtos',
          action: () => navigate('importador-xls'),
          beta: true,
          permissions: ['EXCEL', 'GESTOR', 'CLOUD'],
        },
        {
          label: 'Analytics',
          icon: DashboardIcon,
          section: 'Produtos',
          action: () => navigate('analytics'),
          beta: true,
          permissions: ['ANALYTICSBI', 'ADMIN'],
        },
        {
          label: 'Analytics PRO',
          icon: DashboardBIIcon,
          section: 'Produtos',
          action: () => navigate('analytics-pro'),
          beta: true,
          permissions: ['INTELVENDAS', 'GESTOR'],
        },
        {
          label: 'Analytics PRO',
          icon: DashboardBIIcon,
          section: 'Produtos',
          action: () => navigate('analytics-pro'),
          beta: true,
          permissions: ['INTELADM', 'UNIQUE'],
        },
        {
          label: 'Quod',
          icon: QuodIcon,
          section: 'Produtos',
          action: () => navigate('quod'),
          beta: true,
          permissions: ['QUOD'],
        },
      ],
    },
    {
      name: 'Gerência',
      items: [
        {
          label: 'Arquivos',
          icon: FolderIcon,
          section: 'Gerência',
          action: () => navigate('files'),
          permissions: ['GESTOR'],
        },

        {
          label: 'Usuários',
          icon: UsersIcon,
          section: 'Gerência',
          action: () => navigate('users'),
        },
        {
          label: 'Participantes',
          icon: ClientIcon,
          section: 'Gerência',
          action: () => navigate('participants'),
          permissions: ['UNIQUE'],
          beta: true,
        },
        {
          label: 'Configurações',
          icon: GearIcon,
          section: 'Gerência',
          action: () => navigate('company-settings'),
        },
      ],
    },
    {
      name: 'Outros',
      items: [
        {
          label: 'Apps',
          icon: StoreIcon,
          section: 'Outros',
          action: () => navigate('/store/integrations'),
          // permissions: ['ADMIN'],
          beta: true,
        },
      ],
    },
  ]

  return { SideMenuSections }
}
