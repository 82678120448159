import { forwardRef, ReactNode, useState } from 'react'

import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  InputLeftElement,
  Text,
} from '@chakra-ui/react'
import { FieldError } from 'react-hook-form'
import { Eye, EyeSlash } from 'phosphor-react'

interface FormInput extends InputProps {
  label: string
  error?: FieldError
  rightElement?: ReactNode
  leftElement?: ReactNode
  control?: FormControlProps
  msg?: string | number
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FormInput = forwardRef<HTMLInputElement, FormInput>(
  (props, ref) => {
    const {
      label,
      name,
      error,
      control,
      isRequired,
      leftElement,
      rightElement,
      msg,
    } = props
    const [type, setType] = useState(() => props.type)
    return (
      <FormControl id={name} isInvalid={!!error} {...control}>
        <FormLabel
          fontSize="xs"
          display="inline-block"
          mb="3px"
          color={props.isDisabled ? 'low.light' : 'inherit'}
        >
          {label}
          {isRequired && (
            <Text as="span" color="red.500" ml="4px">
              *
            </Text>
          )}
        </FormLabel>

        <InputGroup
          border={`0px solid ${msg === 1 ? '#ff0000' : '#CBD5E0'}`}
          borderRadius="4px"
        >
          {!!leftElement && (
            <InputLeftElement h="36px" children={leftElement} />
          )}

          <Input
            name={name}
            ref={ref}
            bgColor="high.pure"
            borderRadius="4px"
            fontSize="sm"
            w="inherit"
            h="36px"
            {...props}
            type={type}
            isRequired
          />
          {!!props.rightElement && props.type !== 'password' && (
            <InputRightElement children={rightElement} h="36px" />
          )}
          {props.type === 'password' && (
            <InputRightElement
              h="36px"
              cursor="pointer"
              onClick={() =>
                setType((t) => (t === 'password' ? 'text' : 'password'))
              }
              children={
                type === 'password' ? <EyeSlash size={22} /> : <Eye size={22} />
              }
            />
          )}
        </InputGroup>

        {!!error && (
          <FormErrorMessage mt="4px" fontSize="xs">
            {error.message}
          </FormErrorMessage>
        )}
      </FormControl>
    )
  }
)
