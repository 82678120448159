import { Flex, Heading, Text, Fade, Box, Link } from '@chakra-ui/react'
import { Helmet } from 'react-helmet-async'

import { ReactComponent as Illustration } from 'src/assets/imgs/robot.svg'

import { useSession } from 'src/contexts'

export const Initial = () => {
  const { session } = useSession()

  return (
    <Fade in={true} style={{ width: '100%' }} delay={0.2}>
      <Helmet>
        <title>Início | Workspace</title>
      </Helmet>
      <Flex
        flexDir="column"
        position="relative"
        w="full"
        h="full"
        p="5"
        bg="high.pure"
        boxShadow={
          '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
        }
        borderRadius="4px"
      >
        <Box display="inline-flex" flexDirection="column" zIndex={2}>
          <Heading
            fontWeight="700"
            fontSize="40px"
            lineHeight="48px"
            borderBottom="1px solid #E6E6E6"
            pb="12px"
            mb="12px"
            w="max-content"
          >
            Bem-vindo ao <br /> Workspace Londrisoft
          </Heading>
          <Text
            color="low.medium"
            fontSize="20px"
            fontWeight="bold"
            my="24px"
          >{`Olá, ${session.name}!`}</Text>
          <Flex flexDir="column" gap={8} mt="12px">
            <Text color="low.medium">
              Visando entregar a melhor experiência para você, estamos
              realizando melhorias no Workspace. Durante esse período podem
              ocorrer variações na sua navegação no Ecossistema. As mudanças
              estarão concluídas até as próximas versões.
            </Text>
            <Text color="low.medium">
              Fique por dentro das novidades através da nossa{' '}
              <Link
                color="primary.pure"
                fontWeight="bold"
                href="https://londrisoft.movidesk.com/kb/pt-br"
                isExternal
              >
                {' '}
                Base de Conhecimento.
              </Link>
            </Text>
            <Text color="low.medium">
              Tem uma sugestão e quer compartilhar com a gente? Comente em nosso
              mural de sugestão clicando{' '}
              <Link
                color="primary.pure"
                fontWeight="bold"
                href="https://feedback.userreport.com/ccb1f13f-c50f-425e-a386-37a81026a972"
                isExternal
              >
                aqui
              </Link>
              .
            </Text>
          </Flex>
        </Box>
        <Box
          h="full"
          w="full"
          position="absolute"
          bottom="-36%"
          right="-25%"
          // transform="translateX(-50%)"
          zIndex={1}
        >
          <Illustration style={{ opacity: 0.8, height: '60%' }} />
        </Box>
      </Flex>
    </Fade>
  )
}
