import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { useSession } from 'src/contexts'
import { sendWhatsMessage } from 'src/utilities/sendWhatsMessage'
import { ReactComponent as WhatsIcon } from '../../../../../assets/icons/whatsapp-white.svg'

export interface ModalTenhoInteresseProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  app_interesse: { appTag: string; appName: string }
}
export const ModalTenhoInteresse = ({
  isOpen,
  onOpen,
  onClose,
  app_interesse,
}: ModalTenhoInteresseProps) => {
  const { session } = useSession()
  return (
    <Modal isCentered size={'2xl'} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text fontSize={'20px'} fontWeight={700}>
            Falta pouco para ativar o seu {app_interesse.appName}
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flexDirection={'column'} gap={5}>
            <Flex flexDirection={'column'} gap={2}>
              <Text fontSize={'16px'}>
                Sua solicitação foi enviada ao nosso time comercial.
              </Text>
              <Text fontSize={'16px'}>
                Em breve um de nossos consultores entrará em contato.
              </Text>
            </Flex>
            <Flex justifyContent={'center'}>
              <Button
                leftIcon={<WhatsIcon style={{ height: '25px' }} />}
                width={'30%'}
                colorScheme={'whatsapp'}
                onClick={() =>
                  sendWhatsMessage({
                    countryCode: '+55',
                    dddCode: '43',
                    phoneNumber: '33159335',
                    message: `Olá! sou ${session.name}, da empresa ${session.company.name}, CNPJ: ${session.company.document}. Gostaria de mais informações a respeito do aplicativo ${app_interesse.appName}.`,
                  })
                }
              >
                <Text fontSize={'14px'}>Quero falar agora</Text>
              </Button>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
