import { Flex, Text } from '@chakra-ui/react'

export const Quod = () => {
  return (
    <Flex w="100%">
      <object
        width={'100%'}
        type="text/html"
        data="https://consultasoluti.com.br/loja/?p=i559235-londrisoft-"
      >
        <p>Your browser does not support iframes.</p>
      </object>
    </Flex>
  )
}
