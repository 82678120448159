import {
  ComplementosFilled,
  IFiscal,
  ISegements,
  IVerifyDataCompany,
} from 'src/screens/Company-settings/types'

export function verifyData(attData: IVerifyDataCompany) {
  const isFilledCompany: ComplementosFilled = {
    fantasia: attData.fantasia,
    insEstadual: attData.insEstadual,
    insMunicipal: attData.insMunicipal,
    indAtividade: attData.indAtividade,
    outrasAtividades: attData.outrasAtividades,
    qtColaboradores: attData.qtColaboradores,
    faturamentoAno: attData.faturamentoAno,
    csosnNfce: attData.csosnNfce,
    csosn: attData.csosn,
    indRegime: attData.indRegime,
    cnae: attData.cnae,
    descricaoCnae: attData.descricaoCnae,
  }

  const isFiscal: IFiscal = {
    indIpi: attData.indIpi,
    indNfce: attData.indNfce,
    indNfe: attData.indNfe,
    indNfse: attData.indNfse,
  }

  const isSegments: ISegements = {
    indSegComercio: attData.indSegComercio,
    indSegEcommerce: attData.indSegEcommerce,
    indSegIndustria: attData.indSegIndustria,
    indSegServico: attData.indSegServico,
  }

  const data = {
    isFilledCompany,
    isFiscal,
    isSegments,
  }

  return data
}
