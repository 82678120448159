export const clearCurrency = (value: string): number => {
  const sepValueCurrency = value.split(',')

  const valuesInvalid = [
    '.',
    ',',
    'R$',
    'RS',
    'U$',
    'US',
    ' ',
    'R',
    'S',
    'U',
    '$',
  ]
  const sepValue = sepValueCurrency[0].split('')

  const valueFormatterFilter = sepValue.filter(
    (item) => !valuesInvalid.includes(item)
  )
  const valueFormatted = valueFormatterFilter.join('')
  const valueFormatedWithCents = `${valueFormatted}.${sepValueCurrency.slice(
    -1
  )}`

  return sepValueCurrency.length > 1
    ? parseFloat(valueFormatedWithCents)
    : parseInt(valueFormatted)
}
