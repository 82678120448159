import { Button, Flex } from '@chakra-ui/react'
import { Preco } from '../Preco'
import { ReactComponent as UserAddIcon } from 'src/assets/icons/user-add-white.svg'

export interface ActionButtonProps {
  action: () => void
  labelButton: string
}
export const ActionButton = ({
  action,
  labelButton,
}: ActionButtonProps) => {
  return (
    <Flex gap={5} alignItems={'center'}>
      <Button
        colorScheme={'blue'}
        fontSize={'14px'}
        onClick={() => action()}
        leftIcon={
          labelButton === 'Adicionar usuário' ? <UserAddIcon /> : undefined
        }
      >
        {labelButton}
      </Button>
    </Flex>
  )
}
