import { Flex, Text, Image } from '@chakra-ui/react'
import WppIcon from 'src/assets/icons/wpp-chager.svg'

export const FooterIndex = () => {
  return (
    <Flex mt="41px" flexDir={'row'} w="100%">
      <Flex flexDir={'column'}>
        <Text fontSize={'16px'} fontWeight={'600'}>
          Status do cliente
        </Text>
        <Text fontSize={'13px'} fontWeight={'400'}>
          Fique em dia com a cobrança e evite cancelamento.
        </Text>
      </Flex>

      <Flex ml="100px">
        <Image src={WppIcon} mr="21px" />
        <Flex flexDir="column">
          <Text fontSize={'16px'} fontWeight={'600'}>
            Dúvidas?
          </Text>
          <Text fontSize={'14px'} fontWeight={'400'} color="#475467">
            Entre em contato com nossa equipe amigável através do Whatsapp com
            perguntas.
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}
