import {
  Flex,
  ModalBody,
  ModalContent,
  Image,
  Text,
  Button,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

import { statusNotes } from '../../index'

import SaveFileSVG from '../../../../../../../assets/icons/savefilesucess.svg'
import BgSVG from '../../../../../../../assets/icons/londrisoftbg.svg'

interface SuccessModalProps {
  lineSelectedToImported: statusNotes
}

export const SuccessModal = ({ lineSelectedToImported }: SuccessModalProps) => {
  const navigate = useNavigate()

  return (
    <ModalContent bg={'#1155bb'} borderRadius="none" fontFamily={'Inter'}>
      <Flex justifyContent={'center'} alignItems="center" flexDir={'column'}>
        <Flex
          w={'798px'}
          h="506px"
          alignSelf={'center'}
          borderRadius="12px"
          mt={'126px'}
        >
          <ModalBody bg={'#fff'} borderRadius="12px">
            <Flex display="grid " justifyItems="center" mt="8%">
              <Image src={SaveFileSVG} alt="savefile" />
              <Text
                fontWeight="600"
                fontSize="34px"
                color="#333333"
                textAlign={'center'}
                mt="27px"
              >
                Parabéns!
                <Text mt="-10px">
                  Você acabou de importar{' '}
                  {lineSelectedToImported.lineSelectedToImport.length}{' '}
                  {lineSelectedToImported.lineSelectedToImport.length > 1
                    ? 'pedidos'
                    : 'pedido'}
                </Text>
              </Text>

              <Flex justifyItems={'center'} textAlign="center">
                <Text>
                  <Text
                    color="#333333"
                    fontSize="22px"
                    mt="14px"
                    fontWeight={'400'}
                  >
                    Encontre no Gestor:
                  </Text>
                  <Text fontWeight={'400'} fontSize="14px">
                    "Faturamento" {'>'} "Menu Utilitários" {'>'} "Importação de
                    pedidos Integrações"
                  </Text>
                </Text>
              </Flex>
            </Flex>

            <Flex justifyContent={'center'} mt="6%">
              <Button
                bg={'#1155BB'}
                _hover={{ bg: '#006AFF' }}
                color="#fff"
                w={'267px'}
                h="52px"
                borderRadius={'4px'}
                fontWeight="700"
                fontSize={'16px'}
                onClick={() => {
                  navigate('/importador-xls')
                }}
              >
                Retornar ao início
              </Button>
            </Flex>
          </ModalBody>
        </Flex>
        <Flex mt={'129px'}>
          <Image src={BgSVG} alt="bg" />
        </Flex>
      </Flex>
    </ModalContent>
  )
}
