import { cpf, cnpj } from 'cpf-cnpj-validator'

export const validationLineTable = (type: string, data: string): boolean => {
  switch (type) {
    case 'cpf':
      return cpf.isValid(data.toString()) || cnpj.isValid(data.toString())

    case 'rg':
      data = data.toString().replace(/\D/g, '')
      data = data
        .toString()
        .replace(/(\d{1,2})(\d{3})(\d{3})(\d{1})$/, '$1.$2.$3-$4')

      return data ? true : false

    // case 'string':
    //   return typeof data === 'string' ? true : false

    // case 'number':
    //   return true

    default:
      return true
  }
}
