import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  List,
  ListItem,
  UnorderedList,
  Link,
} from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSearchParams } from 'react-router-dom'
import { apiInstance } from 'src/services/api'

import animation from 'src/components/Animations/files-transfer.json'
import animationError from 'src/components/Animations/not-found.json'
import { ReactComponent as Logo } from 'src/assets/logo_complete.svg'
import Lottie from 'react-lottie'
import { EmptyFile } from 'src/components/Animations'

export const PDFviewer = () => {
  const [searchParams] = useSearchParams()
  const [file, setFile] = useState('')
  const [loading, setLoading] = useState(true)
  const [fail, setFail] = useState(false)
  const [attempts, SetAttempts] = useState(0)
  const [emptyFile, setEmptyFile] = useState(true)

  const getFileHash = async (hash: string | null) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 3000))

      const { data } = await apiInstance(8010).get<any>(
        `/Relatorios/ContentByHash/${hash}`
      )

      if (data.mensagem.trim() === 'ARQUIVO EM BRANCO') {
        setEmptyFile(true)
      }

      setFile(() => data.arquivo)
      setLoading(false)
    } catch (error) {
      // setFail(true)
      SetAttempts((state) => state + 1)
    }
  }

  const hasHash = useCallback(() => {
    const b64 = searchParams.get('hash' || undefined)
    setFile(b64!)
    return b64
  }, [searchParams])

  useEffect(() => {
    if (!!hasHash()) {
      if (attempts >= 3) {
        // setFail(true)
        return
      }
      getFileHash(hasHash())
      return
    }
    // setFail(true)
  }, [hasHash, attempts])

  return (
    <>
      <Helmet>
        <title>PDF Viewer | Workspace</title>
      </Helmet>
      {!loading && !!file && (
        <Box height="100vh">
          <embed
            src={`data:application/pdf;base64,${file}`}
            height="100%"
            width="100%"
          />
        </Box>
      )}

      {loading && !!emptyFile && (
        <Flex
          height="100vh"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Logo />
          <Lottie
            style={{ fillOpacity: 0, pointerEvents: 'none' }}
            options={{
              animationData: fail ? animationError : animation,
              autoplay: true,
              loop: true,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
            width={400}
            height={400}
          />
          <Text fontSize={{ base: '14px', md: '16px' }} mt="10px" mb="10px">
            {fail
              ? 'Link expirado ou incorreto 😥'
              : ' Aguarde, estamos montando o arquivo 😉.'}
          </Text>
        </Flex>
      )}

      {emptyFile && (
        <Flex
          height="100vh"
          // justifyContent="center"
          // alignItems="center"
          flexDirection="column"
          padding={[2, 10]}
          overflowY="auto"
        >
          <Flex flexDirection={'column'} width="80%" margin={'0 auto'}>
            <Heading color={'error.medium'} fontSize={'48px'} fontWeight="bold">
              Ahh não!
            </Heading>

            <Text marginTop={'10px'} fontSize="16px" fontWeight={'bold'}>
              Parece que seu arquivo está vazio.
            </Text>

            <Flex flexDirection={'column'} alignItems="center">
              <EmptyFile />
              <Text fontSize="14px" fontWeight={'bold'}>
                Não há informações a serem exibidas, favor revisar as
                informações.
              </Text>
            </Flex>

            <Flex flexDirection={'column'} marginTop={10}>
              <Text color={'low.medium'} fontWeight="600" fontSize={'16px'}>
                Como evitar este tipo de situação?
              </Text>

              <UnorderedList spacing={'3'} marginTop={'3'}>
                <ListItem color={'low.medium'} fontSize={'14px'}>
                  Confira as informações antes de gerar o arquivo.
                </ListItem>
                <ListItem color={'low.medium'} fontSize={'14px'}>
                  Analise o período informado, verifique se a lançamentos.
                </ListItem>
              </UnorderedList>
            </Flex>
            <Flex flexDirection={'column'} marginTop={10}>
              <Text color={'low.medium'} fontSize="14px">
                Links úteis
              </Text>
              <Flex
                alignItems={'center'}
                justifyContent="space-between"
                marginTop={'10px'}
              >
                <Link
                  color={'primary.medium'}
                  fontSize="12px"
                  href="https://workspace.londrisoft.com.br/initial?utm_source=workspace&utm_medium=home&utm_campaign=arquivo_em_branco"
                >
                  Ínicio
                </Link>
                <Link
                  color={'primary.medium'}
                  fontSize="12px"
                  href="https://workspace.londrisoft.com.br/files?utm_source=workspace&utm_medium=arquivo&utm_campaign=arquivo_em_branco"
                >
                  Arquivos
                </Link>
                <Link
                  color={'primary.medium'}
                  fontSize="12px"
                  href="https://londrisoft.movidesk.com/kb/pt-br?utm_source=workspace&utm_medium=arquivo&utm_campaign=arquivo_em_branco"
                >
                  Central de ajuda
                </Link>
                <Link
                  color={'primary.medium'}
                  fontSize="12px"
                  href="https://londrisoft.com.br/?utm_source=workspace&utm_medium=arquivo&utm_campaign=arquivo_em_branco"
                >
                  Londrisoft
                </Link>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  )
}
