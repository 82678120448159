import {
  Box,
  Button,
  Center,
  Checkbox,
  Divider,
  Fade,
  Flex,
  HStack,
  SimpleGrid,
  Spinner,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { FormEvent, useCallback, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormInput } from 'src/components/FormInput'

import { useSession } from 'src/contexts'
import { apiInstance } from 'src/services/api'
import { formatting, unFormatting } from 'src/utilities/formattingToPattern'

import { Atividade, Company, Complementos } from '../types'

interface Props {
  company: Company
  complemento: Complementos
}

export const Unique = () => {
  const { session } = useSession()
  const [isFetching, setIsFetching] = useState(true)
  const [atividades, setAtividades] = useState<Atividade[]>([])
  const [comp, setComp] = useState({} as Complementos)

  const toast = useToast();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting, errors },
  } = useForm<Props>({
    shouldFocusError: false,
    reValidateMode: 'onChange',
  })

  const getData = useCallback(async () => {
    try {
      setIsFetching(true)
      const {
        data: { complemento, company },
      } = await apiInstance(9000).get<Props>(
        `/workspace/Empresa/${session.company.document}`,
        {
          headers: {
            GatewayLS: '2e44bb6339e6aacd8faeca8fd4e8694e',
          },
        }
      )

      setValue(
        'company.cnpjCpf',
        formatting({ value: company.cnpjCpf, pattern: 'document' })
      )
      setValue('company.razao', company.razao)

      setValue('complemento.fantasia', complemento.fantasia || '')
      setComp(complemento)
      await new Promise((resolve) => setTimeout(resolve, 1000))
    } catch (error) {
      console.log(error)
    } finally {
      setIsFetching(false)
    }
  }, [session.company.document, setValue])

  const getAtividades = useCallback(async () => {
    try {
      const { data } = await apiInstance(9000).get(
        `/workspace/CadastroGenerico/?tabela=ATUACAOESCRITORIO`,
        {
          headers: {
            GatewayLS: '2e44bb6339e6aacd8faeca8fd4e8694e',
          },
        }
      )

      const hm = comp?.atuacaoEmpresa
        ?.split('|')
        .filter((app: string) => app.length !== 0) || ['']

      const kk = data.data.map((atividade: Atividade) => {
        const i = hm.some((a) => atividade.codigo === a)

        if (i) {
          return {
            ...atividade,
            indGravado: 'S',
          }
        }
        return atividade
      })

      setAtividades(kk)
    } catch (error) {
      console.log(error)
    }
  }, [comp?.atuacaoEmpresa])

  const attComplementosUnique: SubmitHandler<Props> = async ({
    complemento,
  }) => {
    try {
      const newData: Complementos = {
        ...comp,
        cnpjCpfEmpresa: unFormatting(session.company.document),
        fantasia: complemento.fantasia,
        descricaoCnae: '',
        descricaoCnae2: '',
        descricaoCnae3: '',
        descricaoCnae4: '',
        descricaoCnae5: '',
        outrasAtividades: '',
        insEstadual: '',
        insMunicipal: '',
      }

      await apiInstance(9000).post(
        `/workspace/Empresa/${session.company.document}`,
        { complemento: newData, atuacao: atividades },
        {
          headers: {
            GatewayLS: '2e44bb6339e6aacd8faeca8fd4e8694e',
          },
        }
      )
      toast({
        title: 'Dados atualizados com sucesso.',
        duration: 3000,
        isClosable: true, 
        status: 'success'
      })
      await getData()
    } catch (error) {
      console.log(error)
    }
  }

  const changeAtividade = (e: FormEvent<HTMLInputElement>) => {
    const { checked, name } = e.currentTarget
    const alter = atividades.map((atividade) => {
      if (atividade.codigo === name) {
        return {
          ...atividade,
          indGravado: checked ? 'S' : 'N',
        }
      }
      return atividade
    })

    setAtividades(alter)
  }

  useEffect(() => {
    getData()
  }, [getData])

  useEffect(() => {
    getAtividades()
  }, [getAtividades])

  return (
    <Fade in={true} style={{ height: '100%' }}>
      {isFetching ? (
        <Center h="full">
          <Spinner
            size="xl"
            color="primary.pure"
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
          />
        </Center>
      ) : (
        <Flex
          h="full"
          flexDir="column"
          as="form"
          onSubmit={handleSubmit(attComplementosUnique)}
        >
          <Flex flexDir="column" gap={4} w="50%">
            <Text>Dados da empresa</Text>
            <HStack w="50%">
              <FormInput
                label="Documento"
                {...register('company.cnpjCpf')}
                isDisabled
              />
            </HStack>
            <VStack>
              <FormInput
                label="Razão social"
                {...register('company.razao')}
                isDisabled
              />
              <FormInput
                label="Nome fantasia"
                {...register('complemento.fantasia')}
              />
            </VStack>
          </Flex>
          <Divider my="22px" borderColor="low.light" />
          <Flex flexDir="column" gap={4} mb="40px">
            <Box>
              <Text>Atuação do escritório</Text>
              <Text fontSize="14px" color="low.medium">
                Selecione as opções que forem compatíveis com área de atuação do
                escritório.
              </Text>
            </Box>

            <SimpleGrid columns={2} gap={3}>
              {atividades.map((atividade) => (
                <Checkbox
                  key={atividade.cadastroId}
                  defaultChecked={atividade.indGravado === 'S'}
                  name={atividade.codigo}
                  onChange={(e: FormEvent<HTMLInputElement>) =>
                    changeAtividade(e)
                  }
                >
                  <Text fontSize="sm">{atividade.descricao}</Text>
                </Checkbox>
              ))}
            </SimpleGrid>
          </Flex>
          <Flex
            justifyContent="end"
            position="fixed"
            right={{ sm: '9%', '2xl': '17%' }}
            top="90%"
          >
            <Button
              w="120px"
              colorScheme="green"
              isLoading={isSubmitting}
              type="submit"
            >
              Salvar
            </Button>
          </Flex>
        </Flex>
      )}
    </Fade>
  )
}
