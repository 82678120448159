import { Button, Flex, Text } from '@chakra-ui/react'
import { useState } from 'react'

const urlToDownload = require('../utils/files/planilha.xlsx')

const DownloadFile = () => {
  const [download, setDownload] = useState('')

  return (
    <>
      <Flex ml={'15%'}>
        <Button
          width="274px"
          height="52px"
          backgroundColor="transparent"
          border="1px solid #1155BB"
          fontFamily={'Inter'}
          fontWeight="700"
          transition={'all 0.2s ease-in-out'}
          _hover={{ backgroundColor: '#fff', border: '2px solid #1155BB' }}
          mr="11px"
          onClick={() => setDownload(urlToDownload)}
        >
          <Text color="#1155BB">Download da planilha padrão</Text>
        </Button>

        {download && (
          <iframe
            src={download}
            style={{
              display: 'none',
            }}
          />
        )}
      </Flex>
    </>
  )
}

export default DownloadFile
