import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  ButtonGroup,
  Center,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useSession } from 'src/contexts'
import { apiInstance } from 'src/services/api'

import { ReactComponent as Notfind } from 'src/assets/imgs/not-find.svg'
import { DownloadSimple } from 'phosphor-react'
import { FileCard } from '../components/FileCard'

interface Company {
  razao: string
  cnpjCpf: string
}

interface SentProps {
  step: number
  visualization: 'card' | 'list'
  selectedCompany: Company
  hasGroup: boolean
}

interface File {
  id: number
  email: string
  name: string
  type: string
  initial_date: string
  originalDate: any
}

export const Sent = ({
  step,
  visualization,
  selectedCompany,
  hasGroup,
}: SentProps) => {
  const { session } = useSession()
  const [files, setFiles] = useState<File[]>([])
  const toast = useToast()
  const ref = useRef<HTMLInputElement>(null)
  const alertRef = useRef(null)
  const [fileAlert, setFileAlert] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [selectFile, setSelectFile] = useState({ file: '', name: '', type: '' })
  const [isFetching, setIsFetching] = useState(true)

  const selectUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files![0]
    e.target.value = ''

    let isValidFile: Boolean

    if (file?.size > 3000000) {
      toast({
        title: 'Tamanho excedido, valor máximo permitido 3MB!',
        duration: 3000,
        position: 'top-right',
        status: 'warning',
      })
      e.target.value = ''
      return
    }

    isValidFile = Boolean(
      ['xml', 'ret', 'plain', 'x-pkcs12', 'png', 'pdf'].filter((t) =>
        t.includes(file?.type.toLowerCase().split('/')[1])
      ).length ||
        file.name.toLowerCase().includes('rem') ||
        file.name.toLowerCase().includes('ret')
    )

    if (!isValidFile) {
      toast({
        title: 'Formato do arquivo selecionado é inválido',
        duration: 3000,
        position: 'top-right',
        status: 'warning',
      })
      e.target.value = ''
      return
    }

    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      setSelectFile({
        name: file.name,
        file: reader.result!.toString(),
        type: file.type,
      })
      setFileAlert(true)
    }
  }

  const uploadFile = async () => {
    try {
      setIsLoading(true)
      const i = selectFile.file.indexOf(',') + 1

      const payload = {
        cnpj: selectedCompany.cnpjCpf,
        login: session.email,
        nome: selectFile.name,
        value: selectFile.file.slice(i),
        extensao: selectFile.type,
      }

      await apiInstance(8010).post(`/Arquivos`, payload, {
        headers: {
          companyId: session.company.id.toString(),
          authorization: `bearer ${session.token}`,
        },
      })

      toast({
        title: 'Upload completo!',
        description: `O arquivo ${payload.nome} foi enviado com sucesso!`,
        position: 'top',
        duration: 3000,
        status: 'success',
        isClosable: true,
      })

      setFileAlert(false)
      await getSentFiles()
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const downloadFile = async (id: number) => {
    try {
      const { data } = await apiInstance(8010).get(
        `/Arquivos/Content/${id}`,
        {
          headers: {
            companyId: session.company.id.toString(),
            authorization: `bearer ${session.token}`,
          },
        }
      )
      const isB64 = data.value.includes('base64')
        ? data.value
        : `data:${data.extensao};base64,${data.value}`

      const downloadLink = document.createElement('a')
      downloadLink.href = isB64
      downloadLink.download = data.nome
      downloadLink.click()
    } catch (error) {
      console.log(error)
    }
  }

  const getSentFiles = useCallback(async () => {
    try {
      setIsFetching(true)
      const { data } = await apiInstance(8010).get<any[]>(
        `/Arquivos/${selectedCompany.cnpjCpf}`,
        {
          headers: {
            companyId: session.company.id.toString(),
            authorization: `bearer ${session.token}`,
          },
        }
      )
      console.log(data)
      const formatedfiles = data
        // .filter((file: { login: string }) => file.login === session.email)
        .map((file: any) => {
          let extension = ''
          switch (file.extensao.toLowerCase()) {
            case 'application/doc':
              extension = 'doc'
              break
            case 'application/pdf':
              extension = 'pdf'
              break
            case 'text/plain':
              extension = 'txt'
              break
            case 'application/excel':
              extension = 'xls'
              break
            case 'application/vnd.ms-excel':
              extension = 'xls'
              break
            case 'text/xml':
              extension = 'xml'
              break
            case 'application/x-pkcs12':
              extension = 'pfx'
              break
            case 'image/jpeg':
              extension = 'jpeg'
              break
            case 'image/jpg':
              extension = 'jpg'
              break
            case 'image/png':
              extension = 'png'
              break
            default:
              extension = 'outros'
              break
          }

          if (file.nome.toLowerCase().includes('ret')) {
            extension = 'ret'
          }
          if (file.nome.toLowerCase().includes('rem')) {
            extension = 'rem'
          }

          const initialD = dayjs(new Date(file.dataInserido)).format(
            'DD/MM/YYYY HH:mm:ss'
          )

          return {
            id: file.id,
            email: file.login,
            name: file.nome,
            type: extension,
            initial_date: initialD,
            originalDate: file.dataInserido,
          }
        })
        .sort((a, b) => {
          const da = +new Date(a.originalDate)
          const db = +new Date(b.originalDate)

          return db - da
        })

      await new Promise((resolve) => setTimeout(resolve, 1000))

      setFiles(() => formatedfiles)
    } catch (error) {
      console.log(error)
    } finally {
      setIsFetching(false)
    }
  }, [selectedCompany.cnpjCpf, session.company.id, session.token])

  useEffect(() => {
    if (step === 1) getSentFiles()
  }, [getSentFiles, step])

  return (
    <>
      <Flex w="full" h="full" flexDir="column">
        <Flex px="4" align="center">
          <input
            ref={ref}
            type="file"
            id="file"
            style={{ display: 'none' }}
            onChange={selectUploadFile}
          />
          <Button onClick={() => ref.current?.click()} colorScheme="blue">
            Carregar arquivo
          </Button>
          <Flex p="5" alignItems="center" mb={1}>
            <span
              style={{
                height: '8px',
                width: '8px',
                borderRadius: '50%',
                backgroundColor: '#1155BB',
                marginRight: '12px',
              }}
            />
            <Text color="low.medium">
              Formatos suportado: .xml; .ret; .txt; .pfx; .png; .pdf;
            </Text>
          </Flex>
        </Flex>

        {isFetching ? (
          <Center h="full" w="full">
            <Spinner
              size="xl"
              color="primary.pure"
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
            />
          </Center>
        ) : !isFetching && files?.length === 0 ? (
          <Center h="full" flexDir="column">
            <Notfind />
            <Text fontSize="20px" fontWeight="bold" color="low.light" mt="18px">
              Nenhum resultado encontrado.
            </Text>
          </Center>
        ) : (
          <>
            {visualization === 'list' && (
              <Box mt="12px" overflowY="auto">
                <Table size="sm">
                  <Thead position="sticky" top="0" bg="high.pure">
                    <Tr h="40px">
                      <Th
                        fontSize="sm"
                        fontWeight="400"
                        color="low.light"
                        borderWidth="0px"
                        w="100px"
                      >
                        Formato
                      </Th>
                      <Th
                        fontSize="sm"
                        fontWeight="400"
                        color="low.light"
                        borderWidth="0px"
                        w="200px"
                      >
                        Gerado em:
                      </Th>

                      <Th
                        fontSize="sm"
                        fontWeight="400"
                        color="low.light"
                        borderWidth="0px"
                      >
                        Nome do arquivo
                      </Th>
                      <Th
                        fontSize="sm"
                        fontWeight="400"
                        color="low.light"
                        borderWidth="0px"
                        w="60px"
                      />
                    </Tr>
                  </Thead>

                  <Tbody>
                    {files?.map((file) => (
                      <Tr key={file.id}>
                        <Td fontWeight="bold" textTransform="uppercase">
                          .{file.type}
                        </Td>
                        <Td>{file.initial_date}</Td>
                        <Td wordBreak="break-all">{file.name}</Td>
                        <Td py="4px">
                          <IconButton
                            size="sm"
                            variant="ghost"
                            aria-label="visualization"
                            icon={<DownloadSimple size={22} />}
                            position="initial"
                            onClick={() => downloadFile(file.id)}
                          />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            )}
            {visualization === 'card' && (
              <Box overflowY="auto" mt="12px">
                <Grid
                  templateColumns="repeat(auto-fill, minmax(276px, auto))"
                  rowGap="12px"
                  columnGap="24px"
                  padding="6px 10px 10px 6px"
                >
                  {files?.map((file) => (
                    <GridItem key={file.id}>
                      <FileCard
                        file={file}
                        actions={
                          <ButtonGroup size="sm" variant="ghost">
                            <IconButton
                              aria-label="visualization"
                              icon={<DownloadSimple size={22} />}
                              position="initial"
                              onClick={() => downloadFile(file.id)}
                            />
                          </ButtonGroup>
                        }
                      />
                    </GridItem>
                  ))}
                </Grid>
              </Box>
            )}
          </>
        )}
      </Flex>

      {/* UploadFile */}
      <AlertDialog
        isOpen={fileAlert}
        leastDestructiveRef={alertRef}
        onClose={() => setFileAlert(false)}
        isCentered
        closeOnOverlayClick={false}
        closeOnEsc={false}
        preserveScrollBarGap
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Você deseja enviar o arquivo</AlertDialogHeader>
            <AlertDialogBody>{`${selectFile.name}”?`}</AlertDialogBody>
            <AlertDialogFooter>
              <Button
                minWidth="110px"
                variant="ghost"
                onClick={() => setFileAlert(false)}
                disabled={isLoading}
              >
                Cancelar
              </Button>
              <Button
                colorScheme="blue"
                ml={3}
                minWidth="110px"
                onClick={uploadFile}
                isLoading={isLoading}
              >
                Enviar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
