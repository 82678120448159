import { FormEvent, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Link,
  Text,
  useToast,
} from '@chakra-ui/react'
import { Question } from 'phosphor-react'

import { apiInstance } from 'src/services/api'
import { useSession } from 'src/contexts'
import { FormInput } from 'src/components/FormInput'
import {
  InputFormattingPattern,
  unFormatting,
} from 'src/utilities/formattingToPattern'
import { FormSelect } from 'src/components/FormSelect'
import { setupProfile } from 'src/constants/setupProfile'

interface User {
  name: string
  email: string
  phone: string
  password: string
  re_password?: string
  status?: string
  perfilId?: string
}

const schema = yup.object().shape({
  name: yup.string().required('Nome obrigatório'),
  email: yup.string().email('E-mail inválido').required('E-mail obrigatório'),
  password: yup
    .string()
    .required('Senha obrigatória')
    .min(8, 'No mínimo 8 caracteres'),
  re_password: yup
    .string()
    .oneOf([null, yup.ref('password')], 'As senhas precisão ser iguais'),
})

export const CreateUser = () => {
  const { session } = useSession()
  const { profiles, helpLink } = setupProfile({ origin: session.origin })
  const [term, setTerm] = useState(false)

  const navigate = useNavigate()
  const toast = useToast()

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<User>({
    resolver: yupResolver(schema),
    shouldFocusError: false,
  })

  const createUser: SubmitHandler<User> = async (values) => {
    try {
      delete values.re_password
      delete values.status
      const user = {
        ...values,
        email: values.email
          .toLowerCase()
          .replace(/[&/\\#,+()$~%'":*?<>{}!]/g, ''),
        companyCnpjCpf: session.company.document,
        firstOrigin: session.origin,
        perfilId: Number(values.perfilId),
        cpf: '',
        phone: unFormatting(values.phone),
        parentUserId: session.id,
        type: session.type,
        specific: session.specific,
      }

      await apiInstance(8006).post('/UserGestor', user, {
        headers: {
          authorization: 'Y8pUrDbc5zPgAwkLxWRbUrNkQATBmhYb',
          companyId: session?.company?.id.toString(),
          cnpjcpf: session?.company?.document,
        },
      })

      await apiInstance(8006).get(
        `User/NotifyUserValid/${session.company.id}`,
        {
          headers: {
            emailUser: user.email,
          },
        }
      )

      toast({
        title: 'Usuário criado!',
        description: 'Usuário criado com sucesso!',
        variant: 'solid',
        status: 'success',
        position: 'top',
      })
      navigate('/users')
    } catch (err: any) {
      if (err.response?.data === 'User not save. Verify e-mail used') {
        toast({
          title: 'Falha ao cadastrar!',
          description: 'E-mail já utilizado',
          status: 'error',
          isClosable: true,
          position: 'top',
        })
        return
      }
      toast({
        title: 'Falha ao cadastrar!',
        description:
          'Não foi possível cadastrar o novo usuário. Tente novamente mais tarde',
        status: 'error',
        isClosable: true,
        position: 'top',
      })
    }
  }

  return (
    <>
      <Helmet>
        <title>Cadastro de Usuário | Workspace</title>
      </Helmet>
      <Flex
        as="form"
        onSubmit={handleSubmit(createUser)}
        w="full"
        bg="high.pure"
        borderRadius="4px"
        p="5"
        boxShadow={
          '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
        }
        gap={10}
      >
        <Box w="full">
          <Text lineHeight="20px" mb="8px" color="primary.pure">
            Dados do usuário
          </Text>
          <Text fontSize="sm">
            Tenha independência e pague somente por usuários ativos.
          </Text>
          <Flex flexDirection="column" gap={8} mt="24px">
            <FormInput
              {...register('name')}
              error={errors.name}
              label="Nome"
              placeholder="john doe"
              isRequired
            />
            <FormInput
              {...register('email')}
              error={errors.email}
              label="E-mail"
              placeholder="mail@provider.com"
              isRequired
            />

            <FormInput
              {...register('phone')}
              label="Telefone"
              name="phone"
              placeholder="(00) 0 0000-0000"
              control={{ w: '50%' }}
              onInput={(e) =>
                InputFormattingPattern({ event: e, pattern: 'phone' })
              }
            />

            <Flex gap="4">
              <FormSelect
                defaultValue="1"
                label="Status"
                {...register('status')}
                isDisabled
              >
                <option value="1">Ativo</option>
                <option value="0">Inativo</option>
              </FormSelect>

              <FormSelect
                label="Perfil"
                {...register('perfilId')}
                defaultValue="2"
                hus={
                  <Link href={helpLink} target="_blank" ml="8px">
                    <Question size={18} />
                  </Link>
                }
              >
                {profiles.map((prof) => (
                  <option key={prof.id} value={prof.id}>
                    {prof.name}
                  </option>
                ))}
              </FormSelect>
            </Flex>
          </Flex>
        </Box>
        <Box w="full">
          <Text lineHeight="20px" mb="8px" color="primary.pure">
            Senha do usuário
          </Text>
          <Text fontSize="sm">No mínimo 8 caracteres</Text>

          <Flex flexDirection="column" gap={8} mt="24px">
            <Flex gap="4">
              <FormInput
                {...register('password')}
                error={errors.password}
                label="Senha"
                placeholder="*********"
                type="password"
                isRequired
              />
              <FormInput
                {...register('re_password')}
                error={errors.re_password}
                label="Repetir Senha"
                placeholder="*********"
                type="password"
                isRequired
              />
            </Flex>
          </Flex>

          <Text lineHeight="20px" mt="38px" mb="8px" color="primary.pure">
            Termos
          </Text>
          <Checkbox
            size="lg"
            isChecked={term}
            onChange={(e: FormEvent<HTMLInputElement>) =>
              setTerm(e.currentTarget.checked)
            }
          >
            <Text fontSize="14px" marginLeft="12px">
              Estou ciente que a contratação de novos usuários gera cobrança
              adicional.
            </Text>
          </Checkbox>

          <Divider my="22px" borderColor="low.light" />

          <Flex gap={10} justify="end">
            <Button minW="156px" onClick={() => navigate(-1)}>
              Cancelar
            </Button>
            <Button
              type="submit"
              minW="156px"
              colorScheme="green"
              isDisabled={!term}
              isLoading={isSubmitting}
            >
              Cadastrar
            </Button>
          </Flex>
        </Box>
      </Flex>
    </>
  )
}

{
  /* <Flex
width={'100%'}
padding={{ base: '5px', sm: '24px' }}
flexDirection={'column'}
overflowY={'auto'}
>
<Stack direction="column" spacing={2}>
  <Heading color="primary.pure" fontSize="20px">
    Quer cadastrar novos usuários?
  </Heading>
  <Text fontSize="14px" color="low.dark" fontWeight="600">
    Tenha independência e pague somente <br /> por usuários ativos.
  </Text>
</Stack>
<Flex
  marginTop={'30px'}
  as="form"
  onSubmit={handleSubmit(handleCreateUser)}
  flexDirection={{ base: 'column', lg: 'row' }}
  paddingBottom={'30px'}
>
  <SimpleGrid
    minChildWidth={{ base: 'auto', md: '370px' }}
    columns={1}
    spacingX="40px"
    spacingY="20px"
  >
    <Input
      {...register('name')}
      label="Nome *"
      placeholder="John Doe"
      error={errors.name}
    />
    <Input
      {...register('email')}
      label="E-mail *"
      placeholder="Johndoe@exemplo.com"
      error={errors.email}
    />
    <Input
      {...register('phone')}
      label="Telefone"
      placeholder="(00) 0 0000-0000"
      width="50%"
      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
        inputPhoneMask(e)
      }
    />
    <SimpleGrid columns={2} spacingX="10px" position={'relative'}>
      <Link
        href={userCreationHelpLink}
        target="_blank"
        position="absolute"
        right="32%"
        top="2px"
        zIndex="10"
      >
        <BsQuestionCircle color="#1155BB" />
      </Link>
      <Select {...register('status')} label="Status" isDisabled>
        <option value={'1'}>Ativo</option>
        <option value={'0'}>Inativo</option>
      </Select>
      <Select {...register('perfilId')} label="Perfil">
        {roles.map((role) => (
          <option key={role.type} value={role.type}>
            {role.perfil}
          </option>
        ))}
      </Select>
    </SimpleGrid>
  </SimpleGrid>

  <Divider
    marginX="80px"
    opacity="1"
    borderColor="#bababa"
    orientation="vertical"
    display={{ base: 'none', lg: 'initial' }}
  />

  <SimpleGrid
    mt={'30px'}
    // minChildWidth="370px"
    maxWidth={'370px'}
    columns={1}
    spacingX="40px"
    spacingY="20px"
    alignSelf={'center'}
  >
    <Input
      {...register('password')}
      label="Senha *"
      placeholder="**********"
      type={'password'}
      error={errors.password}
    />
    <Input
      {...register('re_password')}
      label="Repita a Senha"
      placeholder="**********"
      type={'password'}
      error={errors.re_password}
    />
    <Flex justifyContent="space-between" alignItems="center">
      <Checkbox
        size="lg"
        onChange={(e) => setConfirm(e.target.checked)}
      />
      <Text fontSize="14px" marginLeft="16px">
        Estou ciente que a contratação de novos usuários gera cobrança
        adicional.
      </Text>
    </Flex>
    <Flex justifyContent="space-between">
      <Button
        variant="ghost"
        fontSize="14px"
        onClick={() => navigate(-1)}
        disabled={isSubmitting}
      >
        Cancelar
      </Button>
      <Button
        colorScheme="green"
        fontSize="14px"
        type="submit"
        disabled={!confirm || isSubmitting}
        isLoading={isSubmitting}
      >
        Cadastrar Usuário
      </Button>
    </Flex>
  </SimpleGrid>
</Flex>
</Flex> */
}
