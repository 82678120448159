import { Box, Button, Divider, Flex, Spacer, Tag, Text } from '@chakra-ui/react'
import { ReactComponent as AnalyticsProLogo } from '../../../assets/analytics-pro-logo.svg'
import { ActionButton } from './components/ActionButton'
import { Descricao } from './components/Descricao'
import { Funcionalidades } from './components/Funcionalidades'
import { Imagem } from './components/Imagem'
import { Title } from './components/Title'
import { UsuariosAtivosTable } from './components/UsuariosAtivosTable'
import { Video } from './components/Video'

import { useEffect, useState } from 'react'
import { ModalAddUser } from './components/ModalAddUser'
import { User } from 'src/screens/Users/hooks'
import { ModalTenhoInteresse } from './components/ModalTenhoInteresse'
import axios from 'axios'
import { useSession } from 'src/contexts'
import { UserRectangle } from 'phosphor-react'
import { useKeenSlider } from 'keen-slider/react'
import 'keen-slider/keen-slider.min.css'
import { getEmailHtmlPloomes } from 'src/utilities/getEmailHtml'
import { sendBase64ForEmails } from 'src/utilities/sendBase64ForEmails'
import { GetAppLogoByTag } from 'src/utilities/GetTagApp'
import { StoreAppProps } from 'src/utilities/storeApps'
import { apiInstance } from 'src/services/api'

export interface AppDetailsProps extends StoreAppProps {
  isAtivo: boolean
  isAdmin: boolean
  handleClickVoltar: () => void
}
const EMAILLEADPLOOMES = ['leadlondrisoft@gmail.com']

export const AppDetails = ({
  tag,
  appName,
  nivel,
  isAnalyticsPro,
  tituloPrincipal,
  tituloFuncionalidades,
  tituloMidias,
  descricao,
  listaFuncionalidades,
  hasMidias,
  urlVideo,
  urlImages,
  isAtivo,
  isAdmin,
  handleClickVoltar,
}: AppDetailsProps) => {
  const { session } = useSession()

  const [isOpenModalAddUser, setOpenModalAddUser] = useState<boolean>(false)
  const [reloadPage, setReloadPage] = useState<boolean>(false)
  const [isOpenModalTenhoInteresse, setOpenModalTenhoInteresse] =
    useState<boolean>(false)

  const [ref] = useKeenSlider<HTMLDivElement>({
    slides: {
      perView: 4,
      spacing: 1,
    },
  })

  const [usersAuth, setUsersAuth] = useState<User[]>([])

  useEffect(() => {
    getUsers()
  }, [reloadPage, isOpenModalAddUser])

  async function getUsers() {
    await apiInstance(8006)
      .get(`/UserGestor/UsersByCompany/${session.company.document}`)
      .then((res) => {
        getUserAuthByTag(res.data, tag)
      })
  }

  function getUserAuthByTag(users: User[], tag: string) {
    const usersAuthByTag: User[] = users
      .filter((user: any) => user.aplicativos && user.aplicativos.includes(tag))
      .map((user: any) => {
        const userAuthByTag: User = {
          userId: user.userId,
          name: user.name,
          email: user.email,
          status: user.status,
          perfilId: user.perfilId,
          aplicativos: user.aplicativos,
        }
        return userAuthByTag
      })
    setUsersAuth(usersAuthByTag)
  }

  const header = (
    <Flex
      width={'100%'}
      height={'80px'}
      alignItems={'center'}
      gap={5}
      paddingX={'20px'}
    >
      {isAnalyticsPro && (
        <Flex alignItems={'center'} gap={5}>
          <AnalyticsProLogo />
          <Divider orientation="vertical" height={'50px'} />
        </Flex>
      )}
      {GetAppLogoByTag({ appTag: tag })}
      <Spacer />

      <Flex flexDirection={'column'} gap={3}>
        <Button
          variant={'unstyled'}
          size={'xl'}
          marginTop={isAdmin ? '-38px' : '-25px'}
          marginRight={isAdmin ? '-12px' : '-35px'}
          onClick={handleClickVoltar}
        >
          <Text fontSize={'12px'} color={'blue'}>
            Voltar
          </Text>
        </Button>
        <Tag
          height={'20px'}
          variant="solid"
          colorScheme={isAtivo ? 'blue' : 'blackAlpha'}
          size={'sm'}
        >
          {isAtivo ? 'Ativo' : 'Inativo'}
        </Tag>
      </Flex>
    </Flex>
  )
  return (
    <Box width={'100%'} height={'100%'}>
      <Flex
        flexDirection={'column'}
        width={'100%'}
        height={'100%'}
        overflowX={'hidden'}
        p="12px"
        bg="high.pure"
        boxShadow={
          '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
        }
        borderRadius="4px"
      >
        {header}
        {isAtivo && isAdmin && nivel === 'user' ? (
          <Flex
            paddingX={'20px'}
            width={'100%'}
            flexDirection={'column'}
            gap={5}
          >
            <Flex>
              <Flex
                paddingX={'20px'}
                flexDirection={'column'}
                width={'50%'}
                gap={5}
              >
                <Title title={tituloPrincipal} />
                <Descricao descricao={descricao} />
              </Flex>
              <Flex
                paddingX={'20px'}
                flexDirection={'column'}
                width={'50%'}
                gap={5}
              >
                <Title title={tituloFuncionalidades} />
                <Funcionalidades listaFuncionalidades={listaFuncionalidades} />
              </Flex>
            </Flex>
            <ActionButton
              labelButton={'Adicionar usuário'}
              action={() => setOpenModalAddUser(true)}
            />
            <UsuariosAtivosTable
              app={{ appTag: tag, appName: appName }}
              users={usersAuth}
              reloadMainPage={() => setReloadPage((prevState) => !prevState)}
            />
          </Flex>
        ) : (
          <Flex>
            <Flex
              paddingX={'20px'}
              flexDirection={'column'}
              width={'50%'}
              gap={5}
            >
              <Title title={tituloPrincipal} />
              <Descricao descricao={descricao} />
              <Title title={tituloFuncionalidades} />
              <Funcionalidades listaFuncionalidades={listaFuncionalidades} />
              {nivel === 'user' && !isAtivo && (
                <ActionButton
                  labelButton={'Tenho interesse'}
                  action={() => {
                    const htmlPloomes = getEmailHtmlPloomes({
                      cnpj: session.company.document,
                      razao_social: session.company.name,
                      email: session.email,
                      telefone: session.company.phone,
                      app_interesse: appName,
                    })

                    sendBase64ForEmails({
                      emails: EMAILLEADPLOOMES,
                      htmlFile: htmlPloomes,
                    })
                    setOpenModalTenhoInteresse(true)
                  }}
                />
              )}
              {nivel === 'company' && tag === 'MercadoLivre' && (
                <ActionButton
                  labelButton={'Tenho interesse'}
                  action={() => {
                    window.open(
                      'https://docs.google.com/forms/d/1xDnM107RQfOR5fZSAsjhLTT6tLKrRuWL5oxUmvzEM2k/edit'
                    )
                    return
                  }}
                />
              )}
            </Flex>
            {hasMidias && (
              <Flex
                paddingX={'20px'}
                flexDirection={'column'}
                width={'50%'}
                gap={3}
              >
                <Title title={tituloMidias} />
                <Video title={appName} urlEmbed={urlVideo} />
                <div ref={ref} className="keen-slider">
                  {urlImages.map((urlImage, index) => {
                    return (
                      <div
                        className={`keen-slider__slide number-slide${index}`}
                        key={index}
                      >
                        <Imagem title={appName} urlEmbed={urlImage} />
                      </div>
                    )
                  })}
                </div>
              </Flex>
            )}
          </Flex>
        )}
      </Flex>
      <ModalAddUser
        app={{ appTag: tag, appName: appName }}
        usersAuth={usersAuth}
        isOpen={isOpenModalAddUser}
        onOpen={() => setOpenModalAddUser(true)}
        onClose={() => {
          setOpenModalAddUser(false)
          setReloadPage((prevState) => !prevState)
        }}
      />
      <ModalTenhoInteresse
        isOpen={isOpenModalTenhoInteresse}
        onOpen={() => setOpenModalTenhoInteresse(true)}
        onClose={() => setOpenModalTenhoInteresse(false)}
        app_interesse={{ appTag: tag, appName: appName }}
      />
    </Box>
  )
}
