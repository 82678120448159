import { fildesGestor } from 'src/screens/Importador/utils/fildesGestor'

export const createNewTable = (
  key_lines_client: number[],
  table: string[],
  returnTable: { key_table: number; table_index: number }[]
): string[] => {
  const newTable: any[] = []
  const newTableFilter = table.map((item: any) =>
    fildesGestor.map((items: any, i: number) =>
      key_lines_client.includes(i)
        ? returnTable.map((r, indice) => {
            return i === r.key_table ? item[r.table_index] : ''
          })
        : ''
    )
  )

  for (const itemTableFilter in newTableFilter) {
    const array: any[] = []
    newTableFilter[itemTableFilter].map((item: any) => {
      if (typeof item === 'object') {
        const itemClear = item.filter((t: any, d: any) => {
          return t !== ''
        })
        array.push(itemClear[0] || '')
      } else {
        array.push('')
      }
    })
    newTable.push(array)
  }

  return newTable
}
