import { Tag, TagProps } from '@chakra-ui/react'
import { useSession } from '../../contexts/sessions'
import { setupProfile } from 'src/constants/setupProfile'

interface ProfileTagProps extends TagProps {
  profileId: number
}

export const ProfileTag = ({ profileId, ...rest }: ProfileTagProps) => {
  const { session } = useSession()

  const { profile } = setupProfile({
    profileId,
    origin: session.origin,
  })

  return (
    <Tag
      borderRadius="4px"
      size="md"
      bg={profile.bgColor}
      color={profile.textColor}
      fontSize="12px"
      {...rest}
    >
      {profile.name}
    </Tag>
  )
}
