import { useCallback, useEffect, useState } from 'react'
import { Flex, Text } from '@chakra-ui/react'

import gestorbg from 'src/assets/login/home_gestor.jpg'
import aizubg from 'src/assets/login/home_aizu.jpg'
import cdbg from 'src/assets/login/home_cd.jpg'
import uniquebg from 'src/assets/login/home_unique.jpg'
import { ReactComponent as Gestor } from 'src/assets/login/gestor.svg'
import { ReactComponent as Unique } from 'src/assets/login/unique.svg'
import { ReactComponent as Aizu } from 'src/assets/login/aizu.svg'
import { ReactComponent as Cert } from 'src/assets/login/cert.svg'

interface Prod {
  bg: string
  label: string
  icon: string
}

export const Slider = () => {
  const [prod] = useState<Prod[]>([
    {
      bg: gestorbg,
      label: `Otimize sua empresa e tenha todo o processo  de vendas ajustado em um só sistema.`,
      icon: '<Gest01 />',
    },
    {
      bg: uniquebg,
      label: `Um sistema feito exclusivamente para atender 
    o trabalho do contador com simplicidade e agilidade.`,
      icon: '<Uni01 />',
    },
    {
      bg: aizubg,
      label: `Solução financeira descomplicada para  
    otimizar os pagamentos da sua empresa.`,
      icon: '<Aizu01 />',
    },
    {
      bg: cdbg,
      label: `A Londrisoft é revendedora de certificados digitais 
    autorizada pela Serasa Experian! 
    Essa solução ágil é imprescindível para a emissão de notas fiscais.`,
      icon: '<Cert01 />',
    },
  ])
  const [index, setIndex] = useState(0)

  useEffect(() => {
    const timer = setTimeout(() => {
      if (index === 3) setIndex(() => 0)
      if (index !== 3) setIndex(() => index + 1)
    }, 5000)

    return () => clearTimeout(timer)
  }, [index])

  return (
    <>
      <img src={aizubg} alt="" style={{ display: 'none' }} />
      <img src={cdbg} alt="" style={{ display: 'none' }} />
      <img src={uniquebg} alt="" style={{ display: 'none' }} />
      <Flex
        display={{ base: 'none', lg: 'flex' }}
        transition={'background .3s linear'}
        backgroundImage={prod[index].bg}
        backgroundPosition={'center'}
        backgroundRepeat={'no-repeat'}
        backgroundSize={'cover'}
        width={'100%'}
        position={'relative'}
      >
        <Flex
          position={'absolute'}
          // height={'150px'}
          top={'50%'}
          left={'50%'}
          transform={'translateX(-50%)'}
          alignItems={'center'}
          justifyContent={'space-between'}
          flexDirection={'column'}
        >
          {index === 0 && <Gestor />}
          {index === 1 && <Unique />}
          {index === 2 && <Aizu />}
          {index === 3 && <Cert />}
          <Text
            minWidth={'500px'}
            color={'high.pure'}
            mt={'40px'}
            fontSize={'16px'}
            fontWeight={'300'}
            textAlign={'center'}
          >
            {prod[index].label}
          </Text>
        </Flex>
      </Flex>
    </>
  )
}
