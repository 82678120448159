import { useCallback, useEffect, useState } from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import Chart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'

import { apiInstance } from 'src/services/api'
import { useSession } from 'src/contexts'
import { currency } from 'src/utilities/currency'

type Props = {
  step: number
  sellerId: number
  period: {
    initial: string
    final: string
  }
  analyticsToken: string | null
}

interface SalesPerMonthProps {
  total: number
  categories: string[]
  series: {
    name: string
    data: number[]
  }[]
}

export const SalesPerMonth = ({
  step,
  sellerId,
  period,
  analyticsToken,
}: Props) => {
  const { session } = useSession()

  const [salesPerMonth, setSalesPerMonth] = useState<SalesPerMonthProps>(
    {} as SalesPerMonthProps
  )

  const getSalesPerMonth = useCallback(async () => {
    try {
      const { data } = await apiInstance(9000).get(
        `/relatorios/TotalMes/?cnpjcpf=${session.company.document}&indvendedor=${sellerId}&dataini=${period.initial}&datafim=${period.final}`,
        {
          headers: {
            authorization: `bearer ${analyticsToken}`,
            GatewayLS: '2e44bb6339e6aacd8faeca8fd4e8694e',
          },
        }
      )

      setSalesPerMonth({
        total: data.registros,
        categories: data.total.map((m: any) => m.descricao.slice(0, 3)),
        series: [
          {
            name: 'Vendas',
            data: data.total.map((m: any) => m.total),
          },
        ],
      })
    } catch (error) {
      console.log(error)
    }
  }, [
    analyticsToken,
    period.final,
    period.initial,
    sellerId,
    session.company.document,
  ])

  useEffect(() => {
    if (step === 0 && analyticsToken) {
      getSalesPerMonth()
    }
  }, [analyticsToken, getSalesPerMonth, step])

  const options = {
    xaxis: {
      type: 'category',
      categories: salesPerMonth?.categories,
    },
    yaxis: {
      labels: {
        formatter: (value: any) =>
          new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(value),
        style: {
          fontSize: '11px',
          fontFamily: 'Sora, sans-serif',
          fontWeight: 400,
        },
      },
    },
    plotOptions: {
      bar: {
        columnWidth: salesPerMonth?.categories?.length < 3 ? '10%' : '40%',
        dataLabels: {
          position: 'top',
        },
      },
    },
    fill: {
      colors: ['#166DD7'],
      opacity: 1,
    },
    tooltip: {
      enabled: false,
    },
    states: {
      active: {
        filter: {
          type: 'none',
        },
      },
      hover: {
        filter: {
          type: 'none',
        },
      },
    },
    dataLabels: {
      formatter: (value: any) =>
        new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(value),
      offsetY: -20,
      style: {
        fontSize: '11px',
        fontWeight: '600',
        fontFamily: 'Sora, sans-serif',
        colors: ['#166DD7'],
      },
    },
    grid: {
      borderColor: '#f0f0f0',
    },
    chart: {
      toolbar: {
        show: false,
      },
      animations: {
        speed: 1,
        easing: 'linear',
      },
    },
  } as ApexOptions

  return (
    <Flex
      flexDir={'column'}
      boxShadow="0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)"
      borderRadius={'4px'}
      // width="calc(100vw - 252px)"
      m={'0 auto'}
      // h="calc(100vh - 260px)"
      padding={'12px'}
    >
      <Box
        width={'100%'}
        height={'68px'}
        border={'1px solid #f0f0f0'}
        borderRadius={'4px'}
        padding={'8px 16px'}
        textAlign={'center'}
        marginBottom={'16px'}
      >
        <Text fontSize={'12px'} color={'low.light'}>
          Total
        </Text>
        <Text
          fontSize={'14px'}
          color={'low.medium'}
          mt={'10px'}
          fontWeight={'600'}
        >
          {currency(salesPerMonth?.total || 0)}
        </Text>
      </Box>

      <Box w="full">
        {!!salesPerMonth?.total ? (
          <Chart
            height={350}
            type={'bar'}
            series={salesPerMonth?.series}
            options={options}
          />
        ) : (
          <Flex
            height={'100%'}
            minH={350}
            width={'full'}
            alignItems="center"
            justifyContent={'center'}
          >
            <Text fontSize={'14px'} color={'low.medium'}>
              Sem vendas
            </Text>
          </Flex>
        )}
      </Box>
    </Flex>
  )
}
