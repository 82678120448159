import { useRef } from 'react'
import {
  Button,
  AlertDialogOverlay,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialog,
} from '@chakra-ui/react'
import { useSession } from 'src/contexts/sessions'

interface ModalCancelProps {
  isOpen: boolean
  action: (value: boolean) => void
}

export const ModalLogout = ({ isOpen, action }: ModalCancelProps) => {
  const cancelRef = useRef(null)

  const { handleLogout } = useSession()

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={() => action(false)}
      isCentered
      motionPreset="slideInBottom"
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Sair
          </AlertDialogHeader>

          <AlertDialogBody>Você tem certeza que quer sair?</AlertDialogBody>

          <AlertDialogFooter>
            <Button
              onClick={() => action(false)}
              variant="ghost"
              fontSize="14px"
            >
              Cancelar
            </Button>
            <Button
              ref={cancelRef}
              colorScheme="red"
              onClick={() => handleLogout()}
              ml={3}
              fontSize="14px"
            >
              Quero sair
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
