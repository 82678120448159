import { createContext, useCallback, useContext, useEffect } from 'react'
import { apiInstance } from 'src/services/api'
import { useSession } from '../sessions'

type Props = {
  children: React.ReactNode
}

const ExtraContext = createContext({})

export function ExtraProvider({ children }: Props) {
  const { session } = useSession()

  const getCertificate = useCallback(async () => {
    try {
      const {
        data: { dados },
      } = await apiInstance(9000).get(
        `/workspace/Certificado/Vencimento/${session.company.document}`,
        {
          headers: {
            GatewayLS: '2e44bb6339e6aacd8faeca8fd4e8694e',
          },
        }
      )

      // setCertificate(new Date(data.dados.vencimento).toLocaleDateString())
      // setExpiry(dayjs(data.dados.vencimento).diff(dayjs(), 'days'))

      console.log('provider', dados)
    } catch (error) {
      console.log(error)
    }
  }, [session?.company?.document])

  useEffect(() => {
    if (session.token.length !== 0) {
      getCertificate()
    }
  }, [getCertificate, session])

  return (
    <ExtraContext.Provider value={{}}>
      <>{children}</>
    </ExtraContext.Provider>
  )
}

export const useExtra = () => useContext(ExtraContext)
