import { FormEvent, useEffect, useState } from 'react'
import {
  Box,
  Flex,
  Button,
  Checkbox,
  Divider,
  Link,
  Text,
  useToast,
  Spacer,
} from '@chakra-ui/react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'

import { useForm, SubmitHandler } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { useSession } from 'src/contexts'
import { apiInstance } from 'src/services/api'
import { FormInput } from 'src/components/FormInput'
import {
  formatting,
  InputFormattingPattern,
} from 'src/utilities/formattingToPattern'
import { FormSelect } from 'src/components/FormSelect'
import { Question } from 'phosphor-react'
import { setupProfile } from 'src/constants/setupProfile'
import { User } from '../hooks'
import { ListaAppUser } from './components/ListaAppsUser'

interface UserEdit extends User {
  phone: string
  password?: string
}

const schema = yup.object().shape({
  name: yup.string().required('Nome obrigatório'),
  email: yup.string().email('E-mail inválido').required('E-mail obrigatório'),
  password: yup
    .string()
    .required('Senha obrigatória(mínimo 8 caracteres)')
    .min(8, 'No mínimo 8 caracteres'),
  re_password: yup
    .string()
    .oneOf([null, yup.ref('password')], 'As senhas precisão ser iguais'),
})
const schemaNoPassword = yup.object().shape({
  name: yup.string().required('Nome obrigatório'),
  email: yup.string().email('E-mail inválido').required('E-mail obrigatório'),
})

export const EditingUser = () => {
  const toast = useToast()
  const { session } = useSession()
  const navigate = useNavigate()
  const [user, setUser] = useState<UserEdit>()
  const [isChangePassword, setIsChangePassword] = useState(false)

  const isNotAdmin = session.perfil !== 1
  const isSameUser = session.id === user?.userId

  const { profiles, helpLink } = setupProfile({ origin: session.origin })

  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { isSubmitting, errors },
    setValue,
  } = useForm({
    resolver: yupResolver(isChangePassword ? schema : schemaNoPassword),
    shouldFocusError: false,
  })

  const editUser: SubmitHandler<UserEdit> = async (values) => {
    try {
      const changedUser = {
        ...user,
        ...(isChangePassword && { password: values.password }),
        name: values.name,
        phone: values.phone.replace(/\D/g, '') || '',
        status: !!Number(values.status),
        perfilId: Number(values.perfilId),
        companyCNPJCPF: session.company.document,
        specific: true,
      }

      await apiInstance(8006).put('UserGestor', changedUser, {
        headers: {
          authorization: 'Y8pUrDbc5zPgAwkLxWRbUrNkQATBmhYb',
          companyId: session?.company?.id.toString(),
          cnpjcpf: session?.company?.document,
        },
      })

      toast({
        title: 'Usuário Editado!',
        status: 'success',
        duration: 2000,
        isClosable: true,
        position: 'top',
      })

      navigate(-1)
    } catch (error: any) {
      toast({
        title: 'Falha ao editar usuário!',
        description: 'Não foi possível editar o usuário. Tente novamente!',
        status: 'error',
        isClosable: true,
        position: 'top',
      })
    }
  }

  const confirmToSwitchPassword = (event: FormEvent<HTMLInputElement>) => {
    const { checked } = event.currentTarget
    setIsChangePassword(checked)
    setValue('password', '')
    setValue('re_password', '')
    clearErrors('re_password')
    clearErrors('password')
  }

  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem('ws@user') || '{}')

    if (Object.keys(obj).length === 0) navigate(-1)

    setUser(obj)

    setValue('name', obj.name)
    setValue('perfilId', obj.perfilId)
    setValue('email', obj.email)
    setValue(
      'phone',
      formatting({ value: obj.phone || '', pattern: 'phone' }) || ''
    )
    setValue('status', obj.status ? 1 : 0)
    localStorage.removeItem('ws@user')
    return () => localStorage.removeItem('ws@user')
  }, [navigate, setValue])

  return (
    <>
      <Helmet>
        <title>Edição de Usuário | Workspace</title>
      </Helmet>
      <Flex
        as="form"
        onSubmit={handleSubmit(editUser)}
        w="full"
        bg="high.pure"
        borderRadius="4px"
        p="5"
        boxShadow={
          '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
        }
        gap={10}
      >
        <Flex width={'100%'} gap={5} flexDirection={'column'}>
          <Flex gap={10} width={'100%'}>
            <Box w="full">
              <Text lineHeight="20px" mb="8px" color="primary.pure">
                Dados do usuário
              </Text>
              <Text fontSize="sm">
                Edite quantas vezes for necessário sem custo.
              </Text>
              <Flex flexDirection="column" gap={5} mt="24px">
                <FormInput
                  {...register('name')}
                  error={errors.name}
                  isRequired
                  label="Nome"
                  placeholder="john doe"
                />
                <FormInput
                  {...register('email')}
                  error={errors.email}
                  isDisabled
                  label="E-mail"
                  placeholder="mail@provider.com"
                />

                <FormInput
                  {...register('phone')}
                  label="Telefone"
                  name="phone"
                  placeholder="(00) 0 0000-0000"
                  w="50%"
                  onInput={(e) =>
                    InputFormattingPattern({ event: e, pattern: 'phone' })
                  }
                />

                <Flex gap={4}>
                  <FormSelect
                    defaultValue="1"
                    label="Status"
                    {...register('status')}
                    isDisabled={isSameUser || isNotAdmin}
                  >
                    <option value="1">Ativo</option>
                    <option value="0">Inativo</option>
                  </FormSelect>

                  <FormSelect
                    label="Perfil"
                    {...register('perfilId')}
                    defaultValue="2"
                    hus={
                      <Link href={helpLink} target="_blank" ml="8px">
                        <Question size={18} />
                      </Link>
                    }
                    isDisabled={isNotAdmin}
                  >
                    {profiles.map((prof) => (
                      <option key={prof.id} value={prof.id}>
                        {prof.name}
                      </option>
                    ))}
                  </FormSelect>
                </Flex>
              </Flex>
            </Box>
            <Box w="full">
              <Text lineHeight="20px" mb="8px" color="primary.pure">
                Senha do usuário
              </Text>
              <Text fontSize="sm">No mínimo 8 caracteres</Text>

              <Flex flexDirection="column" gap={5} mt="24px">
                <Checkbox
                  size="lg"
                  isChecked={isChangePassword}
                  onChange={confirmToSwitchPassword}
                >
                  <Text fontSize="14px" marginLeft="8px">
                    Deseja altera a senha?
                  </Text>
                </Checkbox>
                <Flex mt="-14px" gap="4">
                  <FormInput
                    {...register('password')}
                    error={errors.password}
                    label="Senha"
                    placeholder="*********"
                    type="password"
                    isRequired={isChangePassword}
                    isDisabled={!isChangePassword}
                  />
                  <FormInput
                    {...register('re_password')}
                    error={errors.re_password}
                    label="Repetir Senha"
                    placeholder="*********"
                    type="password"
                    isRequired={isChangePassword}
                    isDisabled={!isChangePassword}
                  />
                </Flex>
              </Flex>

              <Divider my="22px" borderColor="low.light" />

              <Flex gap={10} justify="end">
                <Button minW="156px" onClick={() => navigate('/users')}>
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  minW="156px"
                  colorScheme="green"
                  isLoading={isSubmitting}
                >
                  Salvar
                </Button>
              </Flex>
            </Box>
          </Flex>
          <ListaAppUser appAtivos={user?.aplicativos?.split('|').filter((x) => x.length > 1)} />
        </Flex>
      </Flex>
    </>
  )
}
