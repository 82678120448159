import axios, { AxiosInstance } from 'axios'
import { ISavedCorrelation } from 'src/screens/Importador/containers/Pages/correlationPage/types/types'

const baseUrlEnv =
  window.location.origin.includes('localhost') ||
  window.location.origin.includes('alpha') ||
  window.location.origin.includes('192.168.0.221')
    ? process.env.REACT_APP_ALPHA_URL
    : process.env.REACT_APP_PROD_URL

export function apiInstance(port: number, url?: string): AxiosInstance {
  const baseURL = url ? `${url}` : `${baseUrlEnv}:${port}`

  return axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

function httpIstance() {
  const currentApi = localStorage.getItem('api')
  let selectApi = ''

  if (!currentApi) {
    localStorage.setItem('api', 'https://api.serverlondrisoft.com:8006')
    selectApi = 'https://api.serverlondrisoft.com:8006'
  } else if (currentApi === 'https://api.serverlondrisoft.com:8006') {
    localStorage.setItem('api', 'https://api1.serverlondrisoft.com:8006')
    selectApi = 'https://api.serverlondrisoft.com:8006'
  } else {
    localStorage.setItem('api', 'https://api.serverlondrisoft.com:8006')
    selectApi = 'https://api1.serverlondrisoft.com:8006'
  }

  return axios.create({
    baseURL: selectApi,
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

// 'https://api.serverlondrisoft.com:8006' // embratel

// 'https://api1.serverlondrisoft.com:8006' // vivo

// 'https://api2.serverlondrisoft.com:8006' // sercontel

// const REPORT_URL = process.env.REACT_APP_REPORT_URL

export async function getCompanyReportData(companyDoc: string) {
  const accessTokenNucleo = (await getAccessTokenNucleo().then(
    (res) => `Bearer ${res}`
  )) as string
  return apiInstance(9008)
    .get('/api/Empresa', {
      params: {
        cnpj: companyDoc,
      },
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `${accessTokenNucleo}`,
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      console.log(err)
    })
}
export const getAccessTokenBI = async (
  companyId: number,
  powerBiGroupId: string,
  powerBiReportId: string
) => {
  return await apiInstance(9017)
    .get(`/api/powerbi/${powerBiGroupId}/${powerBiReportId}/${companyId}`, {
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      console.log(err)
    })
}

export const getEcoCompanyGroup = async (cnpj: string) => {
  const data = await apiInstance(9000)
    .get(`workspace/Empresa/GrupoEconomico/${cnpj}`, {
      headers: {
        GatewayLS: '2e44bb6339e6aacd8faeca8fd4e8694e',
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err)
    })

  return data
}

// Inicio Importador ###############################################################

const getTokenAccessImportador = async (cnpj: string) => {
  const {
    data: { Token },
  } = await apiInstance(8006, 'https://api.serverlondrisoft.com:8006').get(
    `/Company/getTokenByCnpjCpf/${cnpj}`,
    {
      headers: {
        Authorization: 'Y8pUrDbc5zPgAwkLxWRbUrNkQATBmhYb',
      },
    }
  )

  return Token
}

export const getLastConfig = async (
  configurationId: string,
  companyId: string,
  userId: string
) => {
  const data = await apiInstance(8006)
    .get(`Configuration/${configurationId}`, {
      headers: {
        gatewayls: '2e44bb6339e6aacd8faeca8fd4e8694e',
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err)
    })

  return data
}

export const updateToInactivate = async (
  cnpj: string,
  configurationId: number,
  payload: any
) => {
  const tokenAccess = await getTokenAccessImportador(cnpj)

  const data = await apiInstance(8006, 'https://api.serverlondrisoft.com:8006')
    .put(`Configuration/${configurationId}`, payload, {
      headers: {
        Authorization: tokenAccess,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err)
    })

  return data
}

export const getCompanyConfiguration = async (
  cnpj: string,
  companyId: number
) => {
  const tokenAccess = await getTokenAccessImportador(cnpj)

  const data = await apiInstance(8006, 'https://api.serverlondrisoft.com:8006')
    .get(`/Configuration/companyId/${companyId}`, {
      headers: {
        GatewayLS: '2e44bb6339e6aacd8faeca8fd4e8694e',
        Authorization: tokenAccess,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err)
    })
  return data
}

export const postSavedOrderToGestor = async (json: any, cnpj: string) => {
  try {
    const tokenAccess = await getTokenAccessImportador(cnpj)
    await apiInstance(8006, 'https://api.serverlondrisoft.com:8006').post(
      `/OrderSync/list`,
      [json],
      {
        headers: {
          Authorization: tokenAccess,
        },
      }
    )
  } catch (err) {
    console.log(err)
  }
}

export const postSavedCorrelation = async (
  cnpj: string,
  payload: ISavedCorrelation
) => {
  const tokenAccess = await getTokenAccessImportador(cnpj)

  await apiInstance(8006, 'https://api.serverlondrisoft.com:8006').post(
    `/Configuration`,
    payload,
    {
      headers: {
        Authorization: tokenAccess,
      },
    }
  )
}

export const getOrderCorrelation = async (json: any) => {
  return await apiInstance(8006).post(`/Order`, json)
}

export const getOrdersSaved = async (cnpj: string) => {
  const tokenAccess = await getTokenAccessImportador(cnpj)
  const dateTomorrow = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate() + 1
  )
    .toLocaleDateString('ko-KR')
    .replaceAll('. ', '-')
    .replace('.', '')

  return await apiInstance(8006, 'https://api.serverlondrisoft.com:8006')
    .get(`Order/Import/?dateInitial=2023-03-15&dateEnd=${dateTomorrow}`, {
      headers: {
        Authorization: tokenAccess,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err)
    })
}

// End endpoints Importador ###############################################################

export const getAccessTokenNucleo = async () => {
  return await apiInstance(9008)
    .post('/api/login', {
      login: 'consumoapi@londrisoft.com.br',
      password: 'ConsApi@2022%',
    })
    .then((response) => {
      return response.data.accessToken
    })
    .catch((err) => {
      console.log(err)
    })
}

export async function getAplicativosByCompanyDocument(
  document: string,
  origin: string
) {
  const headers = {
    GatewayLS: '2e44bb6339e6aacd8faeca8fd4e8694e',
    Sistema: `${origin}`,
  }
  const { data } = await apiInstance(9000).get(
    `/workspace/Aplicativo/Empresa/${document}`,
    {
      headers: headers,
    }
  )
  return data
}

export async function getUserAppsByEmail(email: string) {
  return await apiInstance(8006)
    .get(`/User/Email/${email}`, {
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Y8pUrDbc5zPgAwkLxWRbUrNkQATBmhYb',
      },
    })
    .then((response) => {
      return response.data.aplicativos
    })
    .catch((err) => {
      console.log(err)
    })
}

interface UserAppProps {
  userId: number
  aplicativos: string
}
export async function updateUserAppTag(data: {
  userId: number
  userApps: string
  appTag: string
  action: 'create' | 'delete'
}) {
  const body = {} as UserAppProps

  body.userId = data.userId

  const allApps = data.userApps.concat(`|${data.appTag}`).split('|')
  let newUserApps = ''

  if (data.action === 'create') {
    allApps.forEach((app) => {
      if (!newUserApps.includes(app)) {
        newUserApps += `|${app}`
      }
    })
  }

  if (data.action === 'delete') {
    allApps.forEach((app) => {
      if (!newUserApps.includes(app) && app !== data.appTag) {
        newUserApps += `|${app}`
      }
    })
  }
  body.aplicativos = newUserApps

  try {
    const accessTokenNucleo = (await getAccessTokenNucleo().then(
      (res) => `Bearer ${res}`
    )) as string

    const headers = {
      'Content-Type': 'Application/Json',
      Authorization: `${accessTokenNucleo}`,
    }

    await apiInstance(9008).put(
      '/api/User/Aplicativos',
      {
        userId: body.userId,
        aplicativos: body.aplicativos,
      },
      {
        headers: headers,
      }
    )
  } catch (error) {
    console.log(error)
  }
}

export const getCharges = async (cnpj: string) => {
  const {
    company: { empresaId },
  } = await getCompanyReportData(cnpj)

  const {
    data: { receber },
  } = await apiInstance(9000).get(`workspace/Receber/${empresaId}`, {
    headers: {
      GatewayLS: '2e44bb6339e6aacd8faeca8fd4e8694e',
    },
  })

  return receber
}

export const getStatusAccountClient = async (cnpj: string) => {
  const {
    company: { empresaId },
  } = await getCompanyReportData(cnpj)

  const {
    data,
  } = await apiInstance(9000).get(`workspace/Receber/${empresaId}`, {
    headers: {
      GatewayLS: '2e44bb6339e6aacd8faeca8fd4e8694e',
    },
  })


  return data
}
