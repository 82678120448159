import { Text, Flex } from '@chakra-ui/react'
import pack from '../../../../package.json'

import { SideMenuButton } from './Components/SideMenuButton'
import { SideMenuSection } from './Components/SideMenuSection'
import { useSideMenuSection } from '../../../constants/hooks/useSections'

import { SideButtonCompany } from './Components/SideButtonCompany'
import { Can } from 'src/components/Can'
import { useSession } from 'src/contexts'

export const SideMenu = () => {
  const { SideMenuSections } = useSideMenuSection()
  const { session } = useSession()

  return (
    <Flex
      flexDir="column"
      borderRadius="4px"
      boxShadow={
        '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
      }
      backgroundColor="high.pure"
      p="10px 0"
      h="full"
      minW="220px"
      zIndex={2}
    >
      <SideButtonCompany />

      {SideMenuSections.map((section) => (
        <SideMenuSection key={section.name} title={section.name}>
          {section.items.map((item, index) => {
            return (
              <Can
                permissions={item.permissions}
                perfil={session.perfil}
                key={index}
              >
                <SideMenuButton
                  label={item.label}
                  icon={item.icon}
                  beta={item.beta}
                  onClick={item.action}
                />
              </Can>
            )
          })}
        </SideMenuSection>
      ))}

      <Text mt="auto" mx="auto" fontSize="11px" color="low.medium">
        Versão: {pack.version}
      </Text>
    </Flex>
  )
}
