import { forwardRef, ReactNode } from 'react'
import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  Select,
  SelectProps,
} from '@chakra-ui/react'
import { FieldError } from 'react-hook-form'

interface FormSelect extends SelectProps {
  label: string
  hus?: ReactNode
  control?: FormControlProps
  error?: FieldError
}

export const FormSelect = forwardRef<HTMLSelectElement, FormSelect>(
  (props, ref) => {
    const { label, name, hus, children, control, error } = props
    return (
      <FormControl id={name} {...control}>
        <FormLabel fontSize="xs" display="inline-flex" mb="3px">
          {label}
          {!!hus && hus}
        </FormLabel>
        <Select
          name={name}
          id={name}
          ref={ref}
          h="36px"
          w="inherit"
          bgColor="high.pure"
          borderRadius="4px"
          fontSize="sm"
          {...props}
        >
          {children}
        </Select>
        {!!error && (
          <FormErrorMessage mt="4px" fontSize="xs">
            {error.message}
          </FormErrorMessage>
        )}
      </FormControl>
    )
  }
)
