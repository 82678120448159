import {
  Modal,
  ModalOverlay,
} from '@chakra-ui/react'

interface ModalBodyProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  // lineSelectedToImported: statusNotes
  body: JSX.Element | undefined
  size: string
}

export const ModalBodyDefault = ({
  isOpen,
  onClose,
  body,
  size,
}: ModalBodyProps) => {

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={size}>
      <ModalOverlay />
      {body}
    </Modal>
  )
}
