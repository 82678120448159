import {
  Button,
  Checkbox,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useSession } from 'src/contexts'
import { User, useUsers } from 'src/screens/Users/hooks'
import { updateUserAppTag } from 'src/services/api'
import {
  getEmailAtivacaoUsuario,
  getEmailNotifyAdminAcessoLiberado,
} from 'src/utilities/getEmailHtml'
import { sendBase64ForEmails } from 'src/utilities/sendBase64ForEmails'
import { compareUser } from 'src/utilities/sortObjAux'

export interface ModalAddUserProps {
  app: { appTag: string; appName: string }
  usersAuth: User[]
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}
export const ModalAddUser = ({
  app,
  usersAuth,
  isOpen,
  onOpen,
  onClose,
}: ModalAddUserProps) => {
  const [acceptTermo, setAcceptTermo] = useState(false)
  const [user, setUser] = useState<User>({} as User)

  const { users } = useUsers()
  const { session } = useSession()

  const idsUsersAuth = usersAuth.map((u) => u.userId)

  function handleSetUser(userId: number) {
    const user = users?.find((u) => u.userId === userId) ?? ({} as User)
    setUser(() => user)
  }

  console.log(app)

  const appsFree = ['QUOD', 'EXCEL']

  async function handleClickAddUsuario() {
    try {
      updateUserAppTag({
        userId: user.userId,
        userApps: user.aplicativos ?? '',
        appTag: app.appTag,
        action: 'create',
      })

      const htmlFileAtivacaoUser = getEmailAtivacaoUsuario({
        user: user.name,
        app_name: app.appName,
      })

      sendBase64ForEmails({
        emails: [user.email],
        htmlFile: htmlFileAtivacaoUser,
      })
      const htmlFileNotifyAdmin = getEmailNotifyAdminAcessoLiberado({
        admin: session.name,
        user_name: user.name,
        app_name: app.appName,
        dateTime: new Date(),
      })
      sendBase64ForEmails({
        emails: [session.email],
        htmlFile: htmlFileNotifyAdmin,
      })
    } catch (error) {
      console.log(error)
    }
    onClose()
  }

  return (
    <Modal isCentered size={'2xl'} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text fontSize={'20px'} fontWeight={700}>
            Adicionar Usuário
          </Text>
        </ModalHeader>
        <ModalBody>
          <Flex flexDirection={'column'} gap={5}>
            <Flex flexDirection={'column'} gap={2}>
              <Text fontSize={'14px'}>
                Os usuários mencionados na lista abaixo estão cadastrados em:
              </Text>
              <Text fontSize={'14px'} fontWeight={600}>
                Usuários {'>'} Gerência de Usuário
              </Text>
              <Select
                fontSize={'14px'}
                placeholder="Selecionar usuário"
                onChange={(e) => handleSetUser(Number(e.target.value))}
              >
                {users
                  ?.filter(
                    (user) => !idsUsersAuth.some((u) => u === user.userId)
                  )
                  .sort(compareUser)
                  .map((user) => {
                    return (
                      <option key={user.userId} value={user.userId}>
                        {user.name} - {user.email}
                      </option>
                    )
                  })}
              </Select>
            </Flex>
            {!appsFree.includes(app.appTag) && (
              <Flex flexDirection={'column'} gap={2}>
                <Text fontSize={'14px'} fontWeight={600}>
                  Termo
                </Text>
                <Checkbox
                  size={'lg'}
                  checked={acceptTermo}
                  onChange={(e) => setAcceptTermo(e.target.checked)}
                >
                  <Text fontSize={'13px'}>
                    Estou ciente que a contratação de novos usuários gera
                    cobrança adicional.
                  </Text>
                </Checkbox>
              </Flex>
            )}
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blackAlpha" marginRight={3} onClick={onClose}>
            Cancelar
          </Button>
          <Button
            colorScheme="green"
            isDisabled={
              !user.userId || appsFree.includes(app.appTag)
                ? false
                : !acceptTermo
            }
            onClick={handleClickAddUsuario}
          >
            Adicionar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
