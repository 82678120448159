import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Image,
  AspectRatio,
  Flex,
  Center,
} from '@chakra-ui/react'

export interface ModalFullImageProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  title: string
  urlImage: string
}
export const ModalFullImage = ({
  isOpen,
  onOpen,
  onClose,
  title,
  urlImage,
}: ModalFullImageProps) => {
  return (
    <Modal size={'4xl'} isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Center>
            <AspectRatio width={'870px'} minWidth={'870px'} ratio={5 / 3}>
              <Image src={urlImage} alt={title} objectFit="cover" />
            </AspectRatio>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
