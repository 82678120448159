import { useToast } from '@chakra-ui/react'
import { useSession } from '../contexts/sessions'
import { apiInstance } from '../services/api'
import { encrypted } from './hashing'

export const useSystems = () => {
  const toast = useToast()
  let { session } = useSession()

  const data = JSON.stringify({
    id: session.id,
    name: session.name,
    email: session.email,
    origin: session.origin,
    company: {
      id: session.company.id,
      name: session.company.name,
      document: session.company.document,
      local: session.type === 0 ? true : false,
    },
  })
  const simplaz = encrypted(data)

  const openGestor = async () => {
    try {
      const { data: company } = await apiInstance(8006).get(
        `Company/${session.company.id}`,
        {
          headers: {
            Authorization: `Bearer ${session.token}`,
            companyId: session.company.id.toString(),
          },
        }
      )

      session = {
        ...session,
        company: {
          ...session.company,
          server: company.Nserver,
        },
      }

      const doc =
        session.company.document.length !== 14
          ? session.company.document.substring(4, 12)
          : session.company.document.substring(6, 14)

      let pass =
        (
          session.email.substring(0, 1).toUpperCase().replace(/[0-9]/g, '') +
          session.email.substring(1, 2).toLowerCase().replace(/[0-9]/g, '')
        ).length !== 2
          ? `Lo${doc}`
          : session.email.substring(0, 1).toUpperCase() +
            session.email.substring(1, 2).toLowerCase().replace(/[0-9]/g, '') +
            doc

      const { data } = await apiInstance(8012).get<any>(
        `/user/checkActivityDirectory/${session.email}`,
        {
          headers: {
            Authorization: session.token,
          },
        }
      )

      if (!data.status) {
        toast({
          title: 'Estamos revisando seu cadastro',
          status: 'warning',
          position: 'top',
          isClosable: true,
        })
        return
      }

      const userAd = data.userNameAD
      const params = data.param
      
        let baseUrl = process.env.REACT_APP_GESTOR_1

      if (session.company.server === 0) 
          {baseUrl = process.env.REACT_APP_GESTOR_0}
      else  
          if (session.company.server === 1)  
             {baseUrl = process.env.REACT_APP_GESTOR_1}
          else
             {baseUrl = process.env.REACT_APP_GESTOR_3}


      const url = `${baseUrl}?user=londrisoft\\\\${userAd}&pwd=${pass}&program=C:\\londrisoft\\Gestor_Prime\\appgestor.exe&startupdir=C:\\londrisoft\\Gestor_Prime\\&params='${params}'`

      // window.location.href = url

      // console.log(data)
      window.open(url)
    } catch (error: any) {
      toast({
        title: 'Falha ao abrir o sistema',
        description: `${error.message}`,
        status: 'warning',
        position: 'top',
        isClosable: true,
      })
    }
  }


  const openUnique = async () => {
    try {
      const { data: company } = await apiInstance(8006).get(
        `Company/${session.company.id}`,
        {
          headers: {
            Authorization: `Bearer ${session.token}`,
            companyId: session.company.id.toString(),
          },
        }
      )

      session = {
        ...session,
        company: {
          ...session.company,
          server: company.Nserver,
        },
      }

      const doc =
        session.company.document.length !== 14
          ? session.company.document.substring(4, 12)
          : session.company.document.substring(6, 14)

      let pass =
        (
          session.email.substring(0, 1).toUpperCase().replace(/[0-9]/g, '') +
          session.email.substring(1, 2).toLowerCase().replace(/[0-9]/g, '')
        ).length !== 2
          ? `Lo${doc}`
          : session.email.substring(0, 1).toUpperCase() +
            session.email.substring(1, 2).toLowerCase().replace(/[0-9]/g, '') +
            doc

      const { data } = await apiInstance(8012).get<any>(
        `/user/checkActivityDirectory/${session.email}`,
        {
          headers: {
            Authorization: session.token,
          },
        }
      )

      if (!data.status) {
        toast({
          title: 'Estamos revisando seu cadastro',
          status: 'warning',
          position: 'top',
          isClosable: true,
        })
        return
      }

      const userAd = data.userNameAD
      const params = data.param

      const baseUrl = process.env.REACT_APP_GESTOR_3

      const url = `${baseUrl}?user=londrisoft\\\\${userAd}&pwd=${pass}&program=C:\\londrisoft\\Gestor_Prime\\appgestor.exe&startupdir=C:\\londrisoft\\Gestor_Prime\\&params='${params}'`


      window.open(url)
    } catch (error: any) {
      toast({
        title: 'Falha ao abrir o sistema !',
        description: `${error.message}`,
        status: 'warning',
        position: 'top',
        isClosable: true,
      })
    }
  }

  const openSimplaz = async () => {
    await apiInstance(9000).post(
      '/logsApi/Logs/GravarLogs',
      {
        EmpresaCnpj: session.company.document,
        IdUsuario: session.id,
        NomeUsuario: session.name,
        Aplicativo: 'Simplaz',
        Acao: 'Acesso ao Simplaz',
        TipoAcao: 'Login',
        EndPoint: 'Simplaz/Login',
      },
      {
        headers: {
          GatewayLS: '2e44bb6339e6aacd8faeca8fd4e8694e',
        },
      }
    )
    window.open(
      `https://simplaz.londrisoft.com.br/login/?dados=${JSON.stringify([
        ...simplaz,
      ])}`
    )
  }

  return { openGestor, openSimplaz }
}
