import { Button, Flex, Text } from '@chakra-ui/react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import { NotFoundPage } from 'src/components/Animations'

export const NotFound = () => {
  const navigation = useNavigate()

  return (
    <>
      <Helmet>
        <title>🥲 | Workspace</title>
      </Helmet>
      <Flex
        height={'100vh'}
        width={'100%'}
        display={'flex'}
        align={'center'}
        justifyContent={'center'}
        flexDirection={'column'}
      >
        <NotFoundPage />
        <Text color={'#606060'}>Página não encontrada</Text>
        <Button
          variant={'ghost'}
          colorScheme={'purple'}
          onClick={() => navigation('/initial')}
        >
          Voltar
        </Button>
      </Flex>
    </>
  )
}
