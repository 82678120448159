import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { User } from 'src/screens/Users/hooks'
import { DisableUserButton } from './components/DisableUserButton'

export interface UsuariosAtivosTableProps {
  app: { appTag: string; appName: string }
  users: User[]
  reloadMainPage: () => void
}

export const UsuariosAtivosTable = ({
  app,
  users,
  reloadMainPage,
}: UsuariosAtivosTableProps) => {
  return (
    <Box height="100%">
      <Text
        fontSize={'16px'}
        fontWeight={600}
        color={'blue'}
      >{` Usuários ativos (${users.length})`}</Text>
      <Box overflowY={'auto'} height={'calc(100vh - 500px)'}>
        <TableContainer>
          <Table variant="simple" size={'xs'}>
            <Thead>
              <Tr>
                <Th>
                  <Text fontSize={'14px'} color={'#BABABA'}>
                    Nome
                  </Text>
                </Th>
                <Th>
                  <Text fontSize={'14px'} color={'#BABABA'}>
                    E-mail
                  </Text>
                </Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {users.map((user) => {
                return (
                  <Tr key={user.userId}>
                    <Td>
                      <Text fontSize={'14px'} color={'#606060'}>
                        {user.name}
                      </Text>
                    </Td>
                    <Td>
                      <Text fontSize={'14px'} color={'#606060'}>
                        {user.email}
                      </Text>
                    </Td>
                    <Td isNumeric>
                      {
                        <DisableUserButton
                          appTag={app.appTag}
                          user={user}
                          reloadMainPage={reloadMainPage}
                        />
                      }
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}
