import {
  FormControl,
  FormLabel,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  FormErrorMessage,
  InputRightElement,
  InputGroup,
  Icon,
} from '@chakra-ui/react'

import { RiEyeOffFill, RiEyeFill } from 'react-icons/ri'
import { forwardRef, ForwardRefRenderFunction, useState } from 'react'
import { FieldErrors } from 'react-hook-form'

type InputProps = ChakraInputProps & {
  name: string
  label?: string
  error?: FieldErrors
  phone?: boolean
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  { name, label, type, error = null, phone = false, ...rest },
  ref
) => {
  const [show, setShow] = useState(true)
  const handleClick = () => setShow(!show)

  const handleSwitchType = () => {
    if (show) {
      return 'password'
    }
    return 'text'
  }

  return (
    <FormControl id={name} isInvalid={!!error}>
      {!!label && (
        <FormLabel
          fontWeight="400"
          fontSize="14px"
          mb="2px"
          htmlFor={name}
          color="low.medium"
        >
          {label}
        </FormLabel>
      )}
      <InputGroup>
        <ChakraInput
          name={name}
          id={name}
          ref={ref}
          {...rest}
          backgroundColor="high.pure"
          borderRadius="4"
          borderColor="low.medium"
          _hover={{ borderColor: 'low.dark' }}
          fontSize={{ base: '16px', sm: '14px' }}
          color="low.dark"
          type={type !== 'password' ? type : handleSwitchType()}
          // onInput={(e: React.ChangeEvent<HTMLInputElement>) => maskPhone(e)}
        />

        {type === 'password' && (
          <InputRightElement
            children={
              <Icon
                as={show ? RiEyeOffFill : RiEyeFill}
                fontSize="22px"
                color="low.dark"
                cursor="pointer"
                onClick={handleClick}
              />
            }
          />
        )}
      </InputGroup>

      {!!error && <FormErrorMessage mt="4px">{error.message}</FormErrorMessage>}
    </FormControl>
  )
}

export const Input = forwardRef(InputBase)
