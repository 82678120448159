import { GestorProfiles, UniqueProfiles } from './profiles'

interface SetupProfile {
  profileId?: number
  origin: string
}

export const setupProfile = ({ profileId = 99, origin }: SetupProfile) => {
  let profiles

  if (origin === 'Gestor') {
    profiles = GestorProfiles
  } else {
    profiles = UniqueProfiles
  }

  let profile = profiles.find((prof) => prof.id === profileId.toString())

  if (!profile) {
    profile = {
      name: 'Verificar',
      id: '99',
      bgColor: '#ccc',
      textColor: '#01010',
    }
  }

  const helpLink =
    origin === 'Gestor'
      ? 'https://londrisoft.movidesk.com/kb/article/191604/perfis-de-usuario-gestor'
      : 'https://londrisoft.movidesk.com/kb/article/237072/perfil-de-usuario-unique'

  return { profile, profiles, helpLink }
}
