import { Divider, Flex, Icon, Text } from '@chakra-ui/react'
import { Clock, FolderOpen } from 'phosphor-react'
import { File } from '../../Reports'

interface FileCardProps {
  file: File
  actions: React.ReactNode
}

export const FileCard = ({ file, actions }: FileCardProps) => {
  return (
    <Flex
      // h="146px"
      minW="276px"
      flexDir="column"
      borderRadius="4px"
      bg="high.pure"
      boxShadow={
        '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
      }
      p="12px 12px 0px 12px"
      borderWidth="1px"
      _hover={{
        borderColor: 'rgba(17, 85, 187, 1)',
        boxShadow:
          '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)',
      }}
      transition="all 0.1s linear"
    >
      <Text
        fontSize="sm"
        color="low.medium"
        minW="276px"
        whiteSpace="break-spaces"
      >
        {file.name.toLowerCase()}
      </Text>
      <Flex justifyContent="space-between" my="8px">
        <Flex align="center">
          <Icon as={FolderOpen} fontSize="22px" color="low.light" />

          <Text
            ml="5px"
            fontSize="sm"
            color="low.light"
            textTransform="uppercase"
          >
            {file.type}
          </Text>
        </Flex>
        <Flex align="center">
          <Icon as={Clock} fontSize="22px" color="low.light" />
          <Text ml="5px" fontSize="sm" color="low.light">
            {file.expiry_date}
          </Text>
        </Flex>
      </Flex>
      <Text fontSize="xs" color="low.light">
        {file.email}
      </Text>

      <Divider borderColor="low.light" my="8px" />

      <Flex justify="end">{actions}</Flex>
    </Flex>
  )
}
