import { Route, Routes, useLocation } from 'react-router-dom'

import { AppShell } from 'src/components/AppShell'
import { ProtectedRoutes, HasAuth } from './auth'

import { Login } from 'src/screens/Login'
import { Recovery } from 'src/screens/Recovery'
import { Initial } from 'src/screens/Initial'
import { AllFiles } from 'src/screens/Files'
import { Users } from 'src/screens/Users'
import { CreateUser } from 'src/screens/Users/Create'
import { EditingUser } from 'src/screens/Users/Editing'

import { Analytics } from 'src/screens/Analytics'
import { PDFviewer } from 'src/screens/PDFviewer'

import { NotFound } from 'src/screens/404'
import { CompanySettings } from 'src/screens/Company-settings'
import { Participants } from 'src/screens/Participants'
import { ParticipantCertificate } from 'src/screens/Participants/Certificate'
import { AnalyticsPRO } from 'src/screens/BI'
import { Store } from 'src/screens/Store'
import { Integrations } from 'src/screens/Store/Integrations'
import { Tools } from 'src/screens/Store/Tools'
import { ImportadorXLS } from 'src/screens/Importador'
import PreviewPage from 'src/screens/Importador/containers/Pages/selectHeaderTable'
import CorrelationPage from 'src/screens/Importador/containers/Pages/correlationPage'
import CorrelationSaved from 'src/screens/Importador/containers/Pages/correlationSaved'
import SelectCorrelationPage from 'src/screens/Importador/containers/Pages/selectLineToImport'
import { Quod } from 'src/screens/Quod'

export const MainRoutes = () => {
  const location = useLocation()
  return (
    <Routes location={location}>
      <Route element={<HasAuth />}>
        <Route path="/" element={<Login />} />
        <Route path="/recovery" element={<Recovery />} />
      </Route>

      <Route element={<ProtectedRoutes />}>
        <Route element={<AppShell />}>
          <Route path="/initial" element={<Initial />} />
          <Route path="/files" element={<AllFiles />} />

          <Route path="/company-settings" element={<CompanySettings />} />

          <Route path="/users">
            <Route index element={<Users />} />
            <Route path="create" element={<CreateUser />} />
            <Route path="editing" element={<EditingUser />} />
          </Route>

          <Route path="/participants">
            <Route index element={<Participants />} />
            <Route path="management" element={<ParticipantCertificate />} />
          </Route>

          <Route path="/importador-xls">
            <Route index element={<ImportadorXLS />} />
            <Route path="selectHeaderTable/:data" element={<PreviewPage />} />
            <Route path="correlation/:path" element={<CorrelationPage />} />
            <Route
              path="correlationSaved/:path"
              element={<CorrelationSaved />}
            />
            <Route
              path="selectpage/:params"
              element={<SelectCorrelationPage />}
            />
          </Route>

          <Route path="analytics" element={<Analytics />} />
          <Route path="analytics-pro" element={<AnalyticsPRO />} />

          <Route path="/quod" element={<Quod />} />

          <Route path="/store" element={<Store />}>
            <Route path="integrations" element={<Integrations />}>
              <Route path="" element={<Integrations />} />
              <Route path=":apptag" element={<Integrations />} />
            </Route>
            <Route path="tools" element={<Tools />} />
          </Route>
        </Route>
      </Route>

      <Route path="/PDFviewer" element={<PDFviewer />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
