export function applyHtmlCodeInEspecialCharacter(value: string): string {
  return value
    .replaceAll('À', '&#192;')
    .replaceAll('Á', '&#193;')
    .replaceAll('Â', '&#194;')
    .replaceAll('Ã', '&#195;')
    .replaceAll('Ä', '&#196;')
    .replaceAll('Ç', '&#199;')
    .replaceAll('È', '&#200;')
    .replaceAll('É', '&#201;')
    .replaceAll('Ê', '&#202;')
    .replaceAll('Ë', '&#203;')
    .replaceAll('Ì', '&#204;')
    .replaceAll('Í', '&#205;')
    .replaceAll('Î', '&#206;')
    .replaceAll('Ò', '&#210;')
    .replaceAll('Ó', '&#211;')
    .replaceAll('Ô', '&#212;')
    .replaceAll('Õ', '&#213;')
    .replaceAll('Ö', '&#214;')
    .replaceAll('Ù', '&#217;')
    .replaceAll('Ú', '&#218;')
    .replaceAll('Û', '&#219;')
    .replaceAll('Ü', '&#220;')
    .replaceAll('à', '&#224;')
    .replaceAll('á', '&#225;')
    .replaceAll('â', '&#226;')
    .replaceAll('ã', '&#227;')
    .replaceAll('ä', '&#228;')
    .replaceAll('ç', '&#231;')
    .replaceAll('è', '&#232;')
    .replaceAll('é', '&#233;')
    .replaceAll('ê', '&#234;')
    .replaceAll('ë', '&#235;')
    .replaceAll('ì', '&#236;')
    .replaceAll('í', '&#237;')
    .replaceAll('î', '&#238;')
    .replaceAll('ò', '&#242;')
    .replaceAll('ó', '&#243;')
    .replaceAll('ô', '&#244;')
    .replaceAll('õ', '&#245;')
    .replaceAll('ö', '&#246;')
    .replaceAll('ù', '&#249;')
    .replaceAll('ú', '&#250;')
    .replaceAll('û', '&#251;')
    .replaceAll('ü', '&#252;')
}
