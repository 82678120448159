import { ListItem, UnorderedList } from '@chakra-ui/react'

export interface FuncionalidadesProps {
  listaFuncionalidades: string[]
}
export const Funcionalidades = ({
  listaFuncionalidades,
}: FuncionalidadesProps) => {
  return (
    <UnorderedList>
      {listaFuncionalidades?.map((func, index) => {
        return (
          <ListItem fontSize={'14px'} key={index}>
            {func}
          </ListItem>
        )
      })}
    </UnorderedList>
  )
}
