import axios from 'axios'
import { config } from 'process'
import { apiInstance, getAccessTokenNucleo } from 'src/services/api'

interface Base64ForEmailsProps {
  emails: string[]
  htmlFile: string
}
export async function sendBase64ForEmails({
  emails,
  htmlFile,
}: Base64ForEmailsProps) {
  const Email = emails.join(';')
  const Base64 = htmlFile
  try {
    const accessTokenNucleo = (await getAccessTokenNucleo().then(
      (res) => `Bearer ${res}`
    )) as string

    const headers = {
      'Content-Type': 'Application/Json',
      Authorization: `${accessTokenNucleo}`,
    }

    await apiInstance(9008).post(
      '/api/user/notifyuser',
      {
        Email,
        Base64,
      },
      {
        headers: headers,
      }
    )
  } catch (error) {
    console.log(error)
  }
}
