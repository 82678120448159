import {
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Flex,
  Tooltip,
  ButtonGroup,
  IconButton,
  Divider,
} from '@chakra-ui/react'

import { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'

import { Reports } from './Reports'
import { Sent } from './Sent'
import { Print } from './Print'
import { TabHeader } from '../Analytics/components/TabHeader'
import { apiInstance } from 'src/services/api'
import { useSession } from 'src/contexts'

import { CompanyChange } from './components/CompanyChange'
import { ListDashes, Rows } from 'phosphor-react'

type Visualization = 'card' | 'list'

interface Company {
  razao: string
  cnpjCpf: string
}

export const AllFiles = () => {
  const { session } = useSession()
  const [companies, setCompanies] = useState<Company[]>([])
  const [selectedCompany, setSelectedCompany] = useState<Company>(() => ({
    cnpjCpf: session.company.document,
    razao: session.company.name,
  }))
  const [tabIndex, setTabIndex] = useState(0)
  const [visualization, setVisualization] = useState<Visualization>('list')

  const changeVisualization = (value: Visualization) => setVisualization(value)

  const changeCompany = (company: Company) => setSelectedCompany(company)

  const handleTabsChange = (index: number) => {
    setTabIndex(index)
  }

  const getGrupoEconomico = useCallback(async () => {
    try {
      const { data } = await apiInstance(9000).get(
        `/workspace/Empresa/GrupoEconomico/${session.company.document}`,
        {
          headers: {
            GatewayLS: '2e44bb6339e6aacd8faeca8fd4e8694e',
          },
        }
      )

      setCompanies(data.grupo)
      // setSelectedCompany(data.grupo[0])
    } catch (error) {
      console.log(error)
    }
  }, [session.company.document])

  const hasGroup = companies.length > 1

  useEffect(() => {
    getGrupoEconomico()
  }, [getGrupoEconomico])

  return (
    <>
      <Helmet>
        <title>Gerência de Arquivos | Workspace</title>
      </Helmet>

      <Tabs
        onChange={handleTabsChange}
        w="full"
        bg="high.pure"
        borderRadius="4px"
        boxShadow={
          '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
        }
      >
        <TabList>
          <TabHeader title="Relatórios" hasSelect={tabIndex === 0} />
          <TabHeader title="Enviados" hasSelect={tabIndex === 1} />
          <TabHeader
            title="Impressão"
            hasSelect={tabIndex === 2}
            display={{ base: 'none', lg: 'block' }}
          />
        </TabList>

        <TabPanels>
          <TabPanel id="arquivos" h="calc(100vh - 130px)">
            <TabPanelHeader
              visualization={visualization}
              changeVisualization={changeVisualization}
              companies={companies}
              changeCompany={changeCompany}
              selectedCompany={selectedCompany}
              hasGroup={hasGroup}
            />
            <Reports
              step={tabIndex}
              selectedCompany={selectedCompany}
              visualization={visualization}
              hasGroup={hasGroup}
            />
          </TabPanel>
          <TabPanel id="enviados" h="calc(100vh - 130px)">
            <TabPanelHeader
              visualization={visualization}
              changeVisualization={changeVisualization}
              companies={companies}
              changeCompany={changeCompany}
              selectedCompany={selectedCompany}
              hasGroup={hasGroup}
            />
            <Sent
              step={tabIndex}
              selectedCompany={selectedCompany}
              visualization={visualization}
              hasGroup={hasGroup}
            />
          </TabPanel>
          <TabPanel
            id="impressao"
            h="calc(100vh - 130px)"
            display={{ base: 'none', lg: 'block' }}
          >
            <Print step={tabIndex} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  )
}

interface TabPanelHeaderProps {
  visualization: Visualization
  changeVisualization: (value: Visualization) => void
  companies: Company[]
  changeCompany: (company: Company) => void
  selectedCompany: Company
  hasGroup: boolean
}

const TabPanelHeader = ({
  visualization,
  changeVisualization,
  companies,
  changeCompany,
  selectedCompany,
  hasGroup,
}: TabPanelHeaderProps) => {
  return (
    <Flex align="center" justify="space-between" py="4px">
      {hasGroup ? (
        <CompanyChange
          companies={companies}
          changeCompany={changeCompany}
          selectedCompany={selectedCompany}
        />
      ) : null}

      <ButtonGroup size="sm" variant="ghost" minH="10xp" ml="auto">
        <Tooltip label="Lista" bg="high.pure" color="low.dark" fontSize="xs">
          <IconButton
            aria-label="visualization"
            icon={<ListDashes size={22} />}
            onClick={() => changeVisualization('list')}
            isDisabled={visualization === 'list'}
          />
        </Tooltip>
        <Divider
          my="auto"
          minH="18px"
          orientation="vertical"
          borderColor="low.light"
        />
        <Tooltip label="Card" bg="high.pure" color="low.dark" fontSize="xs">
          <IconButton
            aria-label="visualization"
            icon={<Rows size={22} />}
            onClick={() => changeVisualization('card')}
            isDisabled={visualization === 'card'}
          />
        </Tooltip>
      </ButtonGroup>
    </Flex>
  )
}
