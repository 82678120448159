import { Box, Flex, Icon, IconButton, useMediaQuery } from '@chakra-ui/react'

import { Link } from 'react-router-dom'

import { ReactComponent as HMenu } from 'src/assets/icons/menu_icon.svg'

import { HeaderMenu } from './Components/HeaderMenu'
import { BreadCrumb } from './Components/Breadcrumb'

import { ReactComponent as Logo } from 'src/assets/imgs/workspace_horizontal.svg'
import { HeaderNotifications } from './Components/Notifications'

type HeaderProps = {
  handleToogleMenu: () => void
  hasToogleMenu: boolean
}

export const Header = ({ handleToogleMenu, hasToogleMenu }: HeaderProps) => {
  const [isLargerThan1300px] = useMediaQuery('(min-width: 1367px)')

  return (
    <Flex
      height={'56px'}
      width={'100%'}
      justifyContent={'space-between'}
      alignItems={'center'}
      padding={{ base: '0px 16px' }}
      boxShadow={
        '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
      }
      bg="high.pure"
      zIndex={3}
    >
      <Flex
        w={{ base: '1200px', xl: '1370px' }}
        m="0 auto"
        align="center"
        justify="space-between"
      >
        <Flex align="center" gap={4}>
          {!hasToogleMenu && (
            <Box w="210px">
              <Link to={'/'}>
                <Logo />
              </Link>
            </Box>
          )}
          {hasToogleMenu && (
            <IconButton
              variant="ghost"
              aria-label="menu"
              icon={<Icon as={HMenu} fontSize="24px" />}
              onClick={handleToogleMenu}
            />
          )}

          <BreadCrumb />
        </Flex>
        <Flex gap="4">
          {/* <HeaderNotifications /> */}
          <HeaderMenu />
        </Flex>
      </Flex>
    </Flex>
  )
}

/* <Flex
bg="red"
maxW="1200px"
w="full"
ml="auto"
align="center"
// justify="space-between"
>
<Flex align="center" gap={4}>
  {hasToogleMenu && (
    <IconButton
      variant="ghost"
      aria-label="menu"
      icon={<Icon as={HMenu} fontSize="24px" />}
      onClick={handleToogleMenu}
    />
  )}

  <BreadCrumb />
</Flex>
<Flex gap="4">
  <HeaderMenu />
</Flex>
</Flex> */
