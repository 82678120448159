import { TReturnTable } from '../types/types'

interface VerifyRequiredFieldProps {
  returnTable: TReturnTable[]
  isRequiredField: number[]
}

export const VerifyRequiredField = ({
  returnTable,
  isRequiredField,
}: VerifyRequiredFieldProps) => {
  const requiredFields = returnTable.filter((item) => {
    return isRequiredField.includes(item.key_table)
  })

  const allFilled =
    requiredFields.length === isRequiredField.length ? true : false

  if (allFilled) {
    return true
  } else {
    return false
  }
}
