import { Text } from '@chakra-ui/react'

export interface TitleProps {
  title: string
}
export const Title = ({ title }: TitleProps) => {
  return (
    <Text fontSize={'16px'} fontWeight={'600'}>
      {title}
    </Text>
  )
}
