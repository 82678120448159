export const organizeTableIsNotNull = (table: any): string[] => {
  const tableIsNotNull = table.filter(
    (item: any) =>
      item !== null && item !== '' && item !== ' ' && item.length > 1
  )

  const tableFormatterIsNotLineNull: string[] = []

  for (let i = 0; i < tableIsNotNull.length; i++) {
    const element: string[] = tableIsNotNull[i]
    const element2 = element.filter(
      (item) =>
        item !== null &&
        item !== '' &&
        item !== ' ' &&
        item.length > 1 &&
        item.trim() !== ''
    )
    if (element2.length >= 1) {
      tableFormatterIsNotLineNull.push(tableIsNotNull[i])
    }
  }

  return tableFormatterIsNotLineNull
}
