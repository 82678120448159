import { getOrdersSaved } from 'src/services/api'
import { postSavedOrderToGestor } from 'src/services/api'

export const handleSaveOrderConfiguration = async (
  lineSelectedToImported: string[],
  newTable: any,
  cnpjCompany: string
) => {
  const ordersSelecteds = newTable.filter((item: any) => {
    return lineSelectedToImported.includes(item.referenceId)
  })

  try {
    for (const lines of ordersSelecteds) {
      await postSavedOrderToGestor(lines, cnpjCompany)
    }
  } catch (err) {
    return false
  }
}
