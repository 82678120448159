import { Box, Center, Divider, Flex, Text } from '@chakra-ui/react'
import { setgid } from 'process'
import { FormInput } from 'src/components/FormInput'
import { useSession } from 'src/contexts'
import { formatting } from 'src/utilities/formattingToPattern'
import { TabelaPlansApp } from './components/TabelaPlansApps'

export const Plan = () => {
  const { session } = useSession()

  const plansGestor = [
    { id: 1, description: 'Plano Avançado' },
    { id: 2, description: 'Plano Intermediário' },
    { id: 3, description: 'Plano Básico' },
    { id: 4, description: 'Plano NF-e' },
  ]

  const plansUnique = [
    { id: 0, description: 'Plano Premium' },
    { id: 1, description: 'Plano Plus' },
    { id: 2, description: 'Plano Light' },
  ]

  const plans = session.origin === 'Gestor' ? plansGestor : plansUnique

  const data = {
    financial: session.plan?.indfinanceiro === '0' ? 'Normal' : 'Pendente',
    users: JSON.parse(session.plan?.users).filter((user: any) => user.status)
      .length,
    plan:
      session.origin === 'Gestor'
        ? plansGestor.find((plan) => plan.id === session.plan.planoGestorId)
        : plansUnique.find((plan) => plan.id === session.plan.planoUniqueId),
    integrations: session.plan.aplicativos
      .split('|')
      .filter((app: string) => app.length !== 0)
      .filter(
        (integration) => integration !== 'GESTOR' && integration !== 'UNIQUE'
      ),
  }

  // console.log(data)

  return (
    <Flex flexDir="column">
      <Text>Planos</Text>
      <Flex align="center" justify="space-between" h="70px" px="20px" mt="10px">
        <Box textAlign="center" w="240px">
          <Text fontSize="xs" color="low.light">
            Plano Atual
          </Text>
          <Text fontSize="sm" mt="10px">
            {data.plan?.description}
          </Text>
        </Box>
        <Divider orientation="vertical" h="40%" />
        <Box textAlign="center" w="240px">
          <Text fontSize="xs" color="low.light">
            Usuários Ativos
          </Text>
          <Text fontSize="sm" mt="10px">
            {data.users}
          </Text>
        </Box>
        <Divider orientation="vertical" h="40%" />
        <Box textAlign="center" w="240px">
          <Text fontSize="xs" color="low.light">
            Integrações Ativas
          </Text>
          <Text fontSize="sm" mt="10px">
            {data.integrations.length}
          </Text>
        </Box>
        <Divider orientation="vertical" h="40%" />
        <Box textAlign="center" w="240px">
          <Text fontSize="xs" color="low.light">
            Situação financeiro
          </Text>
          <Text fontSize="sm" mt="10px">
            {data.financial}
          </Text>
        </Box>
      </Flex>

      <Flex px="20px" gap="24px" mt="10px" justify="space-around">
        {plans.map((plan) => (
          <PlanCard
            key={plan.id}
            id={plan.id}
            description={plan.description}
            hasSelect={plan.id === data.plan?.id}
            openLink={
              session.origin === 'Gestor'
                ? () =>
                    window.open(
                      'https://materiais.londrisoft.com.br/gestor-planos',
                      '_blank'
                    )
                : () =>
                    window.open(
                      'https://materiais.londrisoft.com.br/unique-tabela-de-planos',
                      '_blank'
                    )
            }
          />
        ))}
      </Flex>
      <Text mt="24px">Contato Principal</Text>
      <Flex gap={8}>
        <FormInput
          label="Responsável"
          isDisabled
          defaultValue={session.company.responsible}
          control={{ w: '270px' }}
        />
        <FormInput
          label="E-mail do usuário principal"
          isDisabled
          defaultValue={session.company.email}
          control={{ w: '270px' }}
        />
        <FormInput
          label="Telefone principal"
          isDisabled
          defaultValue={formatting({
            value: session.company.phone,
            pattern: 'phone',
          })}
          control={{ w: '175px' }}
        />
      </Flex>
      <TabelaPlansApp session={session} />
    </Flex>
  )
}

const PlanCard = ({
  id,
  description,
  hasSelect,
  openLink,
}: {
  id: number
  description: string
  hasSelect: boolean
  openLink?: () => void
}) => {
  return (
    <Center
      onClick={openLink}
      cursor="pointer"
      h="100px"
      maxW="250px"
      w="full"
      borderRadius="4px"
      opacity={hasSelect ? 1 : 0.5}
      __css={{
        background:
          'radial-gradient(100.49% 100.49% at 50% 20.59%, #1155BB 0%, #3BB2EE 100%)',
        boxShadow:
          '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)',
      }}
    >
      <Text
        fontWeight="600"
        color="high.pure"
        textAlign="center"
        fontSize={{ base: 'sm' }}
      >
        {description}
      </Text>
    </Center>
  )
}
