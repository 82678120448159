import { Flex } from '@chakra-ui/react'
import { Header } from 'src/screens/Company-settings/Charge/components/Header'
import { TableIndex } from './components/Table'
import { FooterIndex } from './components/Footer'
import { useSession } from 'src/contexts'
import { useMemo, useState } from 'react'
import { getCharges } from 'src/services/api'

interface IBoletos {
  cnpjCpf: string
  email: string
  dtVencimento: string
  valor: string
  dtPagamento: string
  boleto: string
  nota: string
}

export const Charge = () => {
  const { session } = useSession()

  const [listInfoBoleto, setListInfoBoleto] = useState<IBoletos[]>([])

  useMemo(async () => {
    const listInfoBoleto = await getCharges(session.company.document)
    setListInfoBoleto(listInfoBoleto)
  }, [session.company.document])

  return (
    <Flex flexDir={'column'} mt="10px" ml="17px">
      <Flex>
        <Header />
      </Flex>
      <Flex>
        <TableIndex listInfoBoleto={listInfoBoleto} />
      </Flex>
      <Flex>
        <FooterIndex />
      </Flex>
    </Flex>
  )
}
