import { ExtraProvider } from './extra'
import { SessionProvider } from './sessions'

interface Props {
  children: React.ReactNode
}

export const ContextsProvider = ({ children }: Props) => {
  return (
    <SessionProvider>
      <>{children}</>
    </SessionProvider>
  )
}
