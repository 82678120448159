export const csosnPrincipal = [
  // { name: 'Não informado', code: 0 },
  { name: 'Permisão de crédito - 101', code: 101 },
  { name: 'Sem crédito - 102', code: 102 },
  { name: 'Isenção de ICMS - 103', code: 103 },
  { name: 'Permissão crédito com subs tributária - 201', code: 201 },
  { name: 'Sem crédito com subs tributária - 202', code: 202 },
  { name: 'Isenção ICMS com subs tributária - 203', code: 203 },
  { name: 'Imune - 300', code: 300 },
  { name: 'Não tributada - 400', code: 400 },
  { name: 'ICMS subs tributária (Substituído) - 500', code: 500 },
  { name: 'Outros - 900', code: 900 },
]

export const csosnCosumidor = [
  // { name: 'Não informado', code: 0 },
  { name: 'Sem crédito - 102', code: 102 },
  { name: 'Isenção de ICMS - 103', code: 103 },
  { name: 'Imune - 300', code: 300 },
  { name: 'Não tributada - 400', code: 400 },
  { name: 'ICMS subs tributária (Substituído) - 500', code: 500 },
  { name: 'Outros - 900', code: 900 },
]
