import { Flex, Text, Fade, useBreakpointValue, Image } from '@chakra-ui/react'
import Lottie from 'react-lottie'

import notFoundFiles from './no-search-result.json'
import cantFindAnimation from './cantfind.json'
import uploadFile from './upload-files.json'
import emptyFile from './emptyFile.json'

import notFoundPage from './404.json'

export const CantFind = () => {
  return (
    <Flex flexDirection={'column'} justifyContent="center" alignItems="center">
      <Lottie
        style={{ fillOpacity: 0, pointerEvents: 'none', zIndex: '1' }}
        options={{
          animationData: cantFindAnimation,
          autoplay: true,
          loop: false,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        width={250}
        height={250}
      />
      <Text fontSize={'18px'} mt="20px" color={'low.light'}>
        Nada por aqui!
      </Text>
    </Flex>
  )
}

export const NotFoundFiles = () => {
  return (
    <Flex flexDirection={'column'} justifyContent="center" alignItems="center">
      <Lottie
        style={{ fillOpacity: 0, pointerEvents: 'none' }}
        options={{
          animationData: notFoundFiles,
          autoplay: true,
          loop: false,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        width={250}
        height={250}
      />
      <Text color={'#606060'}>Nada por aqui!</Text>
    </Flex>
  )
}
export const NotFoundPage = () => {
  return (
    <Flex flexDirection={'column'} justifyContent="center" alignItems="center">
      <Lottie
        style={{ fillOpacity: 0, pointerEvents: 'none' }}
        options={{
          animationData: notFoundPage,
          autoplay: true,
          loop: true,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        // width={600}
        // height={600}
      />
    </Flex>
  )
}

export const UploadFile = () => {
  return (
    <Flex flexDirection={'column'} justifyContent="center" alignItems="center">
      <Lottie
        style={{ fillOpacity: 0, pointerEvents: 'none' }}
        options={{
          animationData: uploadFile,
          autoplay: true,
          loop: true,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        width={200}
        height={200}
      />
    </Flex>
  )
}

export const EmptyFile = () => {
  return (
    <Flex flexDirection={'column'} justifyContent="center" alignItems="center">
      <Lottie
        style={{ fillOpacity: 0, pointerEvents: 'none' }}
        options={{
          animationData: emptyFile,
          autoplay: true,
          loop: false,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        width={300}
        height={300}
      />
    </Flex>
  )
}
