import { useSession } from 'src/contexts'
import { decrypted } from 'src/utilities/hashing'

interface UseCanParams {
  permissions?: string[]
  perfil?: number | null
}

export const useCan = ({ permissions = [], perfil = null }: UseCanParams) => {
  const { session } = useSession()
  const DPermissions = decrypted(session.permissions || [])

  // if (!perfil) {
  //   const admin = perfil === 1 ? true : false

  //   return admin
  // }

  if (permissions?.length > 0) {
    const hasPermissions = permissions?.every((app) => {
      return DPermissions?.includes(app)
    })

    if (!hasPermissions) {
      return false
    }
  }

  return true
}
