import { Select } from '@chakra-ui/react'

const SelectItem = ({ data, key_gestor, func }: any) => {
  function manipulaTable(id: string) {
    func(key_gestor, id)
  }

  return (
    <>
      <Select
        w={'176px'}
        h="31px"
        borderRadius={'4px'}
        borderColor={'1px solid #58595B'}
        onChange={(e) => manipulaTable(e.target.value)}
        isRequired
        fontSize={'14px'}
      >
        <option value={' '}>Selecione o dado</option>
        {data.map((item: string, index: number) => (
          <option key={index} value={index}>
            {item}
          </option>
        ))}
      </Select>
    </>
  )
}

export default SelectItem
