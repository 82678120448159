export function encrypted(data: any) {
  let hash: number[] = []
  let i: number
  let chr: number

  const dados = data.toString()

  if (dados.length === 0) return hash
  for (i = 0; i < dados.length; i++) {
    chr = dados.charCodeAt(i)
    hash.push(chr) // Convert to 32bit integer
  }
  return hash
}

export const decrypted = (data: number[]): string[] =>
  data
    .map((x) => String.fromCharCode(x).replace("'", ''))
    .join('')
    .split(',')

export function encryptedSimple<T>(obj: T): string {
  return window.globalThis.btoa(JSON.stringify(obj))
}

export function decryptedSimple<T>(data: string): T {
  return JSON.parse(window.globalThis.atob(data))
}
