import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { User } from 'src/screens/Users/hooks'
import { updateUserAppTag } from 'src/services/api'

export interface ModalRemoveUserProps {
  appTag: string
  user: User
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}
export const ModalRemoveUser = ({
  appTag,
  user,
  isOpen,
  onOpen,
  onClose,
}: ModalRemoveUserProps) => {
  function handleClickConfirmRemoveUsuario() {
    try {
      updateUserAppTag({
        userId: user.userId,
        userApps: user.aplicativos,
        appTag: appTag,
        action: 'delete',
      })
    } catch (error) {
      console.log(error)
    }
    onClose()
  }
  return (
    <Modal isCentered size={'2xl'} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text fontSize={'20px'} fontWeight={700}>
            Remover Usuário
          </Text>
        </ModalHeader>
        <ModalBody>
          <Flex flexDirection={'column'} gap={5}>
            <Flex flexDirection={'column'} gap={2}>
              <Text fontSize={'14px'}>
                Tem certeza que deseja remover o acesso ao dashboard do usuário?
              </Text>
              <Text fontSize={'14px'} fontWeight={600}>
                {`${user.name} - ${user.email}`}
              </Text>
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blackAlpha" marginRight={3} onClick={onClose}>
            Não
          </Button>
          <Button colorScheme="green" onClick={handleClickConfirmRemoveUsuario}>
            Sim
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
