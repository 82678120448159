import { Navigate, Outlet } from 'react-router-dom'
import { useSession } from 'src/contexts'

export const ProtectedRoutes = () => {
  const { session } = useSession()

  const isAuth = !!session.id

  return isAuth ? (
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to="/" />
  )
}

export const HasAuth = () => {
  const { session } = useSession()

  const isAuth = !session.id

  return isAuth ? <Outlet /> : <Navigate to="/initial" />
}
