import {
  Box,
  Button,
  Center,
  Flex,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react'
import { DotsThreeVertical, MagnifyingGlass, UserGear } from 'phosphor-react'
import { FormEvent, useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import { useSession } from 'src/contexts'
import { apiInstance } from 'src/services/api'
import { formatting } from 'src/utilities/formattingToPattern'
import { Participant } from './types'

import { ReactComponent as NotFindImage } from 'src/assets/imgs/not-find.svg'

import { ReactComponent as ShieldIcon } from 'src/assets/icons/security_safe.svg'

export const Participants = () => {
  const { session } = useSession()
  const [isFetching, setIsFetching] = useState(true)
  const [textFilter, setTextFilter] = useState('')
  const [participants, setParticipants] = useState<Participant[]>()

  const navigate = useNavigate()

  const getParticipants = useCallback(async () => {
    try {
      setIsFetching(true)
      const { data } = await apiInstance(9000).get(
        `/workspace/Participante/${session.company.document}?page=0&limit=1000`,
        {
          headers: {
            GatewayLS: '2e44bb6339e6aacd8faeca8fd4e8694e',
          },
        }
      )
      await new Promise((resolve) => setTimeout(resolve, 500))

      const p = data.clientes.map((c: any) => ({
        ...c,
        ...c.participanteEndereco[0],
      }))

      setParticipants(p)
    } catch (error) {
      console.log(error)
    } finally {
      setIsFetching(false)
    }
  }, [session.company.document])

  const selectedParticipant = (participant: Participant) => {
    localStorage.setItem('ws@Participant', JSON.stringify(participant))
    navigate('management')
  }

  const filteredParticipants = useCallback(() => {
    let res = participants
    if (participants) {
      res = participants.filter((participant) =>
        participant.razao.toLowerCase().includes(textFilter)
      )
      return res
    }

    return []
  }, [participants, textFilter])

  useEffect(() => {
    getParticipants()
  }, [getParticipants])

  return (
    <>
      <Helmet>
        <title>Gerência de Participantes | Workspace</title>
      </Helmet>
      <Flex
        flexDirection="column"
        w="full"
        bg="high.pure"
        boxShadow={
          '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
        }
        borderRadius="4px"
        padding="8px 12px"
      >
        <Flex align="center" justify="space-between">
          <Text>Participantes</Text>

          <InputGroup w="300px">
            <Input
              h="36px"
              placeholder="Buscar por nome"
              onChange={(e: FormEvent<HTMLInputElement>) =>
                setTextFilter(e.currentTarget.value.toLowerCase())
              }
              value={textFilter}
            />
            <InputLeftElement h="36px" children={<MagnifyingGlass />} />
          </InputGroup>
        </Flex>
        {isFetching ? (
          <Center h="full" w="full">
            <Spinner
              size="xl"
              color="primary.pure"
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
            />
          </Center>
        ) : !isFetching && filteredParticipants().length === 0 ? (
          <Center h="full" flexDir="column" gap={4}>
            <NotFindImage />
            <Text fontSize="sm" color="low.light">
              Nada por aqui! Verifique os filtros e tente novamente
            </Text>
          </Center>
        ) : (
          <Box overflowY="auto" mt="20px">
            <Table size="sm">
              <Thead position="sticky" top="0" bg="high.pure">
                <Tr h="40px">
                  <Th
                    fontSize="sm"
                    fontWeight="400"
                    color="low.light"
                    borderWidth="0px"
                  >
                    Nome
                  </Th>
                  <Th
                    fontSize="sm"
                    fontWeight="400"
                    color="low.light"
                    borderWidth="0px"
                  >
                    CNPJ/CPF
                  </Th>
                  <Th
                    fontSize="sm"
                    fontWeight="400"
                    color="low.light"
                    borderWidth="0px"
                  >
                    Certificado
                  </Th>
                  <Th
                    fontSize="sm"
                    fontWeight="400"
                    color="low.light"
                    borderWidth="0px"
                  >
                    E-Mail
                  </Th>

                  <Th
                    fontSize="sm"
                    fontWeight="400"
                    color="low.light"
                    borderWidth="0px"
                    textAlign="center"
                  >
                    <Icon
                      fontSize="22px"
                      children={<ShieldIcon style={{ fill: '#166DD7' }} />}
                    />
                  </Th>
                </Tr>
              </Thead>

              <Tbody>
                {filteredParticipants()?.map((participant) => (
                  <Tr key={participant.participanteId}>
                    <Td fontSize="12px">{participant.razao}</Td>
                    <Td w="200px">
                      {formatting({
                        value: participant.cnpjCpf || '',
                        pattern: 'document',
                      })}
                    </Td>
                    <Td>
                      {new Date(
                        participant.dtVectocertificado
                      ).toLocaleDateString() === '01/01/1' ? (
                        <Text color="high.dark" textDecoration="line-through">
                          00/00/00
                        </Text>
                      ) : (
                        new Date(
                          participant.dtVectocertificado
                        ).toLocaleDateString()
                      )}
                    </Td>
                    <Td>{participant.email.toLowerCase()}</Td>
                    <Td py="4px">
                      <Tooltip
                        label="Mais"
                        placement="left-start"
                        bg="high.pure"
                        color="primary.pure"
                      >
                        <IconButton
                          size="sm"
                          aria-label="more"
                          position="initial"
                          variant="link"
                          onClick={() => selectedParticipant(participant)}
                          children={<DotsThreeVertical size={22} />}
                        />
                      </Tooltip>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        )}
      </Flex>
    </>
  )
}
