import {
  ComponentStyleConfig,
  extendTheme,
  theme as ChakraTheme,
} from '@chakra-ui/react'

const breakpoints = {
  ...ChakraTheme.breakpoints,
  xl: '1300px',
}

export const theme = extendTheme({
  styles: {
    global: {
      'body, #root': {
        background: 'high.light',
        overflowY: 'hidden',
      },
      'html, body': {
        color: 'low.dark',
      },
      '::placeholder': {
        fontSize: '14px',
      },
    },
  },
  fonts: {
    body: 'Sora, sans-serif',
    heading: 'Sora, sans-serif',
  },
  colors: {
    primary: {
      pure: '#166DD7',
      light: '#3BB2EE',
      medium: '#1155BB ',
      dark: '#113377',
    },
    highlight: {
      pure: '#8855EE',
      light: '#BB88FF',
      medium: '#9055FF',
      dark: '#7044BB',
    },
    low: {
      pure: '#000000',
      light: '#BABABA',
      medium: '#606060',
      dark: '#303030',
    },
    high: {
      pure: '#FFFFFF',
      light: '#FBFBFB',
      medium: '#F0F0F0',
      dark: '#E5E5E5',
    },
    error: {
      pure: '#D13F3F',
      light: '#E5ACAC',
      medium: '#D16161',
      dark: '#B22424',
    },
    alert: {
      pure: '#F48619',
      light: '#FFB266',
      medium: '#F29130',
      dark: '#B25900',
    },
    success: {
      pure: '#008844',
      light: '#6CD9A0',
      medium: '#22BB6B',
      dark: '#138047 ',
    },
    gray: {
      100: '#F0F0F0',
    },
  },
  config: {
    cssVarPrefix: 'lnd',
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: '400',
        borderRadius: '4px',
      },
      sizes: {
        md: {
          fontSize: 'sm',
        },
      },
      variants: {
        solid: ({ colorScheme }) => {
          if (colorScheme === 'blue') {
            return {
              bg: 'primary.pure',
              _hover: {
                bg: 'primary.medium',
              },
            }
          }
        },
        link: {
          height: '36px',
          fontsize: '14px',
        },
      },
    } as ComponentStyleConfig,
  },
  breakpoints,
})

// import { extendTheme } from '@chakra-ui/react'
// import { Button } from './components/button'

// // sm: 480px / md: 768px / lg: 992px / xl: 1280px

// export const theme = extendTheme({
//   styles: {
//     global: {
//       'html, body': {
//         color: 'low.dark',
//       },
//       'body, #root': {
//         background: 'high.pure',
//         overflow: 'hidden',
//         height: '100vh',
//       },
//       '::-webkit-scrollbar': {
//         width: '6px',
//         background: '#E5E5E5',
//         borderRadius: '4px',
//       },
//       '::-webkit-scrollbar-thumb': {
//         background: '#166DD7',
//         borderRadius: '4px',
//       },
//       '.apexcharts-text tspan': {
//         fontFamily: 'Sora, sans-serif !important',
//       },
//     },
//   },
//   fonts: {
//     body: 'Sora, sans-serif',
//     heading: 'Sora, sans-serif',
//   },
//   colors: {
//     primary: {
//       pure: '#1155BB',
//       light: '#3BB2EE',
//       medium: '#166DD7',
//       dark: '#113377',
//     },
//     highlight: {
//       pure: '#8855EE',
//       light: '#BB88FF',
//       medium: '#9055FF',
//       dark: '#7044BB',
//     },
//     low: {
//       pure: '#000000',
//       light: '#BABABA',
//       medium: '#606060',
//       dark: '#303030',
//     },
//     high: {
//       pure: '#FFFFFF',
//       light: '#FBFBFB',
//       medium: '#F0F0F0',
//       dark: '#E5E5E5',
//     },
//     error: {
//       pure: '#D13F3F',
//       light: '#E5ACAC',
//       medium: '#D16161',
//       dark: '#B22424',
//     },
//     alert: {
//       pure: '#F48619',
//       light: '#FFB266',
//       medium: '#F29130',
//       dark: '#B25900',
//     },
//     success: {
//       pure: '#008844',
//       light: '#6CD9A0',
//       medium: '#22BB6B',
//       dark: '#138047 ',
//     },
//     gray: {
//       100: '#F0F0F0',
//     },
//   },
//   config: {
//     cssVarPrefix: 'lnd',
//   },
//   components: {
//     Button,
//   },
// })
