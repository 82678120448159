import { useState } from 'react'
import {
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  Image,
} from '@chakra-ui/react'

import { ArrowSquareOut, DownloadSimple } from 'phosphor-react'
import anydeskIcon from '../../../assets/imgs/anydesk.svg'
import ZipIcon from '../../../assets/imgs/7zip.svg'
import ShotIcon from '../../../assets/imgs/lightshot.png'
import AppLogo from '../../../assets/icons/int-logo.svg'

import GestorAtt from '../../../assets/imgs/gestor-att.svg'
import UniqueAtt from '../../../assets/imgs/unique-att.svg'

interface Tool {
  img: any
  name: string
  description: string
  link: string
  hasDownload?: boolean
}

export const Tools = () => {
  const [tools, setTools] = useState<Tool[]>(() => [
    {
      name: 'Anydesk',
      description:
        'Acesse qualquer dispositivo a qualquer momento e a partir de qualquer lugar, sempre de forma segura e rápida.',
      link: 'https://anydesk.com/pt/downloads/windows',
      img: anydeskIcon,
    },
    {
      name: '7-Zip',
      description:
        'Você pode usar 7-Zip em quaisquer computadores, incluindo computadores em organizações comerciais. Você não precisa registrar ou pagar pelo 7-Zip.',
      link: 'https://www.7-zip.org/',
      img: ZipIcon,
    },
    {
      name: 'Lightshot ',
      description:
        'Este programa permite que você capture a tela diretamente de seu desktop.',
      link: 'https://app.prntscr.com/pt-br/download.html',
      img: ShotIcon,
    },
    {
      name: 'AssistenteLS',
      description:
        'Imprima de forma automática em seu computador, basta instalar nosso AssistenteLS.',
      link: 'https://workspace.londrisoft.com.br/assistenteLS.exe',
      img: AppLogo,
      hasDownload: true,
    },
    // {
    //   name: 'Atualização GESTOR',
    //   description: 'Atualização do Sistem GESTOR local',
    //   link: 'https://site.londrisoft.com.br/programas/gestor_prime_atua/atualiza.exe',
    //   img: GestorAtt,
    //   hasDownload: true,
    // },
    // {
    //   name: 'Atualização UNIQUE',
    //   description: 'Atualização do Sistem UNIQUE local',
    //   link: 'https://site.londrisoft.com.br/programas/unique_atua/atualiza.exe',
    //   img: UniqueAtt,
    //   hasDownload: true,
    // },
  ])

  return (
    <Flex>
      <Table>
        <Thead>
          <Tr></Tr>
        </Thead>

        <Tbody>
          {tools.map((tool, index) => (
            <Tr key={index}>
              <Td w="100px">
                <Image
                  h="40px"
                  w="40px"
                  src={tool.img}
                  alt={tool.description}
                />
              </Td>
              <Td>
                <Text fontSize="sm" fontWeight="bold">
                  {tool.name}
                </Text>
                <Text fontSize="13px" color="low.medium">
                  {tool.description}
                </Text>
              </Td>
              <Td textAlign="end" w="130px">
                <Button
                  as="a"
                  variant="outline"
                  size="sm"
                  borderRadius="4px"
                  leftIcon={
                    tool.hasDownload ? (
                      <DownloadSimple size={20} />
                    ) : (
                      <ArrowSquareOut size={20} />
                    )
                  }
                  w="120px"
                  href={tool.link}
                  target="_blank"
                >
                  {tool.hasDownload ? 'Baixar' : 'Abrir'}
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Flex>
  )
}
