import {
  Box,
  Button,
  Divider,
  Flex,
  FormLabel,
  Text,
  useToast,
} from '@chakra-ui/react'

import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as Whatsapp } from 'src/assets/icons/whatsapp.svg'
import { ReactComponent as Phone } from 'src/assets/icons/phone.svg'
import { ReactComponent as Mail } from 'src/assets/icons/mail.svg'
import { ReactComponent as UniqueCert } from 'src/assets/imgs/unique_cert.svg'

import { Check, TextT, X } from 'phosphor-react'
import { FormInput } from 'src/components/FormInput'
import { Participant } from '../types'
import { formatting } from 'src/utilities/formattingToPattern'
import { apiInstance } from 'src/services/api'
import dayjs from 'dayjs'

export const ParticipantCertificate = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const [participant, setParticipant] = useState({} as Participant)
  const [certificate, setCertificate] = useState(
    {} as { expiration: string; daysExpiration: number }
  )
  const [newCertificate, setNewCertificate] = useState({
    certificadopfx: '',
    cnpjCpf: participant?.cnpjCpf,
    senha: '',
  })

  const ref = useRef<HTMLInputElement>(null)

  const getCertificate = useCallback(
    async (doc: string) => {
      try {
        const { data } = await apiInstance(9000).get(
          `/workspace/Certificado/Vencimento/${doc}`,
          {
            headers: {
              GatewayLS: '2e44bb6339e6aacd8faeca8fd4e8694e',
            },
          }
        )
        const { dados } = data
        const payload = {
          expiration: new Date(dados.vencimento).toLocaleDateString(),
          daysExpiration: dayjs(dados.vencimento).diff(dayjs(), 'days'),
        }
        setCertificate(payload)
      } catch (error: any) {
        toast({
          title: <Text fontSize="sm">Falha ao carregar certificado</Text>,
          description: (
            <Text fontSize="sm">Participante sem certificado valido!</Text>
          ),
          position: 'top',
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      }
    },
    [toast]
  )

  const changeCertificate = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0]

    if (file.type !== 'application/x-pkcs12') {
      toast({
        title: 'Formato inválido',
        position: 'top',
        status: 'warning',
      })
      return
    }

    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function (e) {
      setNewCertificate({
        ...newCertificate,
        certificadopfx: reader.result!.toString().split(',')[1],
      })
    }
  }

  const checkCertificateIsValid = async () => {
    try {
      await apiInstance(9000).post(
        '/workspace/Certificado',
        newCertificate,
        {
          headers: {
            GatewayLS: '2e44bb6339e6aacd8faeca8fd4e8694e',
          },
        }
      )
      toast({
        title: <Text fontSize="sm">Uploado completo!</Text>,
        description: (
          <Text fontSize="sm">Seu certificado foi inserido com sucesso!</Text>
        ),
        position: 'top',
        status: 'success',
      })
    } catch (error: any) {
      if (error.response) {
        toast({
          title: <Text fontSize="sm">Falha ao carregar certificado</Text>,
          description: <Text fontSize="sm">{error.response.data.message}</Text>,
          position: 'top',
          status: 'error',
        })
        return
      }
      toast({
        title: <Text fontSize="sm">Falha ao carregar certificado</Text>,
        description: (
          <Text fontSize="sm">Verifique se o arquivo é compatíveis</Text>
        ),
        position: 'top',
        status: 'error',
      })
    }
  }

  useEffect(() => {
    const obj = JSON.parse(localStorage.getItem('ws@Participant') || '{}')

    if (Object.keys(obj).length === 0) navigate(-1)

    getCertificate(obj.cnpjCpf)
    setNewCertificate((state) => ({
      ...state,
      cnpjCpf: obj.cnpjCpf,
    }))

    setParticipant(obj)

    return () => localStorage.removeItem('ws@Participant')
  }, [getCertificate, navigate])

  return (
    <>
      <input
        ref={ref}
        type="file"
        id="file"
        style={{ display: 'none' }}
        onChange={changeCertificate}
      />
      <Flex gap={6} w="full">
        <Flex
          w="full"
          bg="high.pure"
          borderRadius="4px"
          boxShadow={
            '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
          }
          p="12px"
          flexDirection="column"
        >
          <ParticipantCard
            participant={participant}
            certificate={certificate}
          />
          {/* <Flex h="full" align="center" justify="center">
            <UniqueCert style={{ width: '40%' }} />
          </Flex> */}
        </Flex>

        <Flex
          bg="high.pure"
          borderRadius="4px"
          boxShadow={
            '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
          }
          h="390px"
          minW="345px"
          p="12px"
          flexDir="column"
          gap={4}
        >
          <Text>Certificado Digital</Text>
          <Button
            variant="outline"
            borderColor="primary.pure"
            color="primary.pure"
            h="36px"
            borderRadius="4px"
            w="full"
            onClick={() => ref.current?.click()}
          >
            Selecionar certificado
          </Button>
          <Flex gap={2} align="end">
            <FormInput
              label="Senha do certificado"
              type="password"
              placeholder="*********"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setNewCertificate({
                  ...newCertificate,
                  senha: e.currentTarget.value.trim(),
                })
              }
              isDisabled={newCertificate.certificadopfx.length === 0}
            />
            <Button
              isDisabled={newCertificate.senha.length < 4}
              h="36px"
              borderRadius="4px"
              onClick={checkCertificateIsValid}
              colorScheme="green"
              fontSize="xs"
            >
              Salvar
            </Button>
          </Flex>
          <Divider />
          <FormInput
            label="Razão social"
            isDisabled
            defaultValue={participant.razao}
          />
          <Flex gap={2}>
            <FormInput
              label="CNPJ/CPF"
              defaultValue={formatting({
                value: participant?.cnpjCpf || '',
                pattern: 'document',
              })}
              control={{ w: '270px' }}
              isDisabled
            />
            <FormInput
              label="Vencimento"
              defaultValue={certificate.expiration}
              control={{ w: '200px' }}
              isDisabled
            />
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}

type ParticipantCardProps = {
  participant: Participant
  certificate: {
    expiration: string
    daysExpiration: number
  }
}

const ParticipantCard = ({
  participant,
  certificate,
}: ParticipantCardProps) => {
  return (
    <Flex
      py="20px"
      px="12px"
      w="full"
      maxH="160px"
      border="1px solid #E6E6E6"
      borderRadius="4px"
      flexDir="column"
      gap={4}
    >
      <Flex w="full" justify="space-between">
        <Text fontSize="sm">{participant.razao}</Text>
        <Flex gap={6}>
          <Text fontSize="sm" display="inline-flex">
            <Phone style={{ marginRight: '10px' }} />
            {participant.fone}
          </Text>
          <Text fontSize="sm" display="inline-flex">
            <Whatsapp style={{ marginRight: '10px' }} />
            {participant.celular}
          </Text>
        </Flex>
      </Flex>
      <Flex w="full" justify="space-between">
        <Text fontSize="sm">
          {formatting({
            value: participant.cnpjCpf || '',
            pattern: 'document',
          })}
        </Text>
        <Text fontSize="sm" display="inline-flex">
          <Mail style={{ marginRight: '10px' }} />
          {participant.email}
        </Text>
      </Flex>
      <Divider borderColor="high.dark" />

      <Flex align="center" justify="space-between">
        <Text fontSize="sm" display="inline-flex">
          Certificado Digital:{' '}
          {certificate.daysExpiration > 0 ? (
            <Check size={22} style={{ marginLeft: '10px' }} color="#008844" />
          ) : (
            <X size={22} style={{ marginLeft: '10px' }} color="#D13F3F" />
          )}
        </Text>

        <Text fontSize="sm">
          Vence em: {certificate.daysExpiration || 0} dia(s)
        </Text>
      </Flex>
      <Box mt="20px">
        <Text fontSize="xs" color="low.medium" mb="2">
          *A Londrisoft não se responsabiliza pelo bloqueio do certificado em
          caso de repetidas inserções de senhas incorretas.{' '}
        </Text>
        <Text fontSize="xs" color="low.medium">
          *Não é possível, em nenhuma circunstância, recuperar essa imagem a
          partir do sistema. Guarde uma cópia para futura utilização.
        </Text>
      </Box>
    </Flex>
  )
}
